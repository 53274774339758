import moment from "moment";
/**
 * Formatea las fechas 'desde' y 'hasta' en el elemento dado si no están en el formato 'YYYY-MM-DD'.
 * Establece el campo 'id' en null.
 *
 * @param {Object} item - El objeto que contiene las fechas a formatear.
 * @param {string} item.desde - La cadena de fecha 'desde'.
 * @param {string} item.hasta - La cadena de fecha 'hasta'.
 * @returns {Object} Un nuevo objeto con las fechas 'desde' y 'hasta' formateadas y 'id' establecido en null.
 */
const formatDatesIfNeeded = (item, needIdNull) => {
  const isDateValidFormat = (dateString) =>
    /^\d{4}-\d{2}-\d{2}$/.test(dateString);

  const formatDate = (dateString) =>
    isDateValidFormat(dateString)
      ? dateString
      : moment(dateString, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD");

  return {
    ...item,
    id: needIdNull ? null : item.id,
    desde: formatDate(item.desde),
    hasta: formatDate(item.hasta),
  };
};

/**

Calcula la tarifa mensual y diaria basada en el metraje y la tarifa del cliente.

@param {Object} item - El objeto que contiene los datos necesarios para calcular la tarifa.

@param {string} item.descripcion - La descripción del ítem.

@param {string} item.desde - La fecha de inicio.

@param {string} item.hasta - La fecha de fin.

@param {number} item.tarifa_cliente - La tarifa del cliente.

@param {string} item.moneda - La moneda en la que se expresa la tarifa del cliente.

@param {number} item.cantidad - La cantidad a ser calculada.

@param {Object[]} monedaOptions - Lista de opciones de moneda con sus tasas de cambio.
*/
function calculateTarifaMensualWithMetraje(item, monedaOptions) {
  if (
    item.descripcion !== "Gasto Común" &&
    item.descripcion !== "Electricidad" &&
    item.descripcion !== "Agua"
  ) {
    if (item.desde && item.hasta) {
      if (item.tarifa_cliente > 0) {
        if (item?.moneda !== "UF") {
          let ufExchangeRateItem;
          let monedaExchangeRateItem;
          if (monedaOptions.length > 0) {
            ufExchangeRateItem = monedaOptions?.find(
              (option) => option?.codigo === "UF"
            );
            monedaExchangeRateItem = monedaOptions?.find(
              (option) => option?.codigo === item.moneda
            );

            if (ufExchangeRateItem) {
              const ufExchangeRate = ufExchangeRateItem.tipoCambio;
              const monedaExangeRate = monedaExchangeRateItem.tipoCambio;

              const equivalentInUF =
                (item.tarifa_cliente * monedaExangeRate) / ufExchangeRate;

              const totalTarifaMensual = parseFloat(
                (equivalentInUF * item.cantidad).toFixed(2)
              );

              item.tarifa_diaria = parseFloat(
                (totalTarifaMensual / 30).toFixed(2)
              );
              item.tarifa_mensual = totalTarifaMensual;
            } else {
              console.error("Exchange rate for 'UF' not found.");
            }
          }
        } else {
          const totalTarifaMensual = parseFloat(
            (item.tarifa_cliente * item.cantidad).toFixed(2)
          );
          item.tarifa_diaria = parseFloat((totalTarifaMensual / 30).toFixed(2));
          item.tarifa_mensual = totalTarifaMensual;
        }
      } else {
        item.tarifa_mensual = 0;
        item.tarifa_diaria = 0;
      }
    } else {
      item.tarifa_mensual = 0;
      item.tarifa_diaria = 0;
    }
  }
}
/**
 * Calcula la tarifa mensual y diaria de un servicio, ajustada según el tipo de moneda y otras condiciones.
 *
 * @param {Object} item - El objeto que contiene los datos necesarios para calcular la tarifa.
 * @param {string} item.descripcion - La descripción del ítem.
 * @param {string} item.desde - La fecha de inicio.
 * @param {string} item.hasta - La fecha de fin.
 * @param {number} item.tarifa_cliente - La tarifa del cliente.
 * @param {string} item.moneda - La moneda en la que se expresa la tarifa del cliente.
 * @param {number} item.cantidad - La cantidad a ser calculada.
 * @param {string} item.tramo - El tramo de gasto común, si aplica.
 * @param {Object[]} monedaOptions - Lista de opciones de moneda con sus tasas de cambio.
 * @param {Object[]} flattenedDataArriendo - Lista de datos de arriendo, utilizada para encontrar ítems coincidentes en gastos comunes.
 * @param {number} subtotalArriendo - El subtotal del arriendo, utilizado para calcular gastos comunes.
 */
function calculateTarifaMensualServicios(
  item,
  monedaOptions,
  flattenedDataArriendo,
  subtotalArriendo
) {
  if (item.descripcion !== "Electricidad" && item.descripcion !== "Agua") {
    if (item.desde && item.hasta) {
      if (item.tarifa_cliente > 0) {
        if (item?.moneda !== "UF") {
          let ufExchangeRateItem;
          let monedaExchangeRateItem;
          if (monedaOptions.length > 0) {
            ufExchangeRateItem = monedaOptions?.find(
              (option) => option?.codigo === "UF"
            );
            monedaExchangeRateItem = monedaOptions?.find(
              (option) => option?.codigo === item.moneda
            );

            if (ufExchangeRateItem) {
              const ufExchangeRate = ufExchangeRateItem.tipoCambio;
              const monedaExangeRate = monedaExchangeRateItem.tipoCambio;

              const equivalentInUF =
                (item.tarifa_cliente * monedaExangeRate) / ufExchangeRate;

              const totalTarifaMensual = parseFloat(
                (equivalentInUF * item.cantidad).toFixed(2)
              );

              item.tarifa_diaria = parseFloat(
                (totalTarifaMensual / 30).toFixed(2)
              );
              item.tarifa_mensual = totalTarifaMensual;
            } else {
              console.error("Exchange rate for 'UF' not found.");
            }
          }
        } else {
          const totalTarifaMensual = parseFloat(item.tarifa_cliente).toFixed(2);
          item.tarifa_diaria = parseFloat((totalTarifaMensual / 30).toFixed(2));
          item.tarifa_mensual = totalTarifaMensual;
        }
      } else {
        if (item.descripcion.includes("Gasto Comun")) {
          if (item.tramo) {
            // Find the matching item in flattenedDataArriendo
            const matchingItem = flattenedDataArriendo.find(
              (data) =>
                data.tramo === item.tramo &&
                item.descripcion.split(" - ")[1] === data.descripcion
            );

            // If a matching item is found, update item.tarifa_cliente, item.tarifa_mensual, and item.tarifa_diaria
            if (matchingItem) {
              item.tarifa_cliente = parseFloat(
                matchingItem.tarifa_mensual * 0.1
              ).toFixed(2);
              item.tarifa_mensual = parseFloat(
                matchingItem.tarifa_mensual * 0.1
              ).toFixed(2);
              item.tarifa_diaria = parseFloat(
                (matchingItem.tarifa_mensual * 0.1) / 30
              ).toFixed(2);
            }
          } else {
            // Calculate tarifa_cliente, tarifa_mensual, and tarifa_diaria based on subtotalArriendo
            item.tarifa_cliente = parseFloat(subtotalArriendo * 0.1).toFixed(2);
            item.tarifa_mensual = parseFloat(subtotalArriendo * 0.1).toFixed(2);
            item.tarifa_diaria = parseFloat(
              (subtotalArriendo * 0.1) / 30
            ).toFixed(2);
          }
        } else {
          item.tarifa_mensual = 0;
          item.tarifa_diaria = 0;
        }
      }
    } else {
      item.tarifa_mensual = 0;
      item.tarifa_diaria = 0;
    }
  }
  if (item.descripcion.includes("Gasto Comun")) {
    if (item.tramo) {
      // Find the matching item in flattenedDataArriendo
      const matchingItem = flattenedDataArriendo.find(
        (data) =>
          data.tramo === item.tramo &&
          item.descripcion.split(" - ")[1] === data.descripcion
      );
      // If a matching item is found, update item.tarifa_cliente, item.tarifa_mensual, and item.tarifa_diaria
      if (matchingItem) {
        item.tarifa_cliente = matchingItem.tarifa_mensual * 0.1;
        item.tarifa_mensual = matchingItem.tarifa_mensual * 0.1;
        item.tarifa_diaria = (matchingItem.tarifa_mensual * 0.1) / 30;
      }
    } else {
      // Calculate tarifa_cliente, tarifa_mensual, and tarifa_diaria based on subtotalArriendo
      item.tarifa_cliente = subtotalArriendo * 0.1;
      item.tarifa_mensual = subtotalArriendo * 0.1;
      item.tarifa_diaria = (subtotalArriendo * 0.1) / 30;
    }
  }
}
/**
 * Calcula la tarifa mensual y diaria de un servicio único, ajustada según el tipo de moneda y otras condiciones.
 *
 * @param {Object} item - El objeto que contiene los datos necesarios para calcular la tarifa.
 * @param {string} item.desde - La fecha de inicio.
 * @param {string} item.hasta - La fecha de fin.
 * @param {number} item.tarifa_cliente - La tarifa del cliente.
 * @param {string} item.moneda - La moneda en la que se expresa la tarifa del cliente.
 * @param {Object[]} monedaOptions - Lista de opciones de moneda con sus tasas de cambio.
 */
function calculateTarifaMensualServiciosUnicos(item, monedaOptions) {
  if (item.desde && item.hasta) {
    if (item.tarifa_cliente > 0) {
      if (item?.moneda !== "UF") {
        let ufExchangeRateItem;
        let monedaExchangeRateItem;
        if (monedaOptions.length > 0) {
          ufExchangeRateItem = monedaOptions?.find(
            (option) => option?.codigo === "UF"
          );
          monedaExchangeRateItem = monedaOptions?.find(
            (option) => option?.codigo === item.moneda
          );

          if (ufExchangeRateItem) {
            const ufExchangeRate = ufExchangeRateItem.tipoCambio;
            const monedaExangeRate = monedaExchangeRateItem.tipoCambio;

            const equivalentInUF =
              (item.tarifa_cliente * monedaExangeRate) / ufExchangeRate;

            const totalTarifaMensual = parseFloat(equivalentInUF.toFixed(2));

            item.tarifa_diaria = parseFloat(
              (totalTarifaMensual / 30).toFixed(2)
            );
            item.tarifa_mensual = totalTarifaMensual;
          } else {
            console.error("Exchange rate for 'UF' not found.");
          }
        }
      } else {
        const totalTarifaMensual = parseFloat(item.tarifa_cliente).toFixed(2);
        item.tarifa_diaria = parseFloat((totalTarifaMensual / 30).toFixed(2));
        item.tarifa_mensual = totalTarifaMensual;
      }
    } else {
      item.tarifa_mensual = 0;
      item.tarifa_diaria = 0;
    }
  } else {
    item.tarifa_mensual = 0;
    item.tarifa_diaria = 0;
  }
}

/**
 * Calcula el subtotal mensual de una lista de datos, ajustado por la cantidad de meses de cada ítem.
 *
 * @param {Object[]} data - Lista de objetos que contienen los datos necesarios para calcular el subtotal.
 * @param {string} data[].descripcion - La descripción del ítem.
 * @param {string} data[].desde - La fecha de inicio.
 * @param {string} data[].hasta - La fecha de fin.
 * @param {number|string} data[].tarifa_mensual - La tarifa mensual del ítem. Si es "Tarifa Variable", se ignora.
 *
 * @returns {string} - El subtotal mensual calculado, redondeado a una decimal.
 */
const calculateSubtotal = (data) => {
  let subtotal = 0;
  let descripcionSum = {};
  let descripcionMonths = {};

  for (let i = 0; i < data.length; i++) {
    const rowData = data[i];

    if (
      rowData &&
      rowData.tarifa_mensual !== undefined &&
      rowData.tarifa_mensual !== "Tarifa Variable"
    ) {
      const currentNombre = rowData.descripcion;
      const currentTarifaMensual = parseFloat(rowData.tarifa_mensual).toFixed(2);

      const desdeDate = new Date(rowData.desde);
      const hastaDate = new Date(rowData.hasta);

      // Get the time zone offset of desdeDate and hastaDate
      const desdeOffset = desdeDate.getTimezoneOffset() * 60 * 1000; // Convert minutes to milliseconds
      const hastaOffset = hastaDate.getTimezoneOffset() * 60 * 1000; // Convert minutes to milliseconds

      // Adjust dates with respective time zone offsets
      const desde = new Date(desdeDate.getTime() + desdeOffset);
      desde.setHours(0, 0, 0, 0);

      const hasta = new Date(hastaDate.getTime() + hastaOffset);
      hasta.setHours(0, 0, 0, 0);

      const monthsDifference =
        (hasta.getFullYear() - desde.getFullYear()) * 12 +
        (hasta.getMonth() - desde.getMonth());

      // If monthsDifference is 0, set it to 1
      const adjustedMonthsDifference =
        monthsDifference === 0 ? 1 : monthsDifference;

      // Add current tarifa to the sum and increment total months for this descripcion
      if (currentNombre in descripcionSum) {
        descripcionSum[currentNombre] = parseFloat(
          (parseFloat(descripcionSum[currentNombre]) + parseFloat(currentTarifaMensual) * adjustedMonthsDifference).toFixed(2)
        );
        descripcionMonths[currentNombre] += adjustedMonthsDifference;
      } else {
        descripcionSum[currentNombre] = parseFloat(
          (parseFloat(currentTarifaMensual) * adjustedMonthsDifference).toFixed(2)
        );
        descripcionMonths[currentNombre] = adjustedMonthsDifference;
      }
    }
  }


  for (const nombre in descripcionSum) {
    subtotal = parseFloat(
      (parseFloat(subtotal) + descripcionSum[nombre] / descripcionMonths[nombre]).toFixed(2)
    );
  }


  return subtotal.toFixed(2);
};
export {
  calculateSubtotal,
  calculateTarifaMensualWithMetraje,
  calculateTarifaMensualServicios,
  calculateTarifaMensualServiciosUnicos,
  formatDatesIfNeeded,
};
