import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

const TarifasTable = ({
  tarifas,
  filters,
  loading,
  selectedTarifa,
  displayDialog,
  newTarifa,
  setNewTarifa,
  handleCloseDialog,
  templateButton,
  handleUpdateTarifa,
}) => {
  return (
    <div className="col-12 md:col-12">
      <div className="card col-12 md:col-12">
        <DataTable
          header="Tarifas Productos"
          className="p-datatable-gridlines"
          value={tarifas.tarifasTipoProductos}
          rows={10}
          dataKey="id"
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          emptyMessage="No hay tarifas para mostrar"
        >
          <Column
            field="descripcion"
            header="Tipo Producto"
            sortable
            style={{ minWidth: "11.5rem", textAlign: "left" }}
          ></Column>

          <Column
            field="tarifa_base"
            header="Tarifa Base"
            sortable
            style={{ minWidth: "10rem", textAlign: "left" }}
            body={(rowData) => (
              <span>{rowData.tarifa_base.toLocaleString("es-CL")}</span>
            )}
          ></Column>
          <Column
            field="moneda"
            header="Moneda"
            sortable
            style={{ minWidth: "10rem", textAlign: "left" }}
          ></Column>

          <Column
            header="Acción"
            body={(rowData) => templateButton({ ...rowData, tipo: "producto" })}
            style={{ minWidth: "5rem", textAlign: "left" }}
          />
        </DataTable>
      </div>

      <div className="card col-12 md:col-12">
        <DataTable
          header="Tarifas Subproductos"
          className="p-datatable-gridlines"
          value={tarifas.tarifasTipoSubProductos}
          rows={10}
          dataKey="id"
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          emptyMessage="No hay tarifas para mostrar"
          selectionMode="single"
        >
          <Column
            field="descripcion"
            header="Tipo Subproducto"
            filter
            filterPlaceholder="Buscar por Subproducto"
            style={{ minWidth: "10rem" }}
          ></Column>

          <Column
            field="tarifa_base"
            header="Tarifa Base"
            sortable
            style={{ minWidth: "10rem", textAlign: "left" }}
            body={(rowData) => (
              <span>{rowData.tarifa_base.toLocaleString("es-CL")}</span>
            )}
          ></Column>

          <Column
            field="moneda"
            header="Moneda"
            sortable
            style={{ minWidth: "10rem", textAlign: "left" }}
          ></Column>

          <Column
            header="Acción"
            body={(rowData) =>
              templateButton({ ...rowData, tipo: "subproducto" })
            }
            style={{ minWidth: "5rem", textAlign: "left" }}
          />
        </DataTable>
      </div>

      <div className="card col-12 md:col-12">
        <DataTable
          header="Tarifas Servicios"
          className="p-datatable-gridlines"
          value={tarifas.tarifasTipoServicios}
          rows={10}
          dataKey="id"
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          emptyMessage="No hay tarifas para mostrar"
        >
          <Column
            field="descripcion"
            header="Tipo Servicio"
            filter
            filterPlaceholder="Buscar por Servicio"
            style={{ minWidth: "11.5rem" }}
          ></Column>

          <Column
            field="tarifa_base"
            header="Tarifa Base"
            sortable
            style={{ minWidth: "10rem", textAlign: "left" }}
            body={(rowData) => (
              <span>{rowData.tarifa_base.toLocaleString("es-CL")}</span>
            )}
          ></Column>

          <Column
            field="moneda"
            header="Moneda"
            sortable
            style={{ minWidth: "10rem", textAlign: "left" }}
          ></Column>

          <Column
            header="Acción"
            body={(rowData) => templateButton({ ...rowData, tipo: "servicio" })}
            style={{ minWidth: "5rem", textAlign: "left" }}
          />
        </DataTable>
      </div>

      <Dialog
        blockScroll
        header="Detalles de la tarifa"
        visible={displayDialog}
        onHide={handleCloseDialog}
        style={{ width: "75vw" }}
        modal
      >
        {selectedTarifa && (
          <div className="card p-fluid formgrid grid">
            <div className="field col-12 md:col-12">
              <DataTable
                value={[selectedTarifa]}
                className="p-datatable-gridlines"
              >
                <Column field="descripcion" header="Descripcion" />

                <Column
                  field="tarifa_base"
                  header="Tarifa Actual"
                  body={(rowData) => (
                    <span>{rowData.tarifa_base.toLocaleString("es-CL")}</span>
                  )}
                />
              </DataTable>
            </div>
            <div className="field col-12 md:col-12">
              <h5 style={{ marginLeft: "5px" }}>Ingrese la nueva tarifa</h5>
              <InputText
                id="newTarifa"
                type="number"
                value={newTarifa}
                onChange={(e) => setNewTarifa(e.target.value)}
                placeholder="Ingrese Monto"
                min={0}
              ></InputText>
            </div>
            <div
              className="field col-12 md:col-12"
              style={{ textAlign: "center" }}
            >
              <h5 style={{ marginTop: "5px", marginLeft: "5px" }}>
                ¿Confirmar ingreso de la nueva tarifa?
              </h5>
            </div>
            <div className="field col-6 md:col-6">
              <Button
                label="Confirmar"
                icon="pi pi-check"
                className="p-button-success mr-2 mb-2"
                onClick={handleUpdateTarifa}
              ></Button>
            </div>
            <div className="field col-6 md:col-6">
              <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-danger mr-2 mb-2"
                onClick={handleCloseDialog}
              ></Button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default TarifasTable;
