import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Asignacion2Service } from "../service/Asignacion2Service";
import { useRef } from "react";
import OverviewBox from "../components/OverviewBox";
import AsignacionesTable from "../components/AsginacionesTable";
import { useGlobalContext } from "../Config";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Asignacion = (props) => {
  const [asignaciones, setAsignaciones] = useState(null);
  const [filters2] = useState(null);
  const [loading2, setLoading2] = useState(true);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [fila, setFila] = useState(null);
  const [producto, setProducto] = useState(null);
  const { baseUrl } = useGlobalContext();
  const estados = ["A", "E"];
  const usuarioId = sessionStorage.getItem("usuario_id");
  const asignacion2Service = new Asignacion2Service();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleAction1 = (rowId) => {};

  const handleAction2 = (rowId) => {};

  const handleAction3 = (rowId) => {};

  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  const buildAsignacionesUrl = (usuarioId) => {
    return `${baseUrl}getCotizacionesByEstados?idUsuario=${usuarioId}&estados=${[
      "3",
      "4",
      "5",
    ]}`;
  };
  const fetchData = async (url) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(
        `Network response was not ok. Status: ${response.status}`
      );
    }

    return response.json();
  };
  const getAsignaciones = (data) => {
    return [...(data || [])].map((d) => {
      d.desde = new Date(d.desde);
      d.hasta = new Date(d.hasta);
      return d;
    });
  };

  useEffect(() => {
    const getAsignaciones = async () => {
      try {
        setLoading2(true);

        const url = buildAsignacionesUrl(usuarioId);
        const data = await fetchData(url);
        if (Array.isArray(data.cotizaciones)) {
          setAsignaciones(data.cotizaciones);
          setLoading2(false);
        } else {
          console.error("Data.cotizaciones no es array:", data.cotizaciones);
          setLoading2(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAsignaciones();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const desdeBodyTemplate = (rowData) => {
    return formatDate(rowData.desde);
  };

  const hastaBodyTemplate = (rowData) => {
    return formatDate(rowData.hasta);
  };

  const estadoBodyTemplate = (rowData) => {
    return (
      <span className={`customer-badge status-${rowData.estado}`}>
        {rowData.estado}
      </span>
    );
  };

  const estadoFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={estados}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-success p-button mr-2 mb-2 mt-2"
          onClick={() => handleAction3(rowData.id)}
          outlined
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button p-button mr-2 mb-2 mt-2"
          onClick={() => handleAction1(rowData.id)}
          outlined
        />
      </div>
    );
  };

  const [menu1, menu2, menu3, menu4] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const overviewChartData1 = {
    labels: [
      "Diciembre",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
    ],
    datasets: [
      {
        data: [50, 64, 32, 24, 18, 27, 20, 36, 30],
        borderColor: ["#4DD0E1"],
        backgroundColor: ["rgba(77, 208, 225, 0.8)"],
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const overviewChartData3 = {
    labels: [
      "Diciembre",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
    ],
    datasets: [
      {
        data: [20, 29, 39, 36, 45, 24, 28, 20, 15],
        borderColor: ["#4DD0E1"],
        backgroundColor: ["rgba(77, 208, 225, 0.8)"],
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const overviewChartData4 = {
    labels: [
      "Diciembre",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
    ],
    datasets: [
      {
        data: [30, 39, 50, 21, 33, 18, 10, 24, 20],
        borderColor: ["#4DD0E1"],
        backgroundColor: ["rgba(77, 208, 225, 0.8)"],
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };
  const overviewChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    tooltips: {
      enabled: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  const chart1 = useRef(null);
  const buildDetalleCotizacionUrl = (idCotizacion) => {
    return `${baseUrl}getDetalleCotizacionByIdCotizacion?idCotizacion=${idCotizacion}`;
  };
  const handleRowClick = async (rowData) => {
    const idCotizacion = rowData.id;
  
    // Function to format date, ensuring the output is always in 'yyyy-mm-dd'
    const formatDate = (date, inputFormat) => {
      const parsedDate = moment(date, inputFormat);
      return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : '';
    };
  
    const formattedDesde = formatDate(rowData.desde, 'DD/MM/YYYY');
    const formattedHasta = formatDate(rowData.hasta, 'DD/MM/YYYY');
  

  
    if (rowData.estado.estado === "INGRESADA") {
      let newUrl = `/selectProductos?idCotizacion=${idCotizacion}&idCliente=${rowData.cliente.id}&selected=true&activeIndex=1`;
      navigate(newUrl);
    } else {
      try {
        setLoading(true);
        const detalleCotizacionUrl = buildDetalleCotizacionUrl(idCotizacion);
  
        const data = await fetchData(detalleCotizacionUrl);
  
        if (data.mensaje === "OK") {
          const encodedParams = data.detalles.map(item => {
            // Omit 'desde' and 'hasta' properties
            const { ...rest } = item;
            // Convert the object to a URL parameter string
            return encodeURIComponent(JSON.stringify(rest));
          }).join(',');
  
          let newUrl = `/revisarCotizacion?idCliente=${rowData.cliente.id}&idCotizacion=${idCotizacion}&desde=${formattedDesde}&hasta=${formattedHasta}`;
          navigate(newUrl);
        } else {
          alert(data.mensaje);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert("Hubo un error al crear la cotización");
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div className="grid table-demo">
      <div className="field col-12 md:col-12">
        <h3 style={{ marginBottom: "20px" }}>Asignaciones</h3>
        <h7 style={{ marginTop: "15px" }}>¿Qué contratos tenemos?</h7>
      </div>

      <OverviewBox
        icon="pi-shopping-cart"
        title="Asignaciones"
        menuRef={menu1}
        chartRef={chart1}
        chartData={overviewChartData1}
        chartOptions={overviewChartOptions}
        amount={asignaciones?.length}
        status="$35.640.000"
      />

      <OverviewBox
        icon="pi-briefcase"
        title="Contratos"
        menuRef={menu2}
        chartRef={null}
        chartData={overviewChartData3}
        chartOptions={overviewChartOptions}
        amount="21"
        status="$29.320.000"
      />

      <OverviewBox
        icon="pi pi-stopwatch"
        title="Proximos Vencimientos"
        menuRef={menu3}
        chartRef={null}
        chartData={overviewChartData4}
        chartOptions={overviewChartOptions}
        amount="7"
      />

      <OverviewBox
        icon="pi pi-stopwatch"
        title="Otro Remarcador"
        menuRef={menu4}
        chartRef={null}
        chartData={overviewChartData4}
        chartOptions={overviewChartOptions}
        amount="127"
      />
      <Button
        label="Históricas"
        icon="pi pi-backward"
        className="ml-2 mr-2 mb-2 mt-2 p-button-rounded p-button"
        style={{ backgroundColor: "#800020" }}
      ></Button>
      <Button
        label="Vigentes"
        icon="pi pi-pause"
        className="mr-2 mb-2 mt-2 p-button-rounded p-button"
        style={{ backgroundColor: "#008000" }}
        type="button"
      />
      <Button
        label="Futuras"
        icon="pi pi-forward"
        className="mr-2 mb-2 mt-2 p-button-rounded p-button"
        style={{ backgroundColor: "#00CED1" }}
        type="button"
      />

      <AsignacionesTable
        asignaciones={asignaciones}
        filters={filters2}
        loading={loading2}
        desdeBodyTemplate={desdeBodyTemplate}
        dateFilterTemplate={dateFilterTemplate}
        hastaBodyTemplate={hastaBodyTemplate}
        estadoBodyTemplate={estadoBodyTemplate}
        estadoFilterTemplate={estadoFilterTemplate}
        actionBodyTemplate={actionBodyTemplate}
        setDisplayBasic={setDisplayBasic}
        displayBasic={displayBasic}
        fila={fila}
        setFila={setFila}
        producto={producto}
        setProducto={setProducto}
        handleRowClick={handleRowClick}
      ></AsignacionesTable>
    </div>
  );
};

export default Asignacion;
