import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const HistorialTable = ({ registrosFiltrados, tipoServicio, servicio }) => {
  const dataFiltrada = registrosFiltrados.filter(
    (registro) =>
      registro.idTipoServicio === tipoServicio.id &&
      registro.servicio === servicio
  );
  return (
    <div className="card p-fluid formgrid grid">
      <h5 style={{ marginLeft: "5px" }}>
        Mostrando el historial del servicio :{" "}
      </h5>
      <div className="field col-12 md:col-12">
        <DataTable
          value={dataFiltrada}
          className="p-datatable-gridlines"
          emptyMessage="No se encontraron registros para este servicio."
        >
          <Column
            field="fecha"
            header="Fecha Registro"
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="tipoRemarcador"
            header="Tipo Remarcador"
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="numeroRemarcador"
            header="Remarcador"
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="valor"
            header="Medición"
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="responsable"
            header="Responsable"
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            field="archivo"
            header="Respaldo"
            style={{ minWidth: "10rem" }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default HistorialTable;
