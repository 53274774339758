import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import ClientesTable from "../components/ClientesTable";
import { useGlobalContext } from "../Config";
import { InputText } from "primereact/inputtext";

const Clientes = () => {

  const [loading, setLoading] = useState(true);

  const { baseUrl } = useGlobalContext();
  const [clientes, setClientes] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nombre_producto: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Buscar Razón Social"
          />
        </span>
        <span className="p-input-icon-left">
          Se encontraron {clientes?.length} clientes
        </span>
      </div>
    );
  };
  const header = renderHeader();

  const fetchData = async (url) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(
        `Network response was not ok. Status: ${response.status}`
      );
    }

    return response.json();
  };
  const filtersClienteUrl = () => {
    // To get all clients we are not passing parameters, pending on further evolution to have filters
    return `${baseUrl}getClientesByFiltro?rut=${""}&razonSocial=${""}&nombreFantasia=${""}&alias=${""}`;
  };
  const searchCliente = async () => {
    try {
      setLoading(true);
      const url = filtersClienteUrl();
      const data = await fetchData(url);
      handleClienteResponse(data);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleClienteResponse = (data) => {
    if (Array.isArray(data.clientes)) {
      setClientes(data.clientes);
    } else {
      console.error("Data.clientes no es array:", data.clientes);
    }
  };
  useEffect(() => {
    searchCliente();
    initFilters();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleEditCliente = (data) => {
    alert("Proximamente podrá editar el cliente");
  };
  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <h5>Clientes</h5>
          <ClientesTable
            clientes={clientes}
            loading={loading}
            id="clientesDataTable"
            isMantenedor={true}
            handleEditCliente={handleEditCliente}
            header={header}
            globalFilterFields={["razon_social"]}
            filters={filters}

          />
        </div>
      </div>
    </div>
  );
};

export default Clientes;
