import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useGlobalContext } from "../Config";
import SubProductosTable from "../components/SubProductosTable";
import ServiciosTable from "../components/ServiciosTable";
import UseScrollOnStateChange from "../components/UseScrollOnStateChange";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StepByStep from "../components/StepByStep";
import Loader from "../components/Loader";
import { InputNumber } from "primereact/inputnumber";

const CotizacionSelectSubProductosYServicios = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idCliente = parseInt(searchParams.get("idCliente"));
  const selected = searchParams.get("selected");
  const idCotizacion = parseInt(searchParams.get("idCotizacion"));
  const activeIndex = parseInt(searchParams.get("activeIndex"));
  const [carro, setCarro] = useState([]);
  const selectedItemsParam = searchParams.get("selectedItems");

  const [subProductoAdicional, setSubProductoAdicional] = useState(null);
  const [metrajeSubproductoAdicional, setMetrajeSubproductoAdicional] =
    useState(0);

  const [cliente, setCliente] = useState(null);
  const { baseUrl } = useGlobalContext();

  const [desde, setDesde] = useState(searchParams.get("desde"));
  const [hasta, setHasta] = useState(searchParams.get("hasta"));
  const [seleccionSubProductoAdicional, setSeleccionSubproductoAdicional] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [displayConstruccion, setDisplayConstruccion] = useState(false);
  const [selectedSubproductosFinales, setSelectedSubproductosFinales] =
    useState([]);
  const [showSeleccion, setShowSeleccion] = useState(true);
  const navigate = useNavigate();
  const updateCotizacion = async (
    idCotizacion,
    idCliente,
    idEstado,
    idCreador,
    desde,
    hasta,
    aviso
  ) => {
    try {
      const queryParams = new URLSearchParams({
        idCotizacion,
        idCliente,
        idEstado,
        idCreador,
        desde,
        hasta,
        aviso,
      });

      const url = `${baseUrl}updateCotizacion?${queryParams}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error updating cotizacion:`, error);
      throw error;
    }
  };
  const handleConfirmarItem = async () => {
    const updatedSelectedSubproductos = selectedSubproductos.map(
      (subproducto) => ({
        ...subproducto,
        tarifa_base: subproducto.tarifa_base,
        tarifa_cliente: subproducto.tarifa_base,
        cantidad: subproducto.metraje,
        relacion: "subproducto",
        incluido: subproducto.incluido,
      })
    );
    const updatedServicios = selectedServicios.map((servicio) => ({
      ...servicio,
      tarifa_cliente: servicio.tarifa_base,
      relacion: "servicio",
    }));

    const hasInternet = selectedServicios.some(
      (servicio) => servicio.descripcion === "Internet"
    );

    const hasNonIncluidoSubproducto = selectedSubproductos.some(
      (subproducto) => !subproducto.incluido
    );

    const servicios_unicos = [];

    if (hasInternet) {
      const habilitacionFibra = servicios.find(
        (servicio) => servicio.descripcion === "Habilitacion Fibra"
      );
      if (habilitacionFibra) {
        habilitacionFibra.relacion = "servicio";
        habilitacionFibra.tarifa_base = 0;
        habilitacionFibra.tarifa_cliente = 0;
        servicios_unicos.push(habilitacionFibra);
      }
    }

    if (hasNonIncluidoSubproducto) {
      const obraCivil = servicios.find(
        (servicio) => servicio.descripcion === "Obra Civil"
      );
      if (obraCivil) {
        obraCivil.relacion = "servicio";
        obraCivil.tarifa_base = 0;
        obraCivil.tarifa_cliente = 0;
        obraCivil.incluido = false;
        servicios_unicos.push(obraCivil);
      }
    }

    const gastoComun = servicios.find(
      (servicio) => servicio.descripcion === "Gasto Comun"
    );
    if (gastoComun) {
      gastoComun.relacion = "servicio";
      gastoComun.tarifa_base = 0;
      gastoComun.tarifa_cliente = 0;
      updatedServicios.push(gastoComun);
    }

    const updatedServiciosUnicos = servicios_unicos.map((serviciounico) => ({
      ...serviciounico,
      relacion: "servicio",
    }));
    const carroItem = {
      ...carro[currentIndex],
      descripcion: carro[currentIndex].nombre_producto,
      desde: desde,
      hasta: hasta,
      cobro: "Factura Afecta",
      incluido: true,
      moneda: carro[currentIndex].moneda,
      contrato: true,
      unidad: carro[currentIndex].unidad,
      cantidad: carro[currentIndex].metraje_producto,
      tarifa_base: carro[currentIndex].tarifa_base,
      tarifa_cliente: carro[currentIndex].tarifa_base,
      subproductos: updatedSelectedSubproductos,
      servicios: updatedServicios,
      servicios_unicos: updatedServiciosUnicos,
      descuento: 0,
      relacion: "producto",
    };

    setCarro((prevCarro) => {
      const updatedCarro = [...prevCarro];
      updatedCarro[currentIndex] = carroItem;

      return updatedCarro;
    });

    if (currentIndex < carro.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      const lastCarroItem = {
        ...carroItem,
      };

      const updatedCarro = [...carro.slice(0, currentIndex), lastCarroItem];
      updatedCarro.forEach((item) => {
        // Establecer el id del item en null
        item.id_elemento = item.id;
        item.id = null;
        item.id_cotizacion = idCotizacion;
        item.tipo = item.incluido ? "Incluido" : "Adicional";

        if (item.servicios) {
          item.servicios = item.servicios.map((serv) => ({
            ...serv,
            id_elemento: serv.id,

            nombre_producto: item.nombre_producto,
            descripcion: `${serv.descripcion} - ${item.nombre_producto}`,
            desde: item.desde,
            hasta: item.hasta,
            cobro: item.cobro,
            contrato: item.contrato,
            tarifa_diaria: 0, // Set tarifa_diaria to 0
            tarifa_mensual: 0, // Set tarifa_mensual to 0
            id: null, // Establecer el id en null
            id_cotizacion: idCotizacion,
            tipo: item.incluido ? "Incluido" : "Adicional",
          }));
        }

        if (item.subproductos) {
          item.subproductos = item.subproductos.map((subprod) => ({
            ...subprod,
            id_elemento: subprod.id,

            nombre_producto: item.nombre_producto,
            descripcion: `${subprod.descripcion} - ${item.nombre_producto}`,
            desde: item.desde,
            hasta: item.hasta,
            cobro: item.cobro,
            contrato: item.contrato,
            tarifa_base: subprod.tarifa_base,
            tarifa_diaria: 0,
            tarifa_mensual: 0,
            incluido: subprod.incluido,
            id: null, // Establecer el id en null
            id_cotizacion: idCotizacion,
            tipo: item.incluido ? "Incluido" : "Adicional",
            unidad: "m**2",
          }));
        }

        if (item.servicios_unicos) {
          item.servicios_unicos = item.servicios_unicos.map(
            (servicio_unico) => ({
              ...servicio_unico,
              id_elemento: servicio_unico.id,
              nombre_producto: item.nombre_producto,
              descripcion: `${servicio_unico.descripcion} - ${item.nombre_producto}`,
              desde: item.desde,
              hasta: item.hasta,
              cobro: item.cobro,
              contrato: item.contrato,
              tarifa_base: servicio_unico.tarifa_base,
              descuento: 0,
              tarifa_diaria: 0,
              tarifa_mensual: 0,
              id: null,
              id_cotizacion: idCotizacion,
              tipo: item.incluido ? "Incluido" : "Adicional", // Establecer el id en null
            })
          );
        }
      });
      const postDataForCarro = async (carro) => {
        const promises = [];

        carro.forEach((item) => {
          // Añadir la promesa para el item principal
          promises.push(postDataCotizacion(item));

          // Añadir promesas para subproductos
          if (item.subproductos) {
            item.subproductos.forEach((subprod) => {
              promises.push(postDataCotizacion(subprod));
            });
          }

          // Añadir promesas para servicios
          if (item.servicios) {
            item.servicios.forEach((serv) => {
              promises.push(postDataCotizacion(serv));
            });
          }

          // Añadir promesas para servicios_unicos
          if (item.servicios_unicos) {
            item.servicios_unicos.forEach((servicio_unico) => {
              promises.push(postDataCotizacion(servicio_unico));
            });
          }
        });

        // Esperar a que todas las promesas se completen
        await Promise.all(promises);
      };

      // Ejemplo de uso
      postDataForCarro(updatedCarro)
        .then(() => {
          console.log("Todas las solicitudes se completaron con éxito");
        })
        .catch((error) => {
          console.error("Ocurrió un error al realizar las solicitudes:", error);
        });
        function formatDate(dateString) {
          const parts = dateString.split("/");
          if (parts.length === 3) {
            const [day, month, year] = parts;
            const formattedDay = day.padStart(2, "0");
            const formattedMonth = month.padStart(2, "0");
            return `${year}-${formattedMonth}-${formattedDay}`;
          }
          return dateString;
        }
        const desdeTransformed = formatDate(desde);
      const hastaTransformed = formatDate(hasta);
        await updateCotizacion(
          idCotizacion,
          cliente.id,
          2,
          sessionStorage.getItem("usuario_id"),
          desdeTransformed,
          hastaTransformed,
          0
        );
      const newURL = `/revisarCotizacion?activeIndex=3&idCliente=${cliente.id}&idCotizacion=${idCotizacion}&desde=${desde}&hasta=${hasta}`;
      navigate(newURL);
    }
  };

  const [showSubProductosAdicionales, setShowSubProductosAdicionales] =
    useState(false);
  const checkNewSubproductoVariables = () => {
    if (
      subProductoAdicional === null ||
      subProductoAdicional === undefined ||
      0 >= metrajeSubproductoAdicional
    ) {
      alert("Ingrese todos los campos para el nuevo subproducto");
      return false;
    } else {
      return true;
    }
  };

  const [hastaSub, setHastaSub] = useState(null);

  const handleSubProductoAdicional = () => {
    setSubProductoAdicional(null);
    setMetrajeSubproductoAdicional(0);
    setSeleccionSubproductoAdicional(true);
  };

  const fetchDataCliente = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${baseUrl}getClienteById?idCliente=${idCliente}`
      );
      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      const data = await response.json();
      setCliente(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching client data:", error);
      setLoading(false);
    }
  };
  const fetchData = async (url) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(
        `Network response was not ok. Status: ${response.status}`
      );
    }

    return response.json();
  };
  const [desdeSub, setDesdeSub] = useState(null);

  const handleSeleccionarAdicional = () => {
    setSelectedSubproductosFinales([]);
    setSeleccionSubproductoAdicional(true);
  };
  useEffect(() => {
    if (idCliente && selected === "true" && activeIndex) {
      fetchDataCliente();
    }
  }, [idCliente, selected]);
  const [descripcionSubProductos, setDescripcionSubProductos] = useState([]);

  const [subproductos, setSubproductos] = useState([]);

  const subproductosAdicionales = [
    { id: 1, name: "TODAS", value: "TODAS" },
    { id: 2, name: "ALTAMIRA", value: "Altamira" },
    { id: 3, name: "LA SIEMBRA", value: "LA SIEMBRA" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [servicios, setServicios] = useState([]);
  const handleServicioSelectionChange = (e) => {
    setSelectedServicios(e.value);
  };
  const [selectedServicios, setSelectedServicios] = useState([]);

  const [selectedSubproductos, setSelectedSubproductos] = useState([]);
  const [additionalSelection, setAdditionalSelection] = useState(false);
  const handleSubproductoSelectionChange = (e) => {
    setSelectedSubproductosFinales(e.value);
  };
  const postDataCotizacion = async (item, isCerrar) => {
    const {
      id,
      id_cotizacion,
      id_empresa,
      relacion,
      tramo,
      descripcion,
      desde,
      hasta,
      cobro,
      tipo,
      contrato,
      unidad,
      cantidad,
      moneda,
      tarifa_base,
      tarifa_cliente,
      descuento,
      id_elemento,
    } = item;
    let url;

    const handleInfinity = (value) =>
      value === Infinity || value === -Infinity ? 0 : value;

    const buildURL = (baseUrl, params) => {
      return `${baseUrl}?${Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&")}`;
    };

    const params = {
      idCotizacion: id_cotizacion,
      idElemento: id_elemento,
      relacion: relacion,
      idEmpresa: id_empresa,
      tramo: tramo || null,
      descripcion: descripcion,
      desde,
      hasta,
      documentoCobro: cobro,
      tipo: tipo,
      contrato,
      unidadMedida: unidad || "",
      cantidad: cantidad || 0,
      moneda,
      tarifaBase: tarifa_base || 0,
      tarifaCliente: tarifa_cliente || 0,
      descuento: descuento ? handleInfinity(descuento) : 0,
      activo: 1,
    };

    if (id === null) {
      url = buildURL(`${baseUrl}insertDetalleCotizacion`, params);
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      throw error;
    }
  };
  const buildServiciosUrl = (idEmpresa) => {
    return `${baseUrl}getServiciosByIdEmpresa?idEmpresa=${idEmpresa}`;
  };
  const buildSubproductosUrl = (idProducto, idEmpresa) => {
    return `${baseUrl}getAllSubproductosByIdProducto?idProducto=${idProducto}&idEmpresa=${idEmpresa}`;
  };
  const buildTiposSubproductosUrl = (idEmpresa) => {
    return `${baseUrl}getSubproductosByIdEmpresa?idEmpresa=${idEmpresa}`;
  };

  const [firstEffectCompleted, setFirstEffectCompleted] = useState(false);
  const [secondEffectCompleted, setSecondEffectCompleted] = useState(false);

  const requestOptions = {
    method: "POST",
    redirect: "follow",
  };
  const handleShowSubProductosAdicionales = async () => {
    try {
      if (!checkNewSubproductoVariables()) {
        return;
      }

      if (carro.length > 0) {
        setLoading(true);

        // Add new item to selectedSubproductos
        const newSubproducto = {
          ...subProductoAdicional,
          metraje: metrajeSubproductoAdicional,
        };

        setSelectedSubproductos((prevSubproductos) => [
          ...prevSubproductos,
          newSubproducto,
        ]);

        setLoading(false);
      } else {
        alert("No hay items en el carro");
      }

      setSeleccionSubproductoAdicional(false);
    } catch (error) {
      console.log(error);
      setSeleccionSubproductoAdicional(false);
    }
  };
  useEffect(() => {
    if (selectedItemsParam) {
      const selectedItems = JSON.parse(decodeURIComponent(selectedItemsParam));

      setCarro(selectedItems);
    }
  }, [selectedItemsParam]);
  useEffect(() => {
    if (carro.length > 0) {
      const fetchDataAndUpdateState = async () => {
        const currentCarroItem = carro[currentIndex];
        if (!currentCarroItem) {
          alert("no hay items en el carro");
          return;
        }

        try {
          setLoading(true);
          const descripcionSubproducto = [];
          const urlServicios = buildServiciosUrl(currentCarroItem.id_empresa);
          const urlSubproductos = buildSubproductosUrl(
            currentCarroItem.id,
            currentCarroItem.id_empresa
          );
          const urlTiposSubproducto = buildTiposSubproductosUrl(
            currentCarroItem.id_empresa
          );

          const [dataServicios, dataSubproductos, dataTipoSubproductos] =
            await Promise.all([
              fetchData(urlServicios),
              fetchData(urlSubproductos),
              fetchData(urlTiposSubproducto),
            ]);

          if (Array.isArray(dataServicios.servicios)) {
            setServicios(dataServicios.servicios);
          }

          if (Array.isArray(dataSubproductos.subproductos)) {
            setSubproductos(dataSubproductos.subproductos);
          }
          if (Array.isArray(dataTipoSubproductos.subproductos)) {
            setDescripcionSubProductos(dataTipoSubproductos.subproductos);
          }
          setLoading(false);

          setFirstEffectCompleted(true);
        } catch (error) {
          console.error(error);
        }
      };
      fetchDataAndUpdateState();
    }
  }, [carro, currentIndex]);

  useEffect(() => {
    const updateSelection = () => {
      if (firstEffectCompleted && secondEffectCompleted) {
        if (carro.length > 0) {
          const selectedSubproductosArray = [];
          const selectedServiciosArray = [];

          const gastoComunIncluded = selectedServiciosArray.find(
            (item) => item.name === "Gasto Común"
          );

          if (!gastoComunIncluded) {
            const gastoComunItem = servicios.find(
              (servicio) => servicio.name === "Gasto Común"
            );
            if (gastoComunItem) {
              selectedServiciosArray.push({
                ...gastoComunItem,
                incluido: true,
                tarifa_base: 0,
                tarifa_cliente: 0,
              });
            }
          }

          subproductos.forEach((item) => {
            selectedSubproductosArray.push({ ...item });
          });

          carro[currentIndex].internet &&
            subproductos.push({
              ...servicios.find(
                (servicio) => servicio.descripcion === "Internet"
              ),
              incluido: true,
            });
          carro[currentIndex].electricidad &&
            selectedServiciosArray.push({
              ...servicios.find(
                (servicio) => servicio.descripcion === "Electricidad"
              ),
              incluido: true,
            });
          carro[currentIndex].agua &&
            selectedServiciosArray.push({
              ...servicios.find((servicio) => servicio.descripcion === "Agua"),
              incluido: true,
            });

          window.scrollTo({ top: 0, behavior: "smooth" });

          setSelectedServicios(selectedServiciosArray);
          setSelectedSubproductos(selectedSubproductosArray);
          setAdditionalSelection(!additionalSelection);
        }
      } else {
        return;
      }
    };

    updateSelection();
  }, [
    carro,
    currentIndex,
    firstEffectCompleted,
    secondEffectCompleted,
    servicios,
    subproductos,
  ]);
  useEffect(() => {
    if (firstEffectCompleted) {
      setSecondEffectCompleted(true);
    }
  }, [firstEffectCompleted]);
  UseScrollOnStateChange(productosDisponibles, "productosDataTable");

  return (
    <div className="card">
      {activeIndex && <StepByStep activeIndex={activeIndex} />}
      {currentIndex < carro.length && showSeleccion && (
        <>
          {loading ? (
            <>
              <Loader></Loader>
            </>
          ) : (
            <>
              {" "}
              <div className="p-fluid formgrid grid">
                <div
                  className="field col-12 md:col-12"
                  style={{ display: "flex", justifyContent: "start" }}
                >
                  {" "}
                  <Button
                    label="Volver a buscar"
                    icon="pi pi-backward
          "
                    className="mr-2 mb-2"
                    onClick={() => {
                      navigate(
                        `/selectProductos?idCotizacion=${idCotizacion}&idCliente=${idCliente}&selected=true&activeIndex=1`
                      );
                    }}
                    style={{ width: "400px" }}
                  ></Button>
                </div>
                <div
                  className=" col-12 md:col-12"
                  style={{ marginBottom: "15px" }}
                >
                  <h6>Cotizacion # {idCotizacion}</h6>
                </div>
                <div
                  className=" col-12 md:col-12"
                  style={{ marginBottom: "15px" }}
                >
                  <h6>
                    Seleccione subproductos y servicios para el producto
                    seleccionado
                  </h6>
                </div>
                <div className=" col-12 md:col-6" id="subproductosDataTable">
                  <SubProductosTable
                    selectedSubproductos={selectedSubproductos}
                    desde={desde}
                    hasta={hasta}
                    handleSeleccionarAdicional={handleSeleccionarAdicional}
                    seleccionSubProductoAdicional={
                      seleccionSubProductoAdicional
                    }
                    setSeleccionSubproductoAdicional={
                      setSeleccionSubproductoAdicional
                    }
                    subProductoAdicional={subProductoAdicional}
                    setSubProductoAdicional={setSubProductoAdicional}
                    descripcionSubProductos={descripcionSubProductos}
                    desdeSub={desdeSub}
                    setDesdeSub={setDesdeSub}
                    hastaSub={hastaSub}
                    setHastaSub={setHastaSub}
                    handleShowSubProductosAdicionales={
                      handleShowSubProductosAdicionales
                    }
                    showSubProductosAdicionales={showSubProductosAdicionales}
                    subproductosAdicionales={subproductosAdicionales}
                    selectedSubproductosFinales={selectedSubproductosFinales}
                    handleSubproductoSelectionChange={
                      handleSubproductoSelectionChange
                    }
                    handleSubProductoAdicional={handleSubProductoAdicional}
                    setDisplayConstruccion={setDisplayConstruccion}
                    displayConstruccion={displayConstruccion}
                    carro={carro}
                    currentIndex={currentIndex}
                  />
                </div>

                <div className=" col-12 md:col-6">
                  <ServiciosTable
                    carro={carro}
                    currentIndex={currentIndex}
                    servicios={servicios}
                    selectedServicios={selectedServicios}
                    handleServicioSelectionChange={
                      handleServicioSelectionChange
                    }
                  />
                </div>
                <div className="field col-12 md:col-12"></div>
              </div>
            </>
          )}
        </>
      )}

      <div
        className="field col-12 md:col-12"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          icon="pi pi-plus"
          label={`Agregar Sub Producto`}
          className="p-button-success mr-2 mb-2"
          onClick={handleSubProductoAdicional}
          style={{ width: "400px" }}
        />
        <Button
          visible={showSeleccion}
          label="Confirmar Selección"
          icon="pi pi-check"
          className="p-button mr-2 mb-2"
          onClick={handleConfirmarItem}
          style={{ width: "400px" }}
        ></Button>
        <Dialog
          visible={seleccionSubProductoAdicional}
          onHide={() => {
            setSeleccionSubproductoAdicional(false);
          }}
          style={{ width: "1200px" }}
          modal
          dismissableMask={false}
        >
          <div className="flex align-items-center justify-content-center">
            <div className="">
              <div className="">
                <h5>Seleccione Sub Producto Adicional</h5>
                <div className="p-fluid formgrid grid">
                  <div className="field col-12 md:col-6">
                    <label htmlFor="tipoSubP">Tipo Sub Producto</label>
                    <Dropdown
                      id="tipoSubP"
                      value={subProductoAdicional}
                      onChange={(e) => {
                        setSubProductoAdicional(e.value);
                      }}
                      options={descripcionSubProductos}
                      optionLabel="descripcion"
                      placeholder="Selecione"
                    ></Dropdown>
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="tipoSubP">Metros Cuadrados</label>
                    <InputNumber
                      id="metrajeSubp"
                      value={metrajeSubproductoAdicional}
                      onChange={(e) => {
                        setMetrajeSubproductoAdicional(e.value);
                      }}
                      placeholder="Selecione Metraje"
                    ></InputNumber>
                  </div>
                  <div
                    className="field col-12 md:col-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      icon="pi pi-plus"
                      label={`Agregar`}
                      className="p-button-rounded p-button-success p-button  mb-2 mt-2"
                      onClick={handleShowSubProductosAdicionales}
                      style={{ width: "400px" }}
                    />
                  </div>
                </div>
                {showSubProductosAdicionales && (
                  <>
                    <DataTable
                      value={subproductosAdicionales}
                      selection={selectedSubproductosFinales}
                      onSelectionChange={handleSubproductoSelectionChange}
                      className="p-datatable-gridlines"
                      showGridlines
                      rows={10}
                      dataKey="id"
                    >
                      <Column
                        selectionMode="multiple"
                        style={{ width: "3em" }}
                      />
                      <Column
                        body={(rowData) =>
                          !rowData.nombre
                            ? `${subProductoAdicional.name} 
                                                        ${rowData.id}`
                            : ""
                        }
                        header="Tipo"
                      />
                      <Column
                        header="Fecha Desde"
                      
                      />
                      <Column
                        header="Fecha Hasta"
                        
                      />
                      <Column
                        header="Tipo Cobro"
                      />
                      <Column
                        header="Acción"
                        body={(rowData) => (
                          <>
                            <Button
                              icon="pi pi-info"
                              className="p-button-rounded p-button p-button mr-2 mb-2 mt-2"
                              onClick={() => setDisplayConstruccion(true)}
                              label="Ver Más"
                            ></Button>

                            <Button
                              icon="pi pi-camera"
                              className="p-button-rounded p-button-warning p-button mb-2 mt-2 mr-2"
                              onClick={() => setDisplayConstruccion(true)}
                              label="Ver Fotos"
                            ></Button>

                            <Button
                              icon="pi pi-map"
                              className="p-button-rounded p-button-success p-button mr-2 mb-2"
                              onClick={() => setDisplayConstruccion(true)}
                              label="Ver Ubicación"
                            ></Button>
                            <Dialog
                              visible={displayConstruccion}
                              onHide={() => {
                                setDisplayConstruccion(false);
                              }}
                              style={{ width: "1200px" }}
                              modal
                              dismissableMask={false}
                            >
                              <div className="flex align-items-center justify-content-center">
                                <h4>En Construcción</h4>
                              </div>
                            </Dialog>
                          </>
                        )}
                      />
                    </DataTable>
                  </>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default CotizacionSelectSubProductosYServicios;
