import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const FormCrearCliente = ({
  tiposCliente,
  tiposDireccion,
  tiposContacto,
  nuevoTipo,
  setNuevoTipo,
  nuevoRut,
  setNuevoRut,
  nuevaRazon,
  setNuevaRazon,
  nuevoAlias,
  setNuevoAlias,
  nuevoFanta,
  setNuevoFanta,
  nuevoContactos,
  nuevoRepresentantes,
  nuevoDirecciones,
  nuevoNombreContacto,
  setNuevoNombreContacto,
  nuevoTipoContacto,
  setNuevoTipoContacto,
  nuevoTelefonoContacto,
  setNuevoTelefonoContacto,
  nuevoCorreoContacto,
  setNuevoCorreoContacto,
  displayCreateContacto,
  setDisplayCreateContacto,
  nuevoNombreRepresentante,
  setNuevoNombreRepresentante,
  nuevoRutRepresentante,
  setNuevoRutRepresentante,
  nuevoTelefonoRepresentante,
  setNuevoTelefonoRepresentante,
  nuevoCorreoRepresentante,
  setNuevoCorreoRepresentante,
  displayCreateRepresentante,
  setDisplayCreateRepresentante,
  nuevaDireccion,
  setNuevaDireccion,
  nuevoTipoDireccion,
  setNuevoTipoDireccion,
  displayCreateDireccion,
  setDisplayCreateDireccion,
  handleAgregarNuevoContacto,
  handleAgregarNuevoDireccion,
  handleAgregarNuevoRepresentante,
  isValid,
  setIsValid,
  isValidRepresentante,
  setIsValidRepresentante,
}) => {

  const cleanRut = (rut) => {
    return rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
  };

  const validateRut = (rut) => {
    if (!rut || typeof rut !== "string") return false;
    const cleanRutVal = cleanRut(rut);
    if (cleanRutVal.length < 2) return false;

    const body = cleanRutVal.slice(0, -1);
    const verifier = cleanRutVal.slice(-1);

    let sum = 0;
    let multiplier = 2;

    for (let i = body.length - 1; i >= 0; i--) {
      sum += multiplier * +body[i];
      multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const mod = 11 - (sum % 11);
    const expectedVerifier =
      mod === 11 ? "0" : mod === 10 ? "K" : mod.toString();

    return expectedVerifier === verifier;
  };

  const formatRut = (rut) => {
    const cleanRutVal = cleanRut(rut);
    let result =
      cleanRutVal.slice(-4, -1) +
      "-" +
      cleanRutVal.substr(cleanRutVal.length - 1);

    for (let i = 4; i < cleanRutVal.length; i += 3) {
      result = cleanRutVal.slice(-3 - i, -i) + "." + result;
    }

    return result;
  };

  const handleRutChange = (event) => {
    const inputRut = event.target.value;
    setNuevoRut(inputRut);

    const cleanRutVal = cleanRut(inputRut);
    setIsValid(validateRut(cleanRutVal));
  };

  const handleRutBlur = () => {
    if (isValid) {
      setNuevoRut(formatRut(nuevoRut));
    }
  };
  const handleRutRepresentanteChange = (event) => {
    const inputRut = event.target.value;
    setNuevoRutRepresentante(inputRut);

    const cleanRutVal = cleanRut(inputRut);
    setIsValidRepresentante(validateRut(cleanRutVal));
  };

  const handleRutRepresentanteBlur = () => {
    if (isValidRepresentante) {
      setNuevoRutRepresentante(formatRut(nuevoRutRepresentante));
    }
  };
  return (
    <>
      <div className="card">
        <h3 style={{ marginBottom: "5px" }}>Datos Nuevo Cliente</h3>
        <h7 style={{ marginTop: "5px" }}>
          Ingrese los datos del cliente a registrar
        </h7>
        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-4 mt-2">
            <label style={{ marginRight: "10px" }}>Rut</label>
            <InputText
              id="rut"
              type="text"
              value={nuevoRut}
              onChange={handleRutChange}
              onBlur={handleRutBlur}
              style={{ borderColor: isValid ? "black" : "red" }}
            />
            {!isValid && <p style={{ color: "red" }}>RUT Inválido</p>}
          </div>
          <div className="field col-12 md:col-4 mt-2">
            <label style={{ marginRight: "10px" }}>Razón Social / Nombre</label>
            <InputText
              id="razonSocial"
              type="text"
              value={nuevaRazon}
              onChange={(e) => setNuevaRazon(e.target.value.toUpperCase())}
            />
          </div>
          <div className="field col-12 md:col-4 mt-2">
            <label htmlFor="tipocliente">Tipo de Cliente</label>
            <Dropdown
              id="tipocliente"
              value={nuevoTipo}
              onChange={(e) => setNuevoTipo(e.value)}
              options={tiposCliente}
              optionLabel="name"
              placeholder="Seleccione El Tipo De Cliente"
            />
          </div>
          <div className="field col-12 md:col-6 mt-2">
            <label style={{ marginRight: "10px" }}>Alias</label>
            <InputText
              id="alias"
              type="text"
              value={nuevoAlias}
              onChange={(e) => setNuevoAlias(e.target.value.toUpperCase())}
            />
          </div>
          <div className="field col-12 md:col-6 mt-2">
            <label style={{ marginRight: "10px" }}>Nombre Fantasía</label>
            <InputText
              id="nombreFantasia"
              type="text"
              value={nuevoFanta}
              onChange={(e) => setNuevoFanta(e.target.value.toUpperCase())}
            />
          </div>

          <div className="field col-12 md:col-12 mt-2">
            <label style={{ marginRight: "10px" }}>Contactos</label>
            <DataTable
              value={nuevoContactos}
              header="Contactos Agregados"
              emptyMessage="Aún no agrega contactos"
              className="p-datatable-gridlines"
            >
              <Column header="Nombre" field="nombre"></Column>
              <Column header="Tipo" field="tipo"></Column>
              <Column header="Teléfono" field="telefono"></Column>
              <Column header="Correo" field="correo"></Column>
            </DataTable>
            <Button
              label="Agregar Contacto"
              className="p-button-success mt-4 mr-2 mb-2"
              onClick={() => setDisplayCreateContacto(true)}
            />
            <Dialog
              blockScroll
              visible={displayCreateContacto}
              onHide={() => setDisplayCreateContacto(false)}
              style={{ width: "85vw" }}
              modal
            >
              <div className="p-fluid formgrid grid">
                <div className="field col-9 md:col-9 mt-2">
                  <h2>Agregar Contactos</h2>
                </div>
                <div className="field col-3 md:col-3 mt-2"></div>
                <div className="field col-12 md:col-3">
                  <label style={{ marginRight: "10px" }}>Nombre</label>
                  <InputText
                    id="nombreContacto"
                    type="text"
                    value={nuevoNombreContacto}
                    onChange={(e) =>
                      setNuevoNombreContacto(e.target.value.toUpperCase())
                    }
                  />
                </div>
                <div className="field col-12 md:col-3 ">
                  <label style={{ marginRight: "10px" }}>Tipo</label>
                  <Dropdown
                    id="tipoContacto"
                    value={nuevoTipoContacto}
                    onChange={(e) => setNuevoTipoContacto(e.value)}
                    options={tiposContacto}
                    optionLabel="name"
                    placeholder="Seleccione El Tipo De Contacto"
                  />
                </div>
                <div className="field col-12 md:col-3 ">
                  <label style={{ marginRight: "10px" }}>Telefono</label>
                  <InputText
                    id="telefonoContacto"
                    type="text"
                    value={nuevoTelefonoContacto}
                    onChange={(e) => setNuevoTelefonoContacto(e.target.value)}
                  />
                </div>
                <div className="field col-12 md:col-3 ">
                  <label style={{ marginRight: "10px" }}>Correo</label>
                  <InputText
                    id="correoContacto"
                    type="mail"
                    value={nuevoCorreoContacto}
                    onChange={(e) =>
                      setNuevoCorreoContacto(e.target.value.toUpperCase())
                    }
                  />
                </div>
                <div className="field col-12 md:col-12 ">
                  <Button
                    label="Agregar Contacto"
                    className="p-button-success mr-2 mb-2"
                    icon="pi pi-plus"
                    onClick={(e) => handleAgregarNuevoContacto()}
                  />
                </div>
                <div className="field col-12 md:col-12 mt-4">
                  <DataTable
                    value={nuevoContactos}
                    header="Contactos Agregados"
                    emptyMessage="Aún no agrega contactos"
                    className="p-datatable-gridlines"
                  >
                    <Column header="Nombre" field="nombre"></Column>
                    <Column header="Tipo" field="tipo"></Column>
                    <Column header="Teléfono" field="telefono"></Column>
                    <Column header="Correo" field="correo"></Column>
                  </DataTable>
                </div>
                <div className="field col-12 md:col-12 ">
                  <Button
                    label="Guardar Contactos"
                    className="p-button mr-2 mb-2"
                    icon="pi pi-save"
                    onClick={(e) => setDisplayCreateContacto(false)}
                  />
                </div>
              </div>
            </Dialog>
          </div>

          <div className="field col-12 md:col-12 mt-2">
            <label style={{ marginRight: "10px" }}>
              Representantes Legales
            </label>
            <DataTable
              value={nuevoRepresentantes}
              header={"Representantes agregados"}
              emptyMessage="Aún no agrega representantes"
              className="p-datatable-gridlines"
            >
              <Column header="Nombre" field="nombre"></Column>
              <Column header="Rut" field="rut"></Column>
              <Column header="Teléfono" field="telefono"></Column>
              <Column header="Correo" field="correo"></Column>
            </DataTable>
            <Button
              label="Agregar Representantes Legales"
              className="p-button-success mt-4 mr-2 mb-2"
              onClick={() => setDisplayCreateRepresentante(true)}
            />
            <Dialog
              blockScroll
              visible={displayCreateRepresentante}
              onHide={() => setDisplayCreateRepresentante(false)}
              style={{ width: "85vw" }}
              modal
            >
              <div className="p-fluid formgrid grid">
                <div className="field col-9 md:col-9 mt-2">
                  <h2>Agregar Representantes Legales</h2>
                </div>
                <div className="field col-3 md:col-3 mt-2"></div>
                <div className="field col-12 md:col-3">
                  <label style={{ marginRight: "10px" }}>Nombre</label>
                  <InputText
                    id="nombreRepresentante"
                    type="text"
                    value={nuevoNombreRepresentante}
                    onChange={(e) =>
                      setNuevoNombreRepresentante(e.target.value.toUpperCase())
                    }
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label style={{ marginRight: "10px" }}>Rut</label>
                  <InputText
                    id="rutRepresentante"
                    type="text"
                    value={nuevoRutRepresentante}
                    onChange={handleRutRepresentanteChange}
                    onBlur={handleRutRepresentanteBlur}
                    style={{ borderColor: isValidRepresentante ? 'black' : 'red' }}
                  />
                  {!isValidRepresentante && <p style={{ color: 'red' }}>RUT Inválido</p>}
                </div>
                <div className="field col-12 md:col-3">
                  <label style={{ marginRight: "10px" }}>Teléfono</label>
                  <InputText
                    id="telefonoRepresentante"
                    type="text"
                    value={nuevoTelefonoRepresentante}
                    onChange={(e) =>
                      setNuevoTelefonoRepresentante(e.target.value)
                    }
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label style={{ marginRight: "10px" }}>Correo</label>
                  <InputText
                    id="correoRepresentante"
                    type="text"
                    value={nuevoCorreoRepresentante}
                    onChange={(e) =>
                      setNuevoCorreoRepresentante(e.target.value.toUpperCase())
                    }
                  />
                </div>
                <div className="field col-12 md:col-12 ">
                  <Button
                    label="Agregar Representante Legal"
                    className="p-button-success mr-2 mb-2"
                    icon="pi pi-plus"
                    onClick={(e) => handleAgregarNuevoRepresentante()}
                  />
                </div>
                <div className="field col-12 md:col-12 mt-4">
                  <DataTable
                    value={nuevoRepresentantes}
                    header="Representantes Legales Agregados"
                    emptyMessage="Aún no agrega Representantes Legales"
                    className="p-datatable-gridlines"
                  >
                    <Column header="Nombre" field="nombre"></Column>
                    <Column header="Rut" field="rut"></Column>
                    <Column header="Teléfono" field="telefono"></Column>
                    <Column header="Correo" field="correo"></Column>
                  </DataTable>
                </div>
                <div className="field col-12 md:col-12 ">
                  <Button
                    label="Guardar Representantes Legales"
                    className="p-button mr-2 mb-2"
                    icon="pi pi-save"
                    onClick={(e) => setDisplayCreateRepresentante(false)}
                  />
                </div>
              </div>
            </Dialog>
          </div>

          <div className="field col-12 md:col-12 mt-2">
            <label style={{ marginRight: "10px" }}>Direcciones</label>
            <DataTable
              value={nuevoDirecciones}
              header={"Direcciones agregadas"}
              emptyMessage="Aún no agregan direcciones"
              className="p-datatable-gridlines"
            >
              <Column header="Tipo" field="tipo"></Column>
              <Column header="Dirección" field="direccion"></Column>
            </DataTable>
            <Button
              label="Agregar Direcciones"
              className="p-button-success mt-4 mr-2 mb-2"
              onClick={() => setDisplayCreateDireccion(true)}
            />
            <Dialog
              blockScroll
              visible={displayCreateDireccion}
              onHide={() => setDisplayCreateDireccion(false)}
              style={{ width: "85vw" }}
              modal
            >
              <div className="p-fluid formgrid grid">
                <div className="field col-9 md:col-9 mt-2">
                  <h2>Agregar Direcciones</h2>
                </div>
                <div className="field col-3 md:col-3 mt-2"></div>
                <div className="field col-12 md:col-6 ">
                  <label style={{ marginRight: "10px" }}>Tipo</label>
                  <Dropdown
                    id="tipodireccion"
                    value={nuevoTipoDireccion}
                    onChange={(e) => setNuevoTipoDireccion(e.value)}
                    options={tiposDireccion}
                    optionLabel="name"
                    placeholder="Seleccione El Tipo De Direccion"
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label style={{ marginRight: "10px" }}>Direccion</label>
                  <InputText
                    id="direccion"
                    type="text"
                    value={nuevaDireccion}
                    onChange={(e) =>
                      setNuevaDireccion(e.target.value.toUpperCase())
                    }
                  />
                </div>
                <div className="field col-12 md:col-12 ">
                  <Button
                    label="Agregar dirección"
                    className="p-button-success mr-2 mb-2"
                    icon="pi pi-plus"
                    onClick={(e) => handleAgregarNuevoDireccion()}
                  />
                </div>
                <div className="field col-12 md:col-12 mt-4">
                  <DataTable
                    value={nuevoDirecciones}
                    header="Direcciones Agregadas"
                    emptyMessage="Aún no agrega direcciones"
                    className="p-datatable-gridlines"
                  >
                    <Column header="Tipo" field="tipo"></Column>
                    <Column header="Dirección" field="direccion"></Column>
                  </DataTable>
                </div>
                <div className="field col-12 md:col-12 ">
                  <Button
                    label="Guardar Direcciones"
                    className="p-button mr-2 mb-2"
                    icon="pi pi-save"
                    onClick={(e) => setDisplayCreateDireccion(false)}
                  />
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCrearCliente;
