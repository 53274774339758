import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
const SubProductosTable = ({
    selectedSubproductos,
    desde,
    hasta,
    handleSeleccionarAdicional,
    seleccionSubProductoAdicional,
    setSeleccionSubproductoAdicional,
    subProductoAdicional,
    setSubProductoAdicional,
    descripcionSubProductos,
    desdeSub,
    setDesdeSub,
    hastaSub,
    setHastaSub,
    handleShowSubProductosAdicionales,
    showSubProductosAdicionales,
    subproductosAdicionales,
    selectedSubproductosFinales,
    handleSubproductoSelectionChange,
    handleSubProductoAdicional,
    setDisplayConstruccion,
    displayConstruccion,
    carro,
    currentIndex,

}) => {
    return (
        <>
            <h5>{`Sub Productos para: ${carro[currentIndex].nombre_producto}`}</h5>

            <DataTable
                value={selectedSubproductos}
                className="p-datatable-gridlines"
                showGridlines
                rows={10}
                dataKey="id"
                emptyMessage="No tiene subproductos asociados"
            >
                <Column field="descripcion" header="Sub Producto" />
                <Column
                    field="incluido"
                    header="Tipo"
                    body={(rowData) =>
                        rowData.incluido ? "Incluido" : "Adicional"
                    }
                />
                <Column
                    header="Fecha Desde"
                    body={(rowData) => {
                        const date = new Date(desde);
                        return date instanceof Date && !isNaN(date)
                          ? date.toLocaleDateString()
                          : "Invalid Date";
                      }}
                />
                <Column
                    header="Fecha Hasta"
                    body={(rowData) => {
                        const date = new Date(hasta);
                        return date instanceof Date && !isNaN(date)
                          ? date.toLocaleDateString()
                          : "Invalid Date";
                      }}
                />
               
            </DataTable>
        </>
    );
};

export default SubProductosTable;
