import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import FormCrearCliente from "./FormCrearCliente";
import StepByStep from "./StepByStep";
import InputTextRut from "./InputTextRut";

const FormBusquedaCliente = ({
  criterioBusqueda,
  hanldeCriterioBusqueda,
  clienteLocal,
  handleClienteLocal,
  rut,
  setRut,
  razonSocial,
  setRazonSocial,
  alias,
  setAlias,
  nombreFantasia,
  setNombreFantasia,
  dni,
  setDni,
  searchCliente,
  isFormVisible,
  displayCreateClient,
  setDisplayCreateClient,
  confirmationDialogFooterCliente,
  nombreRepresentante,
  setNombreRepresentante,
  searchClienteByRepresentante,
  tiposCliente,
  tiposDireccion,
  nuevoTipo,
  setNuevoTipo,
  nuevoRut,
  setNuevoRut,
  nuevaRazon,
  setNuevaRazon,
  nuevoAlias,
  setNuevoAlias,
  nuevoFanta,
  setNuevoFanta,
  nuevoContactos,
  setNuevoContactos,
  nuevoRepresentantes,
  setNuevoRepresentantes,
  nuevoDirecciones,
  setNuevoDirecciones,
  nuevoNombreContacto,
  setNuevoNombreContacto,
  nuevoTipoContacto,
  setNuevoTipoContacto,
  nuevoTelefonoContacto,
  setNuevoTelefonoContacto,
  nuevoCorreoContacto,
  setNuevoCorreoContacto,
  displayCreateContacto,
  setDisplayCreateContacto,
  nuevoNombreRepresentante,
  setNuevoNombreRepresentante,
  nuevoRutRepresentante,
  setNuevoRutRepresentante,
  nuevoTelefonoRepresentante,
  setNuevoTelefonoRepresentante,
  nuevoCorreoRepresentante,
  setNuevoCorreoRepresentante,
  displayCreateRepresentante,
  setDisplayCreateRepresentante,
  nuevaDireccion,
  setNuevaDireccion,
  nuevoTipoDireccion,
  setNuevoTipoDireccion,
  displayCreateDireccion,
  setDisplayCreateDireccion,
  clearVariablesNuevoCliente,
  clearVariablesNuevoContacto,
  clearVariablesNuevoDirecciones,
  clearVariablesNuevoRepresentante,
  handleAgregarNuevoCliente,
  handleAgregarNuevoContacto,
  handleAgregarNuevoDireccion,
  handleAgregarNuevoRepresentante,
  checkFormClientes,
  checkFormContactos,
  checkFormDirecciones,
  checkFormRepresentantes,
  isValidFormClientes,
  isValidFormContactos,
  isValidFormDirecciones,
  isValidFormRepresentantes,
  isValid,
  setIsValid,
  isValidRepresentante,
  setIsValidRepresentante,
}) => {

  const cleanRut = (rut) => {
    return rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
  };

  const validateRut = (rut) => {
    if (!rut || typeof rut !== "string") return false;
    const cleanRutVal = cleanRut(rut);
    if (cleanRutVal.length < 2) return false;

    const body = cleanRutVal.slice(0, -1);
    const verifier = cleanRutVal.slice(-1);

    let sum = 0;
    let multiplier = 2;

    for (let i = body.length - 1; i >= 0; i--) {
      sum += multiplier * +body[i];
      multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const mod = 11 - (sum % 11);
    const expectedVerifier =
      mod === 11 ? "0" : mod === 10 ? "K" : mod.toString();

    return expectedVerifier === verifier;
  };

  const formatRut = (rut) => {
    const cleanRutVal = cleanRut(rut);
    let result =
      cleanRutVal.slice(-4, -1) +
      "-" +
      cleanRutVal.substr(cleanRutVal.length - 1);

    for (let i = 4; i < cleanRutVal.length; i += 3) {
      result = cleanRutVal.slice(-3 - i, -i) + "." + result;
    }

    return result;
  };

  const handleRutChange = (event) => {
    const inputRut = event.target.value;
    setRut(inputRut);

    const cleanRutVal = cleanRut(inputRut);
    setIsValid(validateRut(cleanRutVal));
  };

  const handleRutBlur = () => {
    if (isValid) {
      setRut(formatRut(rut));
    }
  };
  return (
    <>
      {true && <StepByStep activeIndex={0} />}

      <h3 style={{ marginBottom: "5px" }}>Buscar Cliente</h3>
      <h7 style={{ marginTop: "5px" }}>
        A qué cliente desea crearle una cotización?
      </h7>
      <div className="p-fluid formgrid grid">
        <div className="field col-12 md:col-9 mt-2">
          <label style={{ marginRight: "10px", marginTop: "10px" }}>
            Búsqueda por Empresa
          </label>
          <InputSwitch
            checked={criterioBusqueda}
            onChange={hanldeCriterioBusqueda}
          />
          <label>Búsqueda por representante legal</label>
        </div>

        {!criterioBusqueda && (
          <>
            <div className="field col-12 md:col-12 mt-2">
              <label style={{ marginRight: "10px" }}>RUT</label>
              <InputSwitch
                checked={clienteLocal}
                onChange={handleClienteLocal}
              />
              <label style={{ marginLeft: "10px" }}>DNI</label>
            </div>

            <div className="field col-12 md:col-6">
              {!clienteLocal && (
                <>
                  <label htmlFor="rut">RUT Cliente</label>
                  <InputText
                    id="rut"
                    type="text"
                    value={rut}
                    onChange={handleRutChange}
                    onBlur={handleRutBlur}
                    style={{ borderColor: isValid ? "black" : "red" }}
                  />
                  {!isValid && <p style={{ color: "red" }}>RUT Inválido</p>}
                </>
              )}
              {clienteLocal && (
                <>
                  <label htmlFor="dni">DNI o ID de Cliente</label>
                  <InputText
                    id="dni"
                    type="text"
                    value={dni}
                    onChange={(e) => setDni(e.target.value)}
                  />
                </>
              )}
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="razonSocial">Razón Social (Nombre)</label>
              <InputText
                id="razonSocial"
                type="text"
                value={razonSocial}
                onChange={(e) => setRazonSocial(e.target.value.toUpperCase())}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="nombreFan">Nombre Fantasía</label>
              <InputText
                id="nombreFan"
                type="text"
                value={nombreFantasia}
                onChange={(e) =>
                  setNombreFantasia(e.target.value.toUpperCase())
                }
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="alias">Alias</label>
              <InputText
                id="alias"
                type="text"
                value={alias}
                onChange={(e) => setAlias(e.target.value.toUpperCase())}
              />
            </div>
            <div
              className="field col-12 md:col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                type="button"
                onClick={searchCliente}
                label="Buscar Cliente"
                icon="pi pi-search"
                className="p-button mr-2 mb-2"
                visible={isFormVisible}
                style={{ width: "400px" }}
              />
              <Button
                type="button"
                onClick={() => setDisplayCreateClient(true)}
                label="Crear Cliente"
                icon="pi pi-plus"
                className="p-button-success mr-2 mb-2"
                style={{ width: "400px" }}
              />
            </div>
          </>
        )}
        {criterioBusqueda && (
          <>
            <div className="field col-12 md:col-12">
              <label htmlFor="nrepleg">Nombre Representante Legal</label>
              <InputText
                id="nrepleg"
                type="text"
                value={nombreRepresentante}
                onChange={(e) =>
                  setNombreRepresentante(e.target.value.toUpperCase())
                }
              />
            </div>

            <div
              className="field col-12 md:col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                type="button"
                onClick={searchClienteByRepresentante}
                label="Buscar Cliente"
                icon="pi pi-search"
                className="p-button mr-2 mb-2"
                visible={isFormVisible}
                style={{ width: "400px" }}
              />
              <Button
                type="button"
                onClick={() => setDisplayCreateClient(true)}
                label="Crear Cliente"
                icon="pi pi-plus"
                className="p-button-success mr-2 mb-2"
                style={{ width: "400px" }}
              />
            </div>
          </>
        )}
        <Dialog
          blockScroll
          visible={displayCreateClient}
          onHide={() => setDisplayCreateClient(false)}
          style={{ width: "85vw" }}
          modal
          footer={confirmationDialogFooterCliente}
        >
          <FormCrearCliente
            tiposCliente={tiposCliente}
            tiposDireccion={tiposDireccion}
            tiposContacto={tiposDireccion}
            nuevoTipo={nuevoTipo}
            setNuevoTipo={setNuevoTipo}
            nuevoRut={rut}
            setNuevoRut={setRut}
            nuevaRazon={razonSocial}
            setNuevaRazon={setRazonSocial}
            nuevoAlias={alias}
            setNuevoAlias={setAlias}
            nuevoFanta={nombreFantasia}
            setNuevoFanta={setNombreFantasia}
            nuevoContactos={nuevoContactos}
            setNuevoContactos={setNuevoContactos}
            nuevoRepresentantes={nuevoRepresentantes}
            setNuevoRepresentantes={setNuevoRepresentantes}
            nuevoDirecciones={nuevoDirecciones}
            setNuevoDirecciones={setNuevoDirecciones}
            nuevoNombreContacto={nuevoNombreContacto}
            setNuevoNombreContacto={setNuevoNombreContacto}
            nuevoTipoContacto={nuevoTipoContacto}
            setNuevoTipoContacto={setNuevoTipoContacto}
            nuevoTelefonoContacto={nuevoTelefonoContacto}
            setNuevoTelefonoContacto={setNuevoTelefonoContacto}
            nuevoCorreoContacto={nuevoCorreoContacto}
            setNuevoCorreoContacto={setNuevoCorreoContacto}
            displayCreateContacto={displayCreateContacto}
            setDisplayCreateContacto={setDisplayCreateContacto}
            nuevoNombreRepresentante={nuevoNombreRepresentante}
            setNuevoNombreRepresentante={setNuevoNombreRepresentante}
            nuevoRutRepresentante={nuevoRutRepresentante}
            setNuevoRutRepresentante={setNuevoRutRepresentante}
            nuevoTelefonoRepresentante={nuevoTelefonoRepresentante}
            setNuevoTelefonoRepresentante={setNuevoTelefonoRepresentante}
            nuevoCorreoRepresentante={nuevoCorreoRepresentante}
            setNuevoCorreoRepresentante={setNuevoCorreoRepresentante}
            displayCreateRepresentante={displayCreateRepresentante}
            setDisplayCreateRepresentante={setDisplayCreateRepresentante}
            nuevaDireccion={nuevaDireccion}
            setNuevaDireccion={setNuevaDireccion}
            nuevoTipoDireccion={nuevoTipoDireccion}
            setNuevoTipoDireccion={setNuevoTipoDireccion}
            displayCreateDireccion={displayCreateDireccion}
            setDisplayCreateDireccion={setDisplayCreateDireccion}
            clearVariablesNuevoCliente={clearVariablesNuevoCliente}
            clearVariablesNuevoContacto={clearVariablesNuevoContacto}
            clearVariablesNuevoDirecciones={clearVariablesNuevoDirecciones}
            clearVariablesNuevoRepresentante={clearVariablesNuevoRepresentante}
            handleAgregarNuevoCliente={handleAgregarNuevoCliente}
            handleAgregarNuevoContacto={handleAgregarNuevoContacto}
            handleAgregarNuevoDireccion={handleAgregarNuevoDireccion}
            handleAgregarNuevoRepresentante={handleAgregarNuevoRepresentante}
            checkFormClientes={checkFormClientes}
            checkFormContactos={checkFormContactos}
            checkFormDirecciones={checkFormDirecciones}
            checkFormRepresentantes={checkFormRepresentantes}
            isValidFormClientes={isValidFormClientes}
            isValidFormContactos={isValidFormContactos}
            isValidFormDirecciones={isValidFormDirecciones}
            isValidFormRepresentantes={isValidFormRepresentantes}
            isValid={isValid}
            setIsValid={setIsValid}
            isValidRepresentante={isValidRepresentante}
            setIsValidRepresentante={setIsValidRepresentante}
          ></FormCrearCliente>
        </Dialog>
      </div>
    </>
  );
};

export default FormBusquedaCliente;
