import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Dialog } from "primereact/dialog";
import { useGlobalContext } from "../Config";
import UseScrollOnStateChange from "../components/UseScrollOnStateChange";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom"; // Use useLocation to get the current URL
import { InputNumber } from "primereact/inputnumber";
import {
  calculateSubtotal,
  formatDatesIfNeeded,
  calculateTarifaMensualWithMetraje,
  calculateTarifaMensualServicios,
  calculateTarifaMensualServiciosUnicos,
} from "../utilities/Helper";
import moment from "moment";
import "moment/locale/es"; // Import Spanish locale

const cantidadDiasAvisoContrato = [
  { label: "15 días", value: 15 },
  { label: "30 días", value: 30 },
  { label: "45 días", value: 45 },
  { label: "60 días", value: 60 },
  { label: "90 días", value: 90 },
];
const dateFormats = [
  "DD/MM/YYYY",
  "MM/DD/YYYY",
  "YYYY/MM/DD",
  "YYYY-MM-DD",
  "DD-MM-YYYY",
  "MM-DD-YYYY",
  "DD MMM YYYY",
  "MMM DD, YYYY",
  "MMMM DD, YYYY",
  "YYYY MMM DD",
];
const RevisarCotizacion = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idCliente = parseInt(searchParams.get("idCliente"));
  const idCotizacion = parseInt(searchParams.get("idCotizacion"));
  const [carro, setCarro] = useState([]);
  const [selectedItemsParam, setSelectedItemsParams] = useState(null);
  const [cotizacion, setCotizacion] = useState(null);
  const buildDetalleCotizacionUrl = (idCotizacion) => {
    return `${baseUrl}getDetalleCotizacionByIdCotizacion?idCotizacion=${idCotizacion}`;
  };
  const getDetalleData = async (rowData) => {
    try {
      const detalleCotizacionUrl = buildDetalleCotizacionUrl(idCotizacion);

      const data = await fetchData(detalleCotizacionUrl);

      if (data.mensaje === "OK") {
        const encodedParams = data.detalles
          .map((item) => {
            // Omit 'desde' and 'hasta' properties
            const { ...rest } = item;
            // Convert the object to a URL parameter string
            return encodeURIComponent(JSON.stringify(rest));
          })
          .join(",");
        setSelectedItemsParams(encodedParams);
      } else {
        alert(data.mensaje);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const buildCotizacionData = (idCotizacion) => {
    return `${baseUrl}getCotizacionById?idCotizacion=${idCotizacion}`;
  };
  const getCotizacionData = async () => {
    try {
      const cotizacionUrl = buildCotizacionData(idCotizacion);

      const data = await fetchData(cotizacionUrl);

      if (data.mensaje === "OK") {
        setCotizacion(data.cotizacion);
      } else {
        alert(data.mensaje);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDetalleData();
    getCotizacionData();
  }, []);
  const [diasAviso, setDiasAviso] = useState(15);
  const [subtotalServicios, setSubtotalServicios] = useState(0);

  const [showCerrarContrato, setShowCerrarContrato] = useState(false);
  const { baseUrl } = useGlobalContext();

  const [desde, setDesde] = useState(searchParams.get("desde"));
  const [hasta, setHasta] = useState(searchParams.get("hasta"));
  const [rowClick, setRowClick] = useState(true);

  const [loading, setLoading] = useState(false);

  const [flattenedDataArriendo, setFlattenedDataArriendo] = useState([]);
  const [serviciosUnicos, setServiciosUnicos] = useState([]);

  const [hastaSub, setHastaSub] = useState(null);
  const [tramoCounter, setTramoCounter] = useState(0);
  const [itemsPorEliminar, setItemsPorEliminar] = useState([]);
  const [monedaOptionsFetched, setMonedaOptionsFetched] = useState(false);
  const [maxAmountOfCuotas, setMaxAmountOfCuotas] = useState(0);
  const [cuotas, setCuotas] = useState(0);
  const [itemCuotas, setItemCuotas] = useState(null);

  const [showTramoModalArriendo, setShowTramoModalArriendo] = useState(false);

  const fetchData = async (url) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(
        `Network response was not ok. Status: ${response.status}`
      );
    }

    return response.json();
  };
  const navigate = useNavigate();

  const [showTramoModalServicio, setShowTramoModalServicio] = useState(false);
  const [showTramoModalUnico, setShowTramoModalUnico] = useState(false);
  const [iva, setIva] = useState(0);

  const handleNewTramoCuota = (rowData) => {
    clearTramosVariables();
    if (rowData.tarifa_cliente <= 0) {
      alert("Debe añadir una tarifa para poder elegir cuotas");
    } else {
      setNombreCuadro(rowData.descripcion);

      // Ensure the dates are in dd/mm/yyyy format
      const startDate = moment(desde, dateFormats, true);
      const endDate = moment(hasta, dateFormats, true);

      if (!startDate.isValid() || !endDate.isValid()) {
        alert("Fechas no válidas");
        return;
      }

      const diffInMonths = endDate.diff(startDate, "months");

      // Set maxAmountOfCuotas with the number of months
      setMaxAmountOfCuotas(diffInMonths + 1);
      setShowTramoModalUnico(true);
      setCuotas(0);
      setItemCuotas(rowData);
    }
  };

  const handleNewTramoServicio = (rowData) => {
    clearTramosVariables();
    setNombreCuadro(rowData.descripcion);

    // Parse dates using moment
    const desdeDate = moment(rowData.desde, dateFormats, true).toDate();
    const hastaDate = moment(rowData.hasta, dateFormats, true).toDate();

    if (
      !moment(rowData.desde, dateFormats, true).isValid() ||
      !moment(rowData.hasta, dateFormats, true).isValid()
    ) {
      alert("Fechas no válidas");
      return;
    }

    const tramo1 = {
      ...rowData,
      desde: desdeDate,
      hasta: hastaDate,
    };
    const tramo2 = {
      ...rowData,
      desde: desdeDate,
      hasta: hastaDate,
    };

    // No need to adjust for timezone offset since moment handles it properly

    setHastaTramoGeneral(hastaDate);
    setTramos([tramo1, tramo2]);

    setShowTramoModalServicio(true);
  };
  const [servicios, setServicios] = useState([]);

  const [servicioObraCivil, setServicioObraCivil] = useState([]);
  const [subtotalUnico, setSubtotalUnico] = useState(0);

  const [subtotal, setSubtotal] = useState(0);
  const [subtotalArriendo, setSubtotalArriendo] = useState(0);

  useEffect(() => {
    const calculatedSubtotal =
      parseFloat(subtotalArriendo) + parseFloat(subtotalServicios);

    const calculatedIva = calculatedSubtotal * 0.19;

    const calculatedTotal = calculatedSubtotal + calculatedIva;

    setSubtotal(parseFloat(calculatedSubtotal.toFixed(2)));
    setIva(parseFloat(calculatedIva.toFixed(2)));
    setTotal(parseFloat(calculatedTotal.toFixed(2)));
  }, [subtotalArriendo, subtotalServicios]);

  useEffect(() => {
    if (selectedItemsParam) {
      try {
        const decodedParam = decodeURIComponent(selectedItemsParam);

        if (decodedParam.startsWith("[")) {
          const selectedItems = JSON.parse(decodedParam);
          setCarro(selectedItems);
        } else {
          const selectedItems = JSON.parse(`[${decodedParam}]`);

          setCarro(selectedItems);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [selectedItemsParam]);

  useEffect(() => {
    if (carro.length > 0) {
      const serviciosInside = [];
      const subproductosInside = [];
      const serviciosUnicosInside = [];
      const flattenedDataArriendoInside = [];

      carro.forEach((item) => {
        if (item.relacion === "subproducto") {
          subproductosInside.push(item);
        } else if (item.relacion === "producto") {
          flattenedDataArriendoInside.push(item);
        } else if (item.relacion === "servicio") {
          if (
            !(
              item.descripcion.includes("Habilitacion Fibra") ||
              item.descripcion.includes("Obra Civil")
            )
          ) {
            if (
              item.descripcion.includes("Agua") ||
              item.descripcion.includes("Electricidad")
            ) {
              item.remarcador = true;
              serviciosInside.push(item);
            } else {
              item.remarcador = false;
              serviciosInside.push(item);
            }
          } else {
            serviciosUnicosInside.push(item);
          }
        }
      });

      subproductosInside.forEach((subprod) => {
        flattenedDataArriendoInside.push(subprod);
      });

      // Group by descripcion
      const groupByDescripcion = (arr) => {
        const groups = arr.reduce((acc, item) => {
          if (!acc[item.descripcion]) {
            acc[item.descripcion] = [];
          }
          acc[item.descripcion].push(item);
          return acc;
        }, {});

        return Object.values(groups);
      };

      // Sort groups by date ascending
      const sortByDate = (a, b) =>
        moment(a.desde, dateFormats, true).toDate() -
        moment(b.desde, dateFormats, true).toDate();

      // Sort groups and merge into a single array
      const mergeSortedGroups = (groups) => {
        return groups.map((group) => group.sort(sortByDate)).flat();
      };

      // Separate and sort
      const sortedServiciosUnicos = mergeSortedGroups([
        ...groupByDescripcion(unsplittable(serviciosUnicosInside)),
        ...groupByDescripcion(splittable(serviciosUnicosInside)),
      ]);

      const sortedFlattenedDataArriendo = mergeSortedGroups([
        ...groupByDescripcion(unsplittable(flattenedDataArriendoInside)),
        ...groupByDescripcion(splittable(flattenedDataArriendoInside)),
      ]);

      const sortedServicios = mergeSortedGroups([
        ...groupByDescripcion(unsplittable(serviciosInside)),
        ...groupByDescripcion(splittable(serviciosInside)),
      ]);

      setServiciosUnicos(sortedServiciosUnicos);
      setFlattenedDataArriendo(sortedFlattenedDataArriendo);
      setServicios(sortedServicios);
    }
  }, [carro]);

  const unsplittable = (arr) =>
    arr.filter((item) => !item.descripcion.includes(" - "));
  const splittable = (arr) =>
    arr.filter((item) => item.descripcion.includes(" - "));
  const [tramos, setTramos] = useState([]);
  const [nombreCuadro, setNombreCuadro] = useState("");
  const [hastaTramoGeneral, setHastaTramoGeneral] = useState(null);
  const [tarifaTramo, setTarifaTramo] = useState(0);
  const [monedaOptions, setMonedaOptions] = useState([]);
  useEffect(() => {
    const fetchMonedaOptions = async () => {
      try {
        const response = await fetch(`${baseUrl}getAllMonedas`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setMonedaOptions(data.monedas);
        // Set monedaOptionsFetched to true after fetching monedaOptions
        setMonedaOptionsFetched(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Optionally, handle the error state here
      }
    };

    fetchMonedaOptions();
  }, []); // Empty dependency array ensures this effect runs only once
  const [total, setTotal] = useState(0);

  const handleTramoConfirmationArriendo = async (descripcion) => {
    if (loading) return;
    setLoading(true);
    const flattenedDataArriendoCopy = [...flattenedDataArriendo];
    const serviciosArrayCopy = [...servicios];
    const indexToRemoveArriendo = flattenedDataArriendoCopy.findIndex(
      (item) => item.descripcion === descripcion
    );
    const indexToRemoveServicios = serviciosArrayCopy.findIndex(
      (item) =>
        item.descripcion.includes(descripcion) &&
        item.descripcion.includes("Gasto Comun")
    );

    const gastoComunItem = serviciosArrayCopy.find(
      (item) =>
        item.descripcion.includes(descripcion) &&
        item.descripcion.includes("Gasto Comun")
    );

    if (indexToRemoveArriendo !== -1) {
      const itemToRemove = flattenedDataArriendoCopy[indexToRemoveArriendo];
      await deactivateItem(itemToRemove);
      flattenedDataArriendoCopy.splice(indexToRemoveArriendo, 1);
      flattenedDataArriendoCopy.push(...tramos);

      const matchingItems = flattenedDataArriendoCopy.filter(
        (item) => item.descripcion === descripcion
      );

      matchingItems.forEach((item, index) => {
        item.tramo = `Tramo ${index + 1}`;
        item.id = null;
      });

      flattenedDataArriendoCopy.sort((a, b) => {
        const nombreComparison = a.descripcion.localeCompare(b.descripcion);
        if (nombreComparison !== 0) {
          return nombreComparison;
        }
        return (
          moment(a.desde, dateFormats, true).toDate() -
          moment(b.desde, dateFormats, true).toDate()
        );
      });

      const formattedAllItems = flattenedDataArriendoCopy.map((item) =>
        formatDatesIfNeeded(item, false)
      );
      await Promise.all(formattedAllItems.map(postDataCotizacion));
    }

    if (indexToRemoveServicios !== -1) {
      const itemToRemove = serviciosArrayCopy[indexToRemoveServicios];
      await deactivateItem(itemToRemove);
      serviciosArrayCopy.splice(indexToRemoveServicios, 1);
      // Add new tramos for Gasto Comun in serviciosArrayCopy

      tramos.forEach((tramo, index) => {
        serviciosArrayCopy.push({
          ...gastoComunItem,
          desde: tramo.desde,
          hasta: tramo.hasta,
          tramo: `Tramo ${index + 1}`,
          id: null,
        });
      });

      serviciosArrayCopy.sort((a, b) => {
        const nombreComparison = a.descripcion.localeCompare(b.descripcion);
        if (nombreComparison !== 0) {
          return nombreComparison;
        }
        return (
          moment(a.desde, dateFormats, true).toDate() -
          moment(b.desde, dateFormats, true).toDate()
        );
      });
      const formattedAllItems = serviciosArrayCopy.map((item) =>
        formatDatesIfNeeded(item, false)
      );
      await Promise.all(formattedAllItems.map(postDataCotizacion));
    }
    setShowTramoModalArriendo(false);
    await getDetalleData();
    setLoading(false);
  };

  const handleTramoConfirmacionCuota = async (rowData) => {
    if (loading) return;
    setLoading(true);
    const serviciosUnicosCopy = [...serviciosUnicos];
    const itemBeforeRemove = serviciosUnicosCopy.find(
      (item) => item.descripcion === rowData.descripcion
    );
    const indexToRemove = serviciosUnicosCopy.findIndex(
      (item) => item.descripcion === rowData.descripcion
    );

    if (indexToRemove !== -1) {
      const itemToRemove = serviciosUnicosCopy[indexToRemove];
      await deactivateItem(itemToRemove);
      serviciosUnicosCopy.splice(indexToRemove, 1);

      // Calculate the number of months between desde and hasta
      const startDate = moment(rowData.desde, dateFormats, true).toDate();
      const endDate = moment(rowData.hasta, dateFormats, true).toDate();
      const tarifaCliente = rowData.tarifa_cliente;
      const totalDays = Math.ceil(
        (endDate - startDate) / (1000 * 60 * 60 * 24)
      );

      const monthsPerCuota = Math.ceil(maxAmountOfCuotas / cuotas);
      const daysPerCuota = Math.ceil(totalDays / cuotas);

      let currentStartDate = moment(startDate, dateFormats, true).toDate();

      for (let i = 0; i < cuotas; i++) {
        const newItem = { ...itemBeforeRemove };
        const daysToAdd =
          i === cuotas - 1 ? totalDays - daysPerCuota * i : daysPerCuota;

        newItem.desde = moment(currentStartDate, dateFormats, true);
        newItem.hasta = moment(currentStartDate, dateFormats, true).add(
          daysToAdd - 1,
          "days"
        );
        newItem.tarifa_cliente = (tarifaCliente / cuotas).toFixed(2);
        newItem.tramo = `Cuota ${i + 1}`;
        newItem.id = null;
        serviciosUnicosCopy.push(newItem);
        currentStartDate = moment(currentStartDate)
          .add(daysToAdd, "days")
          .toDate();
      }

      serviciosUnicosCopy.sort((a, b) => {
        const nombreComparison = a.descripcion.localeCompare(b.descripcion);
        if (nombreComparison !== 0) {
          return nombreComparison;
        }
        return (
          moment(a.desde, dateFormats, true).toDate() -
          moment(b.desde, dateFormats, true).toDate()
        );
      });

      const formattedAllItems = serviciosUnicosCopy.map((item) =>
        formatDatesIfNeeded(item, false)
      );
      await Promise.all(formattedAllItems.map(postDataCotizacion));
    }
    setShowTramoModalUnico(false);
    await getDetalleData();
    setLoading(false);
  };

  const clearTramosVariables = () => {
    setTramos([]);
    setTramoCounter(0);
  };
  const handleNewTramoArriendo = (rowData) => {
    clearTramosVariables();
    setNombreCuadro(rowData.descripcion);

    // Parse dates using moment
    const desdeDate = moment(rowData.desde, dateFormats, true).toDate();
    const hastaDate = moment(rowData.hasta, dateFormats, true).toDate();

    if (
      !moment(rowData.desde, dateFormats, true).isValid() ||
      !moment(rowData.hasta, dateFormats, true).isValid()
    ) {
      alert("Fechas no válidas");
      return;
    }

    const tramo1 = {
      ...rowData,
      desde: desdeDate,
      hasta: hastaDate,
    };
    const tramo2 = {
      ...rowData,
      desde: desdeDate,
      hasta: hastaDate,
    };

    // Adjust tramo1 and tramo2 dates using moment
    tramo1.desde = moment(tramo1.desde, dateFormats, true);
    tramo1.hasta = moment(tramo1.hasta, dateFormats, true);

    tramo2.desde = moment(tramo2.desde, dateFormats, true);
    tramo2.hasta = moment(tramo2.hasta, dateFormats, true);

    setHastaTramoGeneral(hastaDate);
    setTarifaTramo(rowData.tarifa_base);
    setTramos([tramo1, tramo2]);

    setShowTramoModalArriendo(true);
  };

  /**
   * @array es el array a filtrar
   * @rowData es el item que no queremos incluir
   * @return un array sin el item
   */
  const filterArray = (array, rowData) => {
    return array.filter((data) => data !== rowData);
  };
  function formatDateTramo(dateInput) {
    // Try to parse the input date with the possible formats
    const date = moment(dateInput, dateFormats, true);

    if (!date.isValid()) {
      alert("Fecha en formato inválido");
      return;
    }

    return date.format("YYYY-MM-DD");
  }
  const updateTramoIfNeeded = (array, rowData) => {
    const newArray = array.filter(
      (item) => item.descripcion === rowData.descripcion
    );

    if (newArray.length > 0) {
      const totalTarifaCliente = newArray
        .reduce((total, item) => total + parseFloat(item.tarifa_cliente), 0)
        .toFixed(2);
      const totalTarifaMensual = newArray
        .reduce((total, item) => total + parseFloat(item.tarifa_mensual), 0)
        .toFixed(2);
      const totalTarifaDiaria = newArray
        .reduce((total, item) => total + parseFloat(item.tarifa_diaria), 0)
        .toFixed(2);

      const updatedItem = {
        ...newArray[0],
        tramo: "",
        desde: formatDateTramo(desde),
        hasta: formatDateTramo(hasta),
        tarifa_cliente: totalTarifaCliente,
        tarifa_mensual: totalTarifaMensual,
        tarifa_diaria: totalTarifaDiaria,
      };

      const updatedArray = array.filter(
        (item) => item.descripcion !== rowData.descripcion
      );

      updatedArray.push(updatedItem);

      return updatedArray;
    }

    return array;
  };

  const handleBorrarLinea = async (itemABorrar, nombreArray) => {
    if (loading) return;
    setLoading(true);
    let arrayToMaintain;
    let hasTramo =
      itemABorrar.tramo !== "" &&
      itemABorrar.tramo !== null &&
      itemABorrar.tramo !== undefined &&
      itemABorrar.tramo !== "null";
    let itemsToDelete = [];
    if (hasTramo) {
      const confirmDelete = window.confirm(
        "El borrado de un item con tramos hará que todos los tramos se borren y vuelva a su estado original"
      );
      if (!confirmDelete) {
        setLoading(false);
        return; // If the user does not confirm, exit the function
      }
    }

    switch (nombreArray) {
      case "productos":
        // Create an array of items that don't have " - " in their description
        const itemsWithoutHyphen = flattenedDataArriendo.filter(
          (item) => !item.descripcion.includes(" - ")
        );

        if (
          itemsWithoutHyphen.length === 1 &&
          !itemABorrar.descripcion.includes(" - ")
        ) {
          alert("No puede dejar la cotización sin producto");
          setLoading(false);
          return;
        } else {
          const originalArray = [...flattenedDataArriendo];

          if (hasTramo) {
            arrayToMaintain = updateTramoIfNeeded(
              flattenedDataArriendo,
              itemABorrar
            );
          } else {
            arrayToMaintain = filterArray(flattenedDataArriendo, itemABorrar);
          }

          if (originalArray.length > 0) {
            // Track deleted items
            const deletedItems = originalArray.filter(
              (item) => !arrayToMaintain.includes(item)
            );
            deletedItems.forEach((item) => itemsToDelete.push(item));
          }

          // Ensure deletion of related items only if itemABorrar.descripcion is no longer in arrayToMaintain
          const itemSecondPart = itemABorrar.descripcion;
          const itemStillPresent = arrayToMaintain.some(
            (item) => item.descripcion === itemABorrar.descripcion
          );

          if (!itemStillPresent) {
            // Delete related items in servicios
            const relatedServicios = servicios.filter(
              (item) => item.descripcion.split(" - ")[1] === itemSecondPart
            );
            relatedServicios.forEach((item) => itemsToDelete.push(item));

            // Delete related items in serviciosUnicos
            const relatedServiciosUnicos = serviciosUnicos.filter(
              (item) => item.descripcion.split(" - ")[1] === itemSecondPart
            );
            relatedServiciosUnicos.forEach((item) => itemsToDelete.push(item));

            const relatedFlattenedDataArriendo = flattenedDataArriendo.filter(
              (item) => item.descripcion.split(" - ")[1] === itemSecondPart
            );

            relatedFlattenedDataArriendo.forEach((item) =>
              itemsToDelete.push(item)
            );

            // Remove items present in itemsToDelete from arrayToMaintain
            arrayToMaintain = arrayToMaintain.filter(
              (item) =>
                !itemsToDelete.some(
                  (deletedItem) => deletedItem.descripcion === item.descripcion
                )
            );
          } else {
            // Check for Gasto Comun items in servicios array
            const gastoComunItems = servicios.filter(
              (item) =>
                item.descripcion.includes("Gasto Comun") &&
                item.descripcion.split(" - ")[1] === itemABorrar.descripcion
            );

            if (gastoComunItems.length > 1 && hasTramo) {
              const updatedGastoComunItem = {
                ...gastoComunItems[0],
                tramo: "",
                desde: formatDateTramo(desde),
                hasta: formatDateTramo(hasta),
                id: null,
              };
              const updatedServicios = servicios.filter(
                (item) =>
                  !(
                    item.descripcion.includes("Gasto Comun") &&
                    item.descripcion.split(" - ")[1] === itemABorrar.descripcion
                  )
              );

              arrayToMaintain.push(updatedGastoComunItem);

              if (servicios.length > 0) {
                // Track deleted items from servicios
                const originalServicios = [...servicios];
                const deletedServiciosItems = originalServicios.filter(
                  (item) => !updatedServicios.includes(item)
                );
                deletedServiciosItems.forEach((item) =>
                  itemsToDelete.push(item)
                );
              }
            }
            if (gastoComunItems.length === 1 && !hasTramo) {
              const updatedServicios = servicios.filter(
                (item) =>
                  !(
                    item.descripcion.includes("Gasto Comun") &&
                    item.descripcion.split(" - ")[1] === itemABorrar.descripcion
                  )
              );
              if (servicios.length > 0) {
                // Track deleted items from servicios
                const originalServicios = [...servicios];
                const deletedServiciosItems = originalServicios.filter(
                  (item) => !updatedServicios.includes(item)
                );
                deletedServiciosItems.forEach((item) =>
                  itemsToDelete.push(item)
                );
              }
            }

            const newArray = arrayToMaintain.filter(
              (item) => item.tipo === "Adicional"
            );
            if (newArray.length < 1) {

              // Add items to itemsToDelete from serviciosUnicos
              const relatedObraCivilItems = serviciosUnicos.filter(
                (item) =>
                  item.descripcion.includes("Obra Civil") &&
                  item.descripcion.split(" - ")[1] ===
                    itemABorrar.descripcion.split(" - ")[1]
              );

              relatedObraCivilItems.forEach((item) => itemsToDelete.push(item));
            }
          }
        }
        break;
      case "servicios":
        const originalServicios = [...servicios];

        if (hasTramo) {
          arrayToMaintain = updateTramoIfNeeded(servicios, itemABorrar);
        } else {
          arrayToMaintain = filterArray(servicios, itemABorrar);
        }

        if (originalServicios.length > 0) {
          // Track deleted items from servicios
          const deletedItemsServicios = originalServicios.filter(
            (item) => !arrayToMaintain.includes(item)
          );
          deletedItemsServicios.forEach((item) => itemsToDelete.push(item));
        }

        const newArrayServicios = arrayToMaintain.filter(
          (item) => item.descripcion === itemABorrar.descripcion
        );

        if (1 > newArrayServicios.length) {
          if (itemABorrar.descripcion.includes("Internet")) {
            const relatedServicioUnico = serviciosUnicos.filter(
              (item) =>
                !item.descripcion.includes("Habilitacion Fibra") &&
                item.descripcion.split(" - ")[1] !==
                  itemABorrar.descripcion.split(" - ")[1]
            );

            if (relatedServicioUnico.length > 0) {
              if (serviciosUnicos.length > 0) {
                // Track deleted items from serviciosUnicos
                const originalServiciosUnicos = [...serviciosUnicos];
                const deletedServiciosUnicosItems =
                  originalServiciosUnicos.filter(
                    (item) => !relatedServicioUnico.includes(item)
                  );
                deletedServiciosUnicosItems.forEach((item) =>
                  itemsToDelete.push(item)
                );
              }
            }
          }
        }
        const relatedHabilitacionFibraItems = serviciosUnicos.filter(
          (item) =>
            item.descripcion.includes("Habilitacion Fibra") &&
            item.descripcion.split(" - ")[1] ===
              itemABorrar.descripcion.split(" - ")[1]
        );

        relatedHabilitacionFibraItems.forEach((item) =>
          itemsToDelete.push(item)
        );
        break;
      case "unicos":
        const originalUnicos = [...serviciosUnicos];

        if (hasTramo) {
          arrayToMaintain = updateTramoIfNeeded(serviciosUnicos, itemABorrar);
        } else {
          arrayToMaintain = filterArray(serviciosUnicos, itemABorrar);
        }

        if (originalUnicos.length > 0) {
          // Track deleted items from serviciosUnicos
          const deletedItemsUnicos = originalUnicos.filter(
            (item) => !arrayToMaintain.includes(item)
          );
          deletedItemsUnicos.forEach((item) => itemsToDelete.push(item));
        }

        break;
      default:
        break;
    }
    if (itemsToDelete.length > 0) {
      await Promise.all(itemsToDelete.map(deactivateItem));
      await Promise.all(arrayToMaintain.map(postDataCotizacion));
      await getDetalleData();
    }
    setLoading(false);
  };

  const selectFechaTramo = (index, newHasta) => {
    let updatedTramos = [...tramos];
    const currentTramo = updatedTramos[index];
    const oneDayInMillis = 24 * 60 * 60 * 1000;

    const newHastaMoment = moment(newHasta, dateFormats, true);
    const currentHastaMoment = moment(currentTramo.hasta, dateFormats, true);
    const currentDesdeMoment = moment(currentTramo.desde, dateFormats, true);
    const hastaTramoGeneralMoment = moment(
      hastaTramoGeneral,
      dateFormats,
      true
    );

    const isFirstTramoValid = () =>
      !newHastaMoment.isSame(hastaTramoGeneralMoment) &&
      !newHastaMoment.isSame(currentHastaMoment) &&
      newHastaMoment.isAfter(currentDesdeMoment);

    const isLastTramoValid = () =>
      tramoCounter === tramos.length - 1 &&
      newHastaMoment.isBefore(hastaTramoGeneralMoment);

    if (index === 0) {
      if (isFirstTramoValid()) {
        const secondTramo = updatedTramos[index + 1];
        currentTramo.hasta = newHastaMoment.toDate();
        secondTramo.desde = newHastaMoment.clone().add(1, "day").toDate();
        updatedTramos = [currentTramo, secondTramo];
        setTramos(updatedTramos);
        setTramoCounter((prevCounter) => prevCounter + 1);
      } else {
        alert("no es valido");
      }
    } else if (isLastTramoValid()) {
      currentTramo.hasta = newHastaMoment.toDate();
      const newTramo = {
        ...currentTramo,
        desde: newHastaMoment.clone().add(1, "day").toDate(),
        hasta: hastaTramoGeneralMoment.toDate(),
      };
      updatedTramos = updatedTramos.map((tramo, i) =>
        i === index ? currentTramo : tramo
      );
      updatedTramos.push(newTramo);
      setTramoCounter((prevCounter) => prevCounter + 1);
      setTramos(updatedTramos);
    } else {
      alert("aqui termina el ciclo");
    }
  };

  const matrixData = {};
  const [resumenMensual, setResumenMensual] = useState([]);

  function calculateMonthlyCharges(charges, isUniqueService = false) {
    const monthlyCharges = {};

    const convertToDateObject = (dateString) => {

      return moment(dateString, "YYYY-MM-DD", true);

    };

    charges.forEach((charge) => {
      const startDateUTC = moment(charge.desde, dateFormats, true)
        .startOf("day")
        .utc();
      const endDateUTC = moment(charge.hasta, dateFormats, true)
        .startOf("day")
        .utc();
      if (isUniqueService && (!charge.tramo || charge.tramo === "null")) {
        const startDateUTC = convertToDateObject(desde).startOf("day").utc();
        const endDateUTC = convertToDateObject(hasta).startOf("day").utc();

        const firstMonthKey = startDateUTC.format("YYYY-MM");

        const monthlyCharge = charge.tarifa_diaria
          ? parseFloat((charge.tarifa_diaria * 30).toFixed(2))
          : 0;

        if (!monthlyCharges[firstMonthKey]) {
          monthlyCharges[firstMonthKey] = 0;
        }
        monthlyCharges[firstMonthKey] += parseFloat(monthlyCharge.toFixed(2)); 
      } else if (isUniqueService && charge.tramo.includes("Cuota")) {
        const startDateUTC = convertToDateObject(desde).startOf("day").utc();
        const endDateUTC = convertToDateObject(hasta).startOf("day").utc();

        const relatedCharges = charges.filter(
          (c) => c.descripcion === charge.descripcion && c.tramo
        );

        relatedCharges.forEach((relatedCharge, index) => {
          const monthWithOffset = startDateUTC.clone().add(index, "month");

          if (monthWithOffset.isAfter(endDateUTC)) {
            return;
          }

          const monthKey = monthWithOffset.format("YYYY-MM");

          const monthlyCharge = relatedCharge.tarifa_diaria
            ? parseFloat((relatedCharge.tarifa_diaria * 30).toFixed(2))
            : 0;

          if (!monthlyCharges[monthKey]) {
            monthlyCharges[monthKey] = 0;
          }

          monthlyCharges[monthKey] += parseFloat(
            (monthlyCharge / relatedCharges.length).toFixed(2)
          );
        });
      } else {
        const startDateUTC = moment(charge.desde, dateFormats, true)
          .startOf("day")
          .utc();
        const endDateUTC = moment(charge.hasta, dateFormats, true)
          .startOf("day")
          .utc();
        const desdeDate = convertToDateObject(desde);
        const hastaDate = convertToDateObject(hasta);

        let currentDate = startDateUTC.clone();

        while (currentDate.isSameOrBefore(endDateUTC)) {
          const currentMonthKey = currentDate.format("YYYY-MM");
          const daysInMonth = currentDate.daysInMonth();

          let daysInPeriod = Math.min(
            daysInMonth - currentDate.date() + 1,
            Math.ceil(endDateUTC.diff(currentDate, "days", true))
          );

          const dailyCharge = charge.tarifa_diaria
            ? parseFloat(charge.tarifa_diaria.toFixed(2))
            : 0;

          if (currentDate.isSame(desdeDate, "day")) {
            if (daysInMonth === 31) {
              daysInPeriod = 30;
            } else {
              daysInPeriod = daysInMonth - startDateUTC.date() + 1;
            }
          } else if (currentDate.isSame(endDateUTC, "month")) {
            if (daysInMonth === 31) {
              daysInPeriod = 30;
            } else {
              daysInPeriod = endDateUTC.date();
            }
          } else {
            if (
              currentDate.month() === 1 &&
              (daysInPeriod === 28 || daysInPeriod === 29)
            ) {
              daysInPeriod = 30;
            } else if (daysInPeriod === 30 || daysInPeriod === 31) {
              daysInPeriod = 30;
            } else {
              daysInPeriod = daysInMonth - startDateUTC.date() + 1;
            }
          }

          const monthlyCharge = dailyCharge * daysInPeriod;

          if (!monthlyCharges[currentMonthKey]) {
            monthlyCharges[currentMonthKey] = 0;
          }

          monthlyCharges[currentMonthKey] += parseFloat(
            monthlyCharge.toFixed(2)
          );

          currentDate.add(1, "month").startOf("month");
        }
      }

    });
    
    return monthlyCharges;
  }
  const normalizeDate = (dateString) => {
    // List of possible date formats
    const formats = [
      "YYYY-MM",
      "YYYY/MM",
      "MM/YYYY",
      "MMM YYYY",
      "MMMM YYYY",
      "YYYY-MM-DD",
      "YYYY/MM/DD",
      "DD/MM/YYYY",
      "DD-MM-YYYY",
    ];
    const date = moment(dateString, formats, true);
    return date.isValid() ? date.format("YYYY-MM") : null;
  };
  const [verResumenMensual, setVerResumenMensual] = useState(false);
  const formatMonth = (dateString) => {
    const date = moment(dateString, "YYYY-MM");
    return date.format("MMMM");
  };
  
  const allData = Object.entries(resumenMensual).map(([date, value]) => {
    const normalizedDate = normalizeDate(date);
    if (!normalizedDate) {
      throw new Error(`Invalid date format: ${date}`);
    }
    return {
      id: normalizedDate,
      formattedMonth: formatMonth(normalizedDate),
      value: `${value.toLocaleString("en-US").replace(".", ",")} UF`,
    };
  });
  
  allData.forEach(({ id, value }) => {
    const [year, month] = id.split("-");
    const paddedMonth = month.padStart(2, "0"); // Ensure month is two digits
  
    if (!matrixData[year]) {
      matrixData[year] = {};
    }
  
    matrixData[year][paddedMonth] = value;
  });
  
  const months = Object.keys(
    allData.reduce((acc, { id }) => {
      const [, month] = id.split("-");
      acc[month] = true;
      return acc;
    }, {})
  );
  
  const sortedMonths = months.sort((a, b) => a - b);
  
  moment.locale("es");
  
  const columns = [
    { field: "year", header: "Año" },
    ...sortedMonths.map((month) => {
      const monthName = moment(month, "M")
        .locale("es") // Ensure locale is set for each formatting
        .format("MMMM")
        .replace(/^\w/, (c) => c.toUpperCase());
  
      return {
        field: month,
        header: monthName,
      };
    }),
  ];
  
  const handleShowResumenMensual = () => {
    const chargesArrays = [flattenedDataArriendo, servicios];
    const uniqueServiceCharges = serviciosUnicos;
    const combinedMonthlyCharges = {};
    chargesArrays.forEach((charges) => {
      const monthlyCharges = calculateMonthlyCharges(charges);

      Object.keys(monthlyCharges).forEach((key) => {
        if (!combinedMonthlyCharges[key]) {
          combinedMonthlyCharges[key] = 0;
        }
        combinedMonthlyCharges[key] += monthlyCharges[key];
      });
    });

    const monthlyUniqueCharges = calculateMonthlyCharges(
      uniqueServiceCharges,
      true
    );
    Object.keys(monthlyUniqueCharges).forEach((key) => {
      if (!combinedMonthlyCharges[key]) {
        combinedMonthlyCharges[key] = 0;
      }
      combinedMonthlyCharges[key] += monthlyUniqueCharges[key];
    });
    setResumenMensual(combinedMonthlyCharges);
    setVerResumenMensual(true);
  };
  
  const years = Object.keys(matrixData);
  
  // Transform matrixData into matrixArray
  const matrixArray = years.map((year) => {
    const yearData = { year: year }; // Include the year field
    sortedMonths.forEach((month) => {
      const monthField = String(month).padStart(2, "0"); // Ensure month is two digits
      yearData[monthField] = matrixData[year][month] || ""; // Provide default value if missing
    });
    return yearData;
  });

  const deactivateItem = async (item) => {
    const { id, id_cotizacion } = item;
    const queryParams = new URLSearchParams({
      id,
      idCotizacion: id_cotizacion,
    });

    const url = `${baseUrl}deactivateDetalleCotizacion?${queryParams}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      throw error;
    }
  };
  const handleInfinity = (value) => {
    return isFinite(value) ? value : 0;
  };
  const buildInsertSubproductoUrl = (
    idEmpresa,
    idProducto,
    idTipoSubproducto,
    metraje
  ) => {
    return `${baseUrl}insertSubProducto?idEmpresa=${idEmpresa}&idProducto=${idProducto}&idTipoSubproducto=${idTipoSubproducto}&metraje=${metraje}&incluido=${true}`;
  };
  const postDataCotizacion = async (item, isCerrar) => {
    const {
      id,
      id_cotizacion,
      id_empresa,
      relacion,
      tramo,
      descripcion,
      desde,
      hasta,
      cobro,
      tipo,
      contrato,
      unidad,
      cantidad,
      moneda,
      tarifa_base,
      tarifa_cliente,
      descuento,
      id_elemento,
    } = item;
    let url;

    const handleInfinity = (value) =>
      value === Infinity || value === -Infinity ? 0 : value;

    const buildURL = (baseUrl, params) => {
      return `${baseUrl}?${Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&")}`;
    };

    if (relacion === "subproducto" && isCerrar === true) {
      const [_, secondPart] = descripcion.split("-");
      const matchingItem = flattenedDataArriendo.find(
        (item) => item.descripcion === secondPart.trim()
      );

      if (matchingItem) {
        const insertSubproductoUrl = buildInsertSubproductoUrl(
          id_empresa,
          matchingItem.id_elemento,
          id_elemento,
          cantidad
        );
        try {
          const subproductoResponse = await fetch(insertSubproductoUrl, {
            method: "POST",
            redirect: "follow",
          });
          if (!subproductoResponse.ok) {
            throw new Error(
              `Error fetching data from ${insertSubproductoUrl}. Status: ${subproductoResponse.status}`
            );
          }
        } catch (error) {
          console.error(`Error fetching subproducto URL:`, error);
          throw error;
        }
      }
    }

    const params = {
      idCotizacion: id_cotizacion,
      relacion: relacion,
      idElemento: id_elemento,
      idEmpresa: id_empresa,
      tramo: tramo || null,
      descripcion: descripcion,
      desde,
      hasta,
      documentoCobro: cobro,
      tipo: tipo,
      contrato,
      unidadMedida: unidad || "",
      cantidad: cantidad || 0,
      moneda,
      tarifaBase: tarifa_base || 0,
      tarifaCliente: tarifa_cliente || 0,
      descuento: handleInfinity(descuento),
      activo: 1,
    };
    if (id === null) {
      url = buildURL(`${baseUrl}insertDetalleCotizacion`, params);
    } else {
      params.id = id;
      url = buildURL(`${baseUrl}updateDetalleCotizacion`, params);
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      return await response.json();
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      throw error;
    }
  };

  const updateCotizacion = async (
    idCotizacion,
    idCliente,
    idEstado,
    idCreador,
    desde,
    hasta,
    aviso
  ) => {
    try {
      const queryParams = new URLSearchParams({
        idCotizacion,
        idCliente,
        idEstado,
        idCreador,
        desde,
        hasta,
        aviso,
      });

      const url = `${baseUrl}updateCotizacion?${queryParams}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error updating cotizacion:`, error);
      throw error;
    }
  };

  function formatDate(dateString) {
    const parts = dateString.split("/");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      const formattedDay = day.padStart(2, "0");
      const formattedMonth = month.padStart(2, "0");
      return `${year}-${formattedMonth}-${formattedDay}`;
    }
    return dateString;
  }
  const guardarCotizacion = async () => {
    const allItems = flattenedDataArriendo.concat(servicios, serviciosUnicos);
    try {
      setLoading(true);

      const formattedAllItems = allItems.map((item) =>
        formatDatesIfNeeded(item, false)
      );
      const deactivatePromises = itemsPorEliminar.map((item) =>
        deactivateItem(item)
      );
      const postPromises = formattedAllItems.map((item) =>
        postDataCotizacion(item, false)
      );
      await Promise.all(deactivatePromises);

      await Promise.all(postPromises);

      const desdeTransformed = formatDate(desde);
      const hastaTransformed = formatDate(hasta);

      await updateCotizacion(
        idCotizacion,
        idCliente,
        2,
        sessionStorage.getItem("usuario_id"),
        desdeTransformed,
        hastaTransformed,
        0
      );

      alert("La cotización se ha guardado con éxito");
      window.location.reload(true);
      setLoading(false);
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }
  };

  const cerrarContrato = async () => {
    const allItems = flattenedDataArriendo.concat(servicios, serviciosUnicos);

    try {
      setLoading(true);

      const formattedAllItems = allItems.map((item) =>
        formatDatesIfNeeded(item, false)
      );
      const deactivatePromises = itemsPorEliminar.map((item) =>
        deactivateItem(item)
      );
      const postPromises = formattedAllItems.map((item) =>
        postDataCotizacion(item, true)
      );
      await Promise.all(deactivatePromises);

      await Promise.all(postPromises);
      const desdeTransformed = formatDate(desde);
      const hastaTransformed = formatDate(hasta);
      await updateCotizacion(
        idCotizacion,
        idCliente,
        5,
        sessionStorage.getItem("usuario_id"),
        desdeTransformed,
        hastaTransformed,
        diasAviso
      );

      alert("La cotización se ha guardado con éxito");
      setLoading(false);

      navigate("/asignacion");
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }
  };

  const cobroOptionsFlattened = [
    { label: "Factura Afecta", value: "Factura Afecta" },
    { label: "Factura Excenta", value: "Factura Excenta" },
    { label: "Nota de Cobro", value: "Nota de Cobro" },
  ];

  const cobroOptionsServicios = [
    { label: "Factura Afecta", value: "Factura Afecta" },
    { label: "Factura Excenta", value: "Factura Excenta" },
    { label: "Nota de Cobro", value: "Nota de Cobro" },
  ];
  const velocidadInternet = [
    { label: "10", value: "10" },
    { label: "20", value: "20" },
    { label: "30", value: "30" },
  ];

  const handleTablaChange = (rowData, newValue, tabla, campo) => {
    const updateData = (prevData) => {
      return prevData.map((item) => {
        if (
          item.descripcion === rowData.descripcion &&
          item.tramo === rowData.tramo &&
          item.id === rowData.id
        ) {
          let updatedItem = { ...item };

          if (campo === "descuento") {
            const newTarifa = item.tarifa_base * (1 - newValue / 100);
            updatedItem = {
              ...updatedItem,
              descuento: newValue,
              tarifa_cliente: newTarifa.toFixed(2),
            };
          } else if (campo === "tarifa_cliente") {
            const percentageDifference =
              ((newValue - item.tarifa_base) / item.tarifa_base) * 100;
            updatedItem = {
              ...updatedItem,
              tarifa_cliente: newValue,
              descuento: -1 * percentageDifference.toFixed(2),
            };
          } else if (
            campo === "cantidad" &&
            tabla === "servicios" &&
            rowData.descripcion.includes("Internet")
          ) {
            let cantidad = newValue;

            if (newValue > 200) {
              cantidad = 200;
            }

            const numberOfTenths = Math.floor(cantidad / 10);
            const additionalTarifaBase = numberOfTenths * item.tarifa_base;

            updatedItem = {
              ...updatedItem,
              cantidad: cantidad,
              tarifa_cliente: additionalTarifaBase,
            };
          } else {
            updatedItem = {
              ...updatedItem,
              [campo]: newValue,
            };
          }

          return updatedItem;
        }
        return item;
      });
    };

    if (tabla === "flattened") {
      setFlattenedDataArriendo((prevData) => updateData(prevData));
    } else if (tabla === "servicios") {
      setServicios((prevData) => updateData(prevData));
    } else if (tabla === "unicos") {
      setServiciosUnicos((prevData) => updateData(prevData));
    }
  };

  const handleTramoConfirmationServicio = async (descripcion) => {
    if (loading) return;
    setLoading(true);
    const serviciosInside = [...servicios];
    const indexToRemove = servicios.findIndex(
      (item) => item.descripcion === descripcion
    );

    if (indexToRemove !== -1) {
      const itemToRemove = serviciosInside[indexToRemove];
      await deactivateItem(itemToRemove);
      serviciosInside.splice(indexToRemove, 1);

      serviciosInside.push(...tramos);
      const matchingItems = serviciosInside.filter(
        (item) => item.descripcion === descripcion
      );

      matchingItems.forEach((item, index) => {
        item.tramo = `Tramo ${index + 1}`;
        item.id = null;
      });

      serviciosInside.sort((a, b) => {
        const nombreComparison = a.descripcion.localeCompare(b.descripcion);
        if (nombreComparison !== 0) {
          return nombreComparison;
        }

        return (
          moment(a.desde, dateFormats, true).toDate() -
          moment(b.desde, dateFormats, true).toDate()
        );
      });

      const formattedAllItems = serviciosInside.map((item) =>
        formatDatesIfNeeded(item, false)
      );
      await Promise.all(formattedAllItems.map(postDataCotizacion));
    }
    setShowTramoModalServicio(false);
    await getDetalleData();
    setLoading(false);
  };

  const [visible, setVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState(null);
  const [typeOfAdd, setTypeOfAdd] = useState("");
  useEffect(() => {
    if (flattenedDataArriendo.length > 0 && monedaOptionsFetched) {
      const updatedData = flattenedDataArriendo.map((item) => {
        const updatedItem = { ...item };
        calculateTarifaMensualWithMetraje(updatedItem, monedaOptions);
        return updatedItem;
      });

      if (!isEqual(updatedData, flattenedDataArriendo)) {
        setFlattenedDataArriendo(updatedData);
      }
    }
    const uniqueProducts = [];
    const descriptions = new Set();
    flattenedDataArriendo.forEach((item) => {
      if (item.relacion === "producto" && !descriptions.has(item.descripcion)) {
        uniqueProducts.push(item);
        descriptions.add(item.descripcion);
      }
    });
    setProducts(uniqueProducts);
  }, [flattenedDataArriendo, monedaOptionsFetched]);
  useEffect(() => {
    if (flattenedDataArriendo.length > 0) {
      const subtotalArriendo = calculateSubtotal(flattenedDataArriendo);
      setSubtotalArriendo(subtotalArriendo);
    }
  }, [flattenedDataArriendo]);
  useEffect(() => {
    if (servicios.length > 0 && monedaOptionsFetched) {
      const updatedData = servicios.map((item) => {
        const updatedItem = { ...item };
        calculateTarifaMensualServicios(
          updatedItem,
          monedaOptions,
          flattenedDataArriendo,
          subtotalArriendo
        );
        return updatedItem;
      });

      if (!isEqual(updatedData, servicios)) {
        setServicios(updatedData);
      }
    }
  }, [servicios, monedaOptionsFetched, subtotalArriendo]);
  useEffect(() => {
    if (servicios.length > 0) {
      const subtotalArriendo = calculateSubtotal(servicios);
      setSubtotalServicios(subtotalArriendo);
    }
  }, [servicios]);
  useEffect(() => {
    if (serviciosUnicos.length > 0 && monedaOptionsFetched) {
      const updatedData = serviciosUnicos.map((item) => {
        const updatedItem = { ...item };
        calculateTarifaMensualServiciosUnicos(updatedItem, monedaOptions);
        return updatedItem;
      });

      if (!isEqual(updatedData, serviciosUnicos)) {
        setServiciosUnicos(updatedData);
      }
    }
  }, [serviciosUnicos, monedaOptionsFetched]);

  function isEqual(objA, objB) {
    return JSON.stringify(objA) === JSON.stringify(objB);
  }

  const openDialog = (typeOfAdd) => {
    setTypeOfAdd(typeOfAdd);
    setSelectedProduct(null);
    setFilteredSubproductos([]);
    setFilteredServicios([]);

    setVisible(true);
  };

  const closeDialog = () => {
    setSelectedProduct(null);
    setFilteredSubproductos([]);
    setFilteredServicios([]);

    setVisible(false);
  };

  const handleProductChange = (e) => {
    setSelectedProduct(e.value);
    handleAddToProduct(e.value, typeOfAdd);
  };
  const handleAddToProduct = async (producto, typeOfAdd) => {
    await fetchAgregado(typeOfAdd, producto.id, producto.id_empresa);
  };
  const buildServiciosUrl = (idEmpresa) => {
    return `${baseUrl}getServiciosByIdEmpresa?idEmpresa=${idEmpresa}`;
  };

  const buildTiposSubproductosUrl = (idEmpresa) => {
    return `${baseUrl}getSubproductosByIdEmpresa?idEmpresa=${idEmpresa}`;
  };
  const [filteredServicios, setFilteredServicios] = useState([]);
  const [rawServicios, setRawServicios] = useState([]);
  const [selectedServicios, setSelectedServicios] = useState([]);
  const [filteredSubproductos, setFilteredSubproductos] = useState([]);
  const [selectedSubproductos, setSelectedSubproductos] = useState([]);
  const [subProductoAdicional, setSubProductoAdicional] = useState(null);
  const [metrajeSubproductoAdicional, setMetrajeSubproductoAdicional] =
    useState(0);
  const [showSubProductosAdicionales, setShowSubProductosAdicionales] =
    useState(false);
  const [seleccionSubProductoAdicional, setSeleccionSubproductoAdicional] =
    useState(false);
  const handleServicioSelectionChange = (e) => {
    setSelectedServicios(e.value);
  };
  const checkNewSubproductoVariables = () => {
    if (
      subProductoAdicional === null ||
      subProductoAdicional === undefined ||
      0 >= metrajeSubproductoAdicional
    ) {
      alert("Ingrese todos los campos para el nuevo subproducto");
      return false;
    } else {
      return true;
    }
  };

  const fetchAgregado = async (typeOfAdd, idProducto, idEmpresa) => {
    try {
      setLoading(true);

      let dataServicios = [];
      let dataSubproductos = [];
      let dataTipoSubproductos = [];

      const urlServicios = buildServiciosUrl(idEmpresa);
      dataServicios = await fetchData(urlServicios);

      if (Array.isArray(dataServicios.servicios)) {
        dataServicios = dataServicios.servicios;
      }
      setRawServicios(dataServicios);
      setFilteredServicios(
        dataServicios.filter((servicio) => servicio.incluido)
      );

      const urlTiposSubproducto = buildTiposSubproductosUrl(idEmpresa);

      const [tiposSubproductosResponse] = await Promise.all([
        fetchData(urlTiposSubproducto),
      ]);

      if (Array.isArray(tiposSubproductosResponse.subproductos)) {
        setFilteredSubproductos(tiposSubproductosResponse.subproductos);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false); // Make sure to stop the loading indicator in case of an error
    }
  };
  const handleAñadirAdicional = async (typeOfAdd) => {
    if (typeOfAdd === "subproducto") {
      if (!subProductoAdicional || !metrajeSubproductoAdicional) {
        alert("Necesita llenar todos los campos");
        return;
      }
    }
    const convertDateFormat = (dateStr) => {
      const [day, month, year] = dateStr.split("/");
      return `${year}-${month}-${day}`;
    };

    function formatDateTramo(dateInput) {
      let formattedDate = moment(dateInput, dateFormats, true);

      if (!formattedDate.isValid()) {
        // Handle invalid date case here if needed
        console.error("Invalid date format");
        return null; // or any default value or error handling mechanism you prefer
      }

      return formattedDate.format("YYYY-MM-DD");
    }
    const createNewSubproducto = () => {
      return {
        ...subProductoAdicional,
        descripcion: `${subProductoAdicional.descripcion} - ${selectedProduct.descripcion}`,
        tarifa_cliente: subProductoAdicional.tarifa_base,
        relacion: "subproducto",
        desde: formatDateTramo(desde),
        hasta: formatDateTramo(hasta),
        descuento: 0,
        tramo: null,
        tipo: "Adicional",
        cantidad: metrajeSubproductoAdicional,
        cobro: "Factura Afecta",
        contrato: true,
        id_elemento: subProductoAdicional.id,
        id: null,
        id_cotizacion: idCotizacion,
        metraje: metrajeSubproductoAdicional,
      };
    };

    const updateServiceDetails = (service) => {
      return {
        ...service,
        relacion: "servicio",
        cobro: "Factura Afecta",
        desde: formatDateTramo(desde),
        hasta: formatDateTramo(hasta),
        descuento: 0,
        tramo: null,
        tipo: "Adicional",
        contrato: true,
        id_elemento: service.id,
        id: null,
        id_cotizacion: idCotizacion,
      };
    };

    const handleObraCivil = (servicesList) => {
      const obraCivil = servicesList.find(
        (service) => service.descripcion === "Obra Civil"
      );
      if (obraCivil) {
        obraCivil.relacion = "servicio";
        obraCivil.tarifa_base = 0;
        obraCivil.tarifa_cliente = 0;
        obraCivil.tipo = "Adicional";
        obraCivil.desde = formatDateTramo(desde);
        obraCivil.hasta = formatDateTramo(hasta);
        obraCivil.descripcion = `Obra Civil - ${selectedProduct.descripcion}`;

        return obraCivil;
      }
      return null;
    };

    const handleHabilitacionFibra = (servicesList) => {
      const habilitacionFibra = servicesList.find(
        (service) => service.descripcion === "Habilitacion Fibra"
      );
      if (habilitacionFibra) {
        habilitacionFibra.relacion = "servicio";
        habilitacionFibra.tarifa_base = 0;
        habilitacionFibra.tarifa_cliente = 0;
        habilitacionFibra.descripcion = `Habilitacion Fibra - ${selectedProduct.descripcion}`;
        habilitacionFibra.desde = formatDateTramo(desde);
        habilitacionFibra.hasta = formatDateTramo(hasta);
        return habilitacionFibra;
      }
      return null;
    };

    if (typeOfAdd === "subproducto") {
      const newSubproducto = createNewSubproducto();

      const duplicateSubproducto = flattenedDataArriendo.find(
        (item) => item.descripcion === newSubproducto.descripcion
      );

      if (duplicateSubproducto) {
        alert("El producto ya tiene este subproducto asociado");
        setLoading(false);

        return;
      }

      const obraCivil =
        handleObraCivil(serviciosUnicos) || handleObraCivil(rawServicios);

      if (
        obraCivil &&
        !serviciosUnicos.some(
          (item) => item.descripcion === obraCivil.descripcion
        )
      ) {
        const newObra = updateServiceDetails(obraCivil);
        serviciosUnicos.push(newObra);
      }

      await Promise.all([postDataCotizacion({ ...newSubproducto })]);
      await Promise.all(serviciosUnicos.map(postDataCotizacion));
      await getDetalleData();
      // setFlattenedDataArriendo([...flattenedDataArriendo, newSubproducto]);
      // setServiciosUnicos([...updatedServiciosUnicos]);
    } else {
      const updatedServicios = selectedServicios.map((servicio) => ({
        ...servicio,
        descripcion: `${servicio.descripcion} - ${selectedProduct.descripcion}`,
        tarifa_cliente: servicio.tarifa_base,
        relacion: "servicio",
        desde: formatDateTramo(desde),
        hasta: formatDateTramo(hasta),
        descuento: 0,
        tramo: null,
        tipo: "Adicional",
        cantidad: 0,
        cobro: "Factura Afecta",
        contrato: true,
        id_elemento: servicio.id,
        id: null,
        id_cotizacion: idCotizacion,
      }));

      const duplicateServicios = updatedServicios.filter((updatedServicio) =>
        servicios.some(
          (servicio) => servicio.descripcion === updatedServicio.descripcion
        )
      );

      if (duplicateServicios.length > 0) {
        const duplicateDescriptions = duplicateServicios
          .map((servicio) => servicio.descripcion)
          .join(", ");
        alert(
          `El producto ya tiene estos servicios asociados: ${duplicateDescriptions}`
        );
        return;
      }

      const hasInternet = updatedServicios.some((servicio) =>
        servicio.descripcion.includes("Internet")
      );
      if (hasInternet) {
        const habilitacionFibra =
          handleHabilitacionFibra(serviciosUnicos) ||
          handleHabilitacionFibra(rawServicios);

        if (
          habilitacionFibra &&
          !serviciosUnicos.some(
            (item) => item.descripcion === habilitacionFibra.descripcion
          )
        ) {
          const newHabilitacion = updateServiceDetails(habilitacionFibra);
          serviciosUnicos.push(newHabilitacion);
        }
      }

      await Promise.all(updatedServicios.map(postDataCotizacion));
      await Promise.all(serviciosUnicos.map(postDataCotizacion));
      await getDetalleData();
      // setServicios([...servicios, ...updatedServicios]);
      // setServiciosUnicos([...updatedServiciosUnicos]);
    }
    closeDialog();
  };
  return (
    <div className="card">
      <>
        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-3"></div>
        </div>
        {carro.length > 0 && (
          <h5>
            Cuadro de pago de Cotizacion # {idCotizacion} para{" "}
            {carro
              .filter((item) => item.relacion === "producto")
              .map((item, index) => {
                if (index < carro.length - 1) {
                  return `${item.descripcion} | `;
                } else if (carro.length === 1) {
                  return item.descripcion;
                } else {
                  return `${item.descripcion}`;
                }
              })}
          </h5>
        )}

        <h7 style={{ marginTop: "5px", marginBottom: "25px" }}>
          ¿Cuánto pagaré por la asignación?
        </h7>
        <div className="p-fluid formgrid grid ">
          <div className="col-12 md:col-2">
            <Button
              label="Agregar Subproducto"
              icon="pi pi-plus"
              className="p-button-success mt-4 mb-4"
              onClick={() => openDialog("subproducto")}
              style={{ width: "100%" }}
              disabled={loading || cotizacion?.estado.estado === "ASIGNACIÓN"}
            />
          </div>
          <div className="col-12 md:col-2">
            <Button
              label={`Agregar Servicio`}
              icon="pi pi-plus"
              className="p-button-success mt-4 mb-4"
              onClick={() => openDialog("servicio")}
              style={{ width: "100%" }}
              disabled={loading || cotizacion?.estado.estado === "ASIGNACIÓN"}
            ></Button>
          </div>
          <div className="col-12 md:col-2">
            <Button
              label="Enviar a Cliente"
              icon="pi pi-send"
              className=" mt-4 mb-4 "
              onClick={() => {}}
              style={{}}
              disabled
            ></Button>
          </div>
          <div className="col-12 md:col-2">
            <Button
              label="Ver Resumen Mensual"
              icon="pi pi-search"
              onClick={() => handleShowResumenMensual()}
              className=" mt-4 mb-4 "
              disabled={loading}
            />
          </div>
          <div className="col-12 md:col-2">
            <Button
              label="Guardar Cotización"
              icon="pi pi-save"
              className="p-button mt-4 mb-4"
              onClick={guardarCotizacion}
              style={{ width: "100%" }}
              disabled={loading || cotizacion?.estado.estado === "ASIGNACIÓN"}
            ></Button>
          </div>
          <div className="col-12 md:col-2">
            <Button
              label={`Cerrar Contrato`}
              icon="pi pi-dollar"
              className="p-button-success mt-4 mb-4"
              onClick={() => setShowCerrarContrato(true)}
              style={{ width: "100%" }}
              disabled={loading || cotizacion?.estado.estado === "ASIGNACIÓN"}
            ></Button>
          </div>{" "}
          <Dialog
            visible={showCerrarContrato}
            onHide={() => setShowCerrarContrato(false)}
            modal
          >
            <div className="p-fluid formgrid grid">
              <div className="col-12 md:col-12" style={{ textAlign: "center" }}>
                <h2>Cerrando Cotización</h2>
                <h7 style={{ marginTop: "5px" }}>
                  ¿Cuántos dias previos al término del contrato se deberá dar
                  aviso?
                </h7>
                <div style={{ marginTop: "10px" }}>
                  <label htmlFor="daysSelect">
                    Seleccione la cantidad de días:
                  </label>
                  <Dropdown
                    id="daysDropdown"
                    value={diasAviso}
                    options={cantidadDiasAvisoContrato}
                    onChange={(e) => setDiasAviso(e.value)}
                    optionLabel="label"
                    placeholder="Seleccionar"
                  />
                </div>
              </div>
              <div className="col-12 md:col-12" style={{ textAlign: "center" }}>
                <Button
                  label="Cerrar Contrato"
                  icon="pi pi-dollar"
                  className="mr-2 mb-2 mt-4 p-button-success"
                  onClick={cerrarContrato}
                  style={{ width: "400px" }}
                ></Button>{" "}
              </div>
            </div>
          </Dialog>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <h5>TARIFA MENSUAL (OPEX)</h5>
        </div>
        <DataTable
          value={
            Array.isArray(flattenedDataArriendo) ? flattenedDataArriendo : []
          }
          className="p-datatable-gridlines mt-2 mb-4"
          showGridlines
          rows={10}
          header={`Arriendo`}
          emptyMessage="No existen items de arriendo"
          loading={loading}
        >
          <Column field="descripcion" header="Nombre" />
          <Column
            field="tramo"
            header="Tramo"
            body={(rowData) => (rowData.tramo === "null" ? "" : rowData.tramo)}
          />
          <Column
            header="Fecha Desde"
            body={(rowData) => {
              const date = moment(rowData.desde, dateFormats, true).toDate();
              const dateWithOffset = moment(date, dateFormats, true)
                .add(date.getTimezoneOffset(), "minutes")
                .toDate(); // Adjust for time zone offset
              return dateWithOffset instanceof Date && !isNaN(dateWithOffset)
                ? new Intl.DateTimeFormat("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }).format(dateWithOffset)
                : "Invalid Date";
            }}
          />
          <Column
            header="Fecha Hasta"
            body={(rowData) => {
              const date = moment(rowData.hasta, dateFormats, true).toDate();
              const dateWithOffset = moment(date, dateFormats, true)
                .add(date.getTimezoneOffset(), "minutes")
                .toDate(); // Adjust for time zone offset
              return dateWithOffset instanceof Date && !isNaN(dateWithOffset)
                ? new Intl.DateTimeFormat("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }).format(dateWithOffset)
                : "Invalid Date";
            }}
          />

          <Column
            header="Doc. Cobro"
            body={(rowData) => (
              <Dropdown
                value={rowData.cobro}
                options={cobroOptionsFlattened}
                onChange={(e) =>
                  handleTablaChange(rowData, e.value, "flattened", "cobro")
                }
                optionLabel="label"
                optionValue="value"
                placeholder={rowData.cobro}
                disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
              />
            )}
          />
          <Column
            header="Tipo"
            body={(rowData) =>
              rowData.tipo === "Incluido" ? "Incluido" : "Adicional"
            }
          />
          <Column
            field="contrato"
            header="Contrato"
            body={(rowData) => (
              <Dropdown
                value={rowData.contrato ? "Sí" : "No"}
                options={[
                  { label: "Sí", value: true },
                  { label: "No", value: false },
                ]}
                onChange={(e) =>
                  handleTablaChange(rowData, e.value, "flattened", "contrato")
                }
                placeholder={rowData.contrato ? "Sí" : "No"}
                disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
              />
            )}
          />
          <Column header="Unidad Medida" field="unidad" />
          <Column header="Cantidad" field="cantidad" />
          <Column
            header="Tarifa Base"
            field="tarifa_base"
            body={(rowData) => (
              <span>
                {rowData?.tarifa_base?.toString().replace(".", ",")} UF
              </span>
            )}
          />
          <Column
            header="Moneda"
            body={(rowData) => {
              const filteredOptions = monedaOptions.filter(
                (item) => item.idEmpresa === rowData.id_empresa
              );

              return (
                <Dropdown
                  value={rowData.moneda}
                  options={filteredOptions}
                  onChange={(e) =>
                    handleTablaChange(rowData, e.value, "flattened", "moneda")
                  }
                  optionLabel="codigo"
                  optionValue="codigo"
                  placeholder={rowData.moneda}
                  disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
                />
              );
            }}
          />

          <Column
            header="Tarifa Cliente"
            body={(rowData) => (
              <InputNumber
                value={rowData.tarifa_cliente}
                onChange={(e) =>
                  handleTablaChange(
                    rowData,
                    e.value,
                    "flattened",
                    "tarifa_cliente"
                  )
                }
                locale="es-CL"
                mode="decimal"
                maxFractionDigits={2}
                disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
              />
            )}
          />
          <Column
            header="Descuento Tarifa %"
            field="descuento"
            body={(rowData) => (
              <>
                {rowData.moneda === "UF" ? (
                  <>
                    <InputNumber
                      value={
                        isFinite(rowData.descuento) ? rowData.descuento : 0
                      }
                      onChange={(e) =>
                        handleTablaChange(
                          rowData,
                          e.value,
                          "flattened",
                          "descuento"
                        )
                      }
                      locale="es-CL"
                      mode="decimal"
                      maxFractionDigits={2}
                      disabled={
                        rowData.tarifa_base === 0 ||
                        cotizacion?.estado.estado === "ASIGNACIÓN"
                      }
                    />
                  </>
                ) : (
                  <>
                    <InputNumber
                      value={0}
                      onChange={(e) =>
                        handleTablaChange(
                          rowData,
                          e.value,
                          "flattened",
                          "descuento"
                        )
                      }
                      locale="es-CL"
                      mode="decimal"
                      maxFractionDigits={2}
                      disabled
                    />
                  </>
                )}
              </>
            )}
          />

          <Column
            header="Tarifa Diaria"
            body={(rowData) =>
              rowData.tarifa_diaria === "Tarifa Variable"
                ? rowData.tarifa_diaria
                : rowData.tarifa_diaria
                ? parseFloat(rowData.tarifa_diaria).toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }) + " UF"
                : ""
            }
          />
          <Column
            header="Tarifa Mensual"
            body={(rowData) =>
              rowData.tarifa_mensual === "Tarifa Variable"
                ? rowData.tarifa_mensual
                : rowData.tarifa_mensual
                ? parseFloat(rowData.tarifa_mensual).toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }) + " UF"
                : ""
            }
          />
          {cotizacion?.estado.estado !== "ASIGNACIÓN" && (
            <Column
              header="Acción"
              style={{ minWidth: "160px" }}
              body={(rowData) => (
                <>
                  <div>
                    <Button
                      icon="pi pi-plus"
                      className="p-button-rounded p-button-success p-button mr-2"
                      label=""
                      onClick={() => handleNewTramoArriendo(rowData)}
                      disabled={
                        rowData?.tramo && rowData?.tramo.includes("Tramo")
                      }
                      outlined
                    ></Button>
                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded p-button-danger p-button"
                      label=""
                      onClick={() => handleBorrarLinea(rowData, "productos")}
                      outlined
                      disabled={loading}
                    ></Button>
                  </div>

                  <Dialog
                    visible={showTramoModalArriendo}
                    onHide={() => {
                      setShowTramoModalArriendo(false);
                    }}
                    style={{ width: "1200px" }}
                    modal
                    dismissableMask={false}
                  >
                    <div className="flex align-items-center justify-content-center">
                      <div className="grid">
                        <div className="col-12">
                          <h5>
                            Seleccione tramos a generar para {nombreCuadro}
                          </h5>
                          <div>
                            {tramos.map((tramo, index) => (
                              <div
                                key={index}
                                className="p-fluid formgrid grid"
                              >
                                <div className="field col-12 md:col-4">
                                  <label htmlFor={`descripcionSubP_${index}`}>
                                    Tramo {index + 1}
                                  </label>
                                  <InputText
                                    id={`descripcionSubP_${index}`}
                                    value={tramo.descripcion}
                                    disabled
                                    onChange={(e) => setNombreCuadro(e.value)}
                                  />
                                </div>
                                <div className="field col-12 md:col-4">
                                  <label htmlFor={`desde_${index}`}>
                                    Desde
                                  </label>
                                  <Calendar
                                    id={`desde_${index}`}
                                    showIcon
                                    value={moment(
                                      tramo.desde,
                                      dateFormats,
                                      true
                                    ).toDate()}
                                    onChange={(e) => {}}
                                    minDate={
                                      moment(
                                        tramo.desde,
                                        dateFormats,
                                        true
                                      ).isValid()
                                        ? moment(tramo.desde, dateFormats, true)
                                            .add(1, "days")
                                            .toDate()
                                        : new Date()
                                    }
                                    maxDate={moment(
                                      tramo.hasta,
                                      dateFormats,
                                      true
                                    ).toDate()}
                                    dateFormat="dd/mm/yy"
                                    disabled={true}
                                  />
                                </div>
                                <div className="field col-12 md:col-4">
                                  <label htmlFor={`hasta_${index}`}>
                                    Hasta
                                  </label>
                                  <Calendar
                                    id={`hasta_${index}`}
                                    showIcon
                                    value={moment(
                                      tramo.hasta,
                                      dateFormats,
                                      true
                                    ).toDate()}
                                    onChange={(e) => {
                                      selectFechaTramo(
                                        index,
                                        moment(
                                          e.value,
                                          dateFormats,
                                          true
                                        ).toDate()
                                      );
                                    }}
                                    minDate={
                                      moment(
                                        tramo.desde,
                                        dateFormats,
                                        true
                                      ).isValid()
                                        ? moment(tramo.desde, dateFormats, true)
                                            .add(1, "days")
                                            .toDate()
                                        : new Date()
                                    }
                                    maxDate={moment(
                                      tramo.hasta,
                                      dateFormats,
                                      true
                                    ).toDate()}
                                    dateFormat="dd/mm/yy"
                                    disabled={index !== tramoCounter}
                                  />
                                </div>
                              </div>
                            ))}
                            <div className="field col-12 md:col-12">
                              <Button
                                icon="pi pi-check"
                                label={`Confirmar`}
                                className="p-button-rounded p-button-success p-button  mb-2 mt-2"
                                onClick={() =>
                                  handleTramoConfirmationArriendo(nombreCuadro)
                                }
                                disabled={loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </>
              )}
            />
          )}
        </DataTable>

        <div
          className="grid ml-2 mr-2 mb-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="pink-color">
            <strong>Arriendo Promedio Mensual:</strong>{" "}
            {subtotalArriendo.toLocaleString("en-US").replace(".", ",")} UF
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              width: "20px",
              height: "10px",
              marginRight: "5px",
              backgroundColor: "yellow",
              border: "1px solid #ccc",
            }}
          ></div>
          <div>: Tarifa Variable</div>
        </div>
        <DataTable
          value={servicios}
          className="p-datatable-gridlines"
          showGridlines
          rows={10}
          header={`Servicios`}
          emptyMessage="No seleccionó servicios"
          loading={loading}
        >
          <Column
            header="Nombre"
            body={(rowData) => (
              <div
                style={{
                  backgroundColor: rowData.remarcador ? "yellow" : "inherit",
                }}
              >
                {rowData.descripcion}
              </div>
            )}
          />
          <Column
            field="tramo"
            header="Tramo"
            body={(rowData) => (rowData.tramo === "null" ? "" : rowData.tramo)}
          />
          <Column
            header="Fecha Desde"
            body={(rowData) => {
              const date = moment(rowData.desde);
              return date.isValid()
                ? date.format("DD/MM/YYYY")
                : "Invalid Date";
            }}
          />
          <Column
            header="Fecha Hasta"
            body={(rowData) => {
              const date = moment(rowData.hasta, dateFormats, true);
              return date.isValid()
                ? date.format("DD/MM/YYYY")
                : "Invalid Date";
            }}
          />

          <Column
            header="Doc. Cobro"
            body={(rowData) => (
              <Dropdown
                value={rowData.cobro}
                options={cobroOptionsServicios}
                onChange={(e) =>
                  handleTablaChange(rowData, e.value, "servicios", "cobro")
                }
                optionLabel="label"
                optionValue="value"
                placeholder={rowData.cobro}
                disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
              />
            )}
          />
          <Column
            field="incluido"
            header="Tipo"
            body={(rowData) =>
              rowData.tipo === "Incluido" ? "Incluido" : "Adicional"
            }
          />
          <Column
            field="contrato"
            header="Contrato"
            body={(rowData) => (
              <Dropdown
                value={rowData.contrato ? "Sí" : "No"}
                options={[
                  { label: "Sí", value: true },
                  { label: "No", value: false },
                ]}
                onChange={(e) =>
                  handleTablaChange(rowData, e.value, "servicios", "contrato")
                }
                placeholder={rowData.contrato ? "Sí" : "No"}
                disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
              />
            )}
          />
          <Column
            header="Cantidad"
            body={(rowData) => (
              <InputNumber
                value={rowData.cantidad}
                onChange={(e) =>
                  handleTablaChange(rowData, e.value, "servicios", "cantidad")
                }
                locale="es-CL"
                maxFractionDigits={0}
                placeholder={rowData.cantidad}
                disabled={
                  !rowData.descripcion.includes("Internet") ||
                  cotizacion?.estado.estado === "ASIGNACIÓN"
                }
              />
            )}
          />
          <Column header="Unidad Medida" field="unidad" />
          <Column
            header="Moneda"
            body={(rowData) => {
              const filteredOptions = monedaOptions.filter(
                (item) => item.idEmpresa === rowData.id_empresa
              );

              return (
                <Dropdown
                  value={rowData.moneda}
                  options={filteredOptions}
                  onChange={(e) =>
                    handleTablaChange(rowData, e.value, "servicios", "moneda")
                  }
                  optionLabel="codigo"
                  optionValue="codigo"
                  placeholder={rowData.moneda}
                  disabled={
                    rowData.descripcion.includes("Gasto Comun") ||
                    cotizacion?.estado.estado === "ASIGNACIÓN"
                  }
                />
              );
            }}
          />
          <Column
            header="Tarifa Base"
            field="tarifa_base"
            body={(rowData) => (
              <span>{rowData.tarifa_base.toString().replace(".", ",")} UF</span>
            )}
          />

          <Column
            header="Tarifa Cliente"
            body={(rowData) => (
              <React.Fragment>
                <InputNumber
                  value={rowData.tarifa_cliente}
                  onChange={(e) =>
                    handleTablaChange(
                      rowData,
                      e.value,
                      "servicios",
                      "tarifa_cliente"
                    )
                  }
                  locale="es-CL"
                  mode="decimal"
                  maxFractionDigits={2}
                  disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
                />
              </React.Fragment>
            )}
          />
          <Column
            header="Descuento Tarifa %"
            field="descuento"
            body={(rowData) => (
              <InputNumber
                value={isFinite(rowData.descuento) ? rowData.descuento : 0}
                onChange={(e) =>
                  handleTablaChange(rowData, e.value, "servicios", "descuento")
                }
                locale="es-CL"
                mode="decimal"
                maxFractionDigits={2}
                disabled={
                  rowData.tarifa_base === 0 ||
                  cotizacion?.estado.estado === "ASIGNACIÓN"
                }
              />
            )}
          />
          <Column
            header="Tarifa Diaria"
            body={(rowData) =>
              rowData.tarifa_diaria === "Tarifa Variable"
                ? rowData.tarifa_diaria
                : rowData.tarifa_diaria
                ? parseFloat(rowData.tarifa_diaria).toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }) + " UF"
                : ""
            }
          />
          <Column
            header="Tarifa Mensual"
            body={(rowData) =>
              rowData.tarifa_mensual === "Tarifa Variable"
                ? rowData.tarifa_mensual
                : rowData.tarifa_mensual
                ? parseFloat(rowData.tarifa_mensual).toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }) + " UF"
                : ""
            }
          />
          {cotizacion?.estado.estado !== "ASIGNACIÓN" && (
            <Column
              header="Acción"
              style={{ minWidth: "120px" }}
              body={(rowData) => (
                <>
                  <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-success p-button mr-2"
                    label=""
                    onClick={() => handleNewTramoServicio(rowData)}
                    disabled={rowData.tramo && rowData.tramo.includes("Tramo")}
                    outlined
                  ></Button>
                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button ml-2"
                    label=""
                    onClick={() => handleBorrarLinea(rowData, "servicios")}
                    outlined
                    disabled={loading}
                  ></Button>
                  <Dialog
                    visible={showTramoModalServicio}
                    onHide={() => {
                      setShowTramoModalServicio(false);
                    }}
                    style={{ width: "1200px" }}
                    modal
                    dismissableMask={false}
                  >
                    <div className="flex align-items-center justify-content-center">
                      <div className="grid">
                        <div className="col-12">
                          <h5>
                            Seleccione tramos a generar para {nombreCuadro}
                          </h5>
                          <div>
                            {tramos.map((tramo, index) => (
                              <div
                                key={index}
                                className="p-fluid formgrid grid"
                              >
                                <div className="field col-12 md:col-4">
                                  <label htmlFor={`descripcionSubP_${index}`}>
                                    Tramo {index + 1}
                                  </label>
                                  <InputText
                                    id={`descripcionSubP_${index}`}
                                    value={tramo.descripcion}
                                    disabled
                                    onChange={(e) => setNombreCuadro(e.value)}
                                  />
                                </div>
                                <div className="field col-12 md:col-4">
                                  <label htmlFor={`desde_${index}`}>
                                    Desde
                                  </label>
                                  <Calendar
                                    id={`desde_${index}`}
                                    showIcon
                                    value={moment(
                                      tramo.desde,
                                      dateFormats,
                                      true
                                    ).toDate()}
                                    onChange={(e) => {}}
                                    minDate={
                                      moment(
                                        tramo.desde,
                                        dateFormats,
                                        true
                                      ).isValid()
                                        ? moment(tramo.desde, dateFormats, true)
                                            .add(1, "days")
                                            .toDate()
                                        : new Date()
                                    }
                                    maxDate={moment(
                                      tramo.hasta,
                                      dateFormats,
                                      true
                                    ).toDate()}
                                    dateFormat="dd/mm/yy"
                                    disabled={true}
                                  />
                                </div>
                                <div className="field col-12 md:col-4">
                                  <label htmlFor={`hasta_${index}`}>
                                    Hasta
                                  </label>
                                  <Calendar
                                    id={`hasta_${index}`}
                                    showIcon
                                    value={moment(
                                      tramo.hasta,
                                      dateFormats,
                                      true
                                    ).toDate()}
                                    onChange={(e) => {
                                      selectFechaTramo(
                                        index,
                                        moment(e.value).toDate()
                                      );
                                    }}
                                    minDate={
                                      moment(
                                        tramo.desde,
                                        dateFormats,
                                        true
                                      ).isValid()
                                        ? moment(tramo.desde, dateFormats, true)
                                            .add(1, "days")
                                            .toDate()
                                        : new Date()
                                    }
                                    maxDate={moment(
                                      tramo.hasta,
                                      dateFormats,
                                      true
                                    ).toDate()}
                                    dateFormat="dd/mm/yy"
                                    disabled={index !== tramoCounter}
                                  />
                                </div>
                              </div>
                            ))}
                            <div className="field col-12 md:col-12">
                              <Button
                                icon="pi pi-check"
                                label={`Confirmar`}
                                className="p-button-rounded p-button-success p-button  mb-2 mt-2"
                                onClick={() =>
                                  handleTramoConfirmationServicio(nombreCuadro)
                                }
                                disabled={loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </>
              )}
            />
          )}
        </DataTable>
        <div
          className="grid ml-2 mr-2 mb-4 mt-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="pink-color">
            <strong>Servicios Promedio Mensual:</strong>{" "}
            {subtotalServicios.toLocaleString("en-US").replace(".", ",")} UF
          </div>
        </div>
        {serviciosUnicos.length > 0 && (
          <>
            <div
              className="mt-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h5>TARIFA UNICA (CAPEX)</h5>
            </div>
            <DataTable
              value={serviciosUnicos}
              className="p-datatable-gridlines"
              showGridlines
              rows={10}
              loading={loading}
            >
              <Column field="descripcion" header="Nombre" />
              <Column
                field="tramo"
                header="Cuota"
                body={(rowData) =>
                  rowData.tramo === "null" ? "" : rowData.tramo
                }
              />

              <Column
                header="Doc. Cobro"
                body={(rowData) => (
                  <Dropdown
                    value={rowData.cobro}
                    options={cobroOptionsServicios}
                    onChange={(e) =>
                      handleTablaChange(rowData, e.value, "unicos", "cobro")
                    }
                    optionLabel="label"
                    optionValue="value"
                    placeholder={rowData.cobro}
                    disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
                  />
                )}
              />

              <Column
                header="Moneda"
                body={(rowData) => {
                  const filteredOptions = monedaOptions.filter(
                    (item) => item.idEmpresa === rowData.id_empresa
                  );

                  return (
                    <Dropdown
                      value={rowData.moneda}
                      options={filteredOptions}
                      onChange={(e) =>
                        handleTablaChange(rowData, e.value, "unicos", "moneda")
                      }
                      optionLabel="codigo"
                      optionValue="codigo"
                      placeholder={rowData.moneda}
                      disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
                    />
                  );
                }}
              />
              <Column
                header="Tarifa Cliente"
                body={(rowData) => (
                  <InputNumber
                    value={rowData.tarifa_cliente}
                    onChange={(e) =>
                      handleTablaChange(
                        rowData,
                        e.value,
                        "unicos",
                        "tarifa_cliente"
                      )
                    }
                    locale="es-CL"
                    mode="decimal"
                    maxFractionDigits={2}
                    disabled={cotizacion?.estado.estado === "ASIGNACIÓN"}
                  />
                )}
              />

              {/* <Column
                header="Descuento Tarifa %"
                field="descuento"
                body={(rowData) => (
                  <InputNumber
                    value={isFinite(rowData.descuento) ? rowData.descuento : 0}
                    onChange={(e) =>
                      handleTablaChange(rowData, e.value, "unicos", "descuento")
                    }
                    locale="es-CL"
                    mode="decimal"
                    maxFractionDigits={2}
                    disabled={rowData.tarifa_base === 0}
                  />
                )}
              /> */}
              {cotizacion?.estado.estado !== "ASIGNACIÓN" && (
                <Column
                  header="Acción"
                  style={{ minWidth: "120px" }}
                  body={(rowData) => (
                    <>
                      <Button
                        icon="pi pi-plus"
                        className="p-button-rounded p-button-success p-button mr-2"
                        label=""
                        onClick={() => handleNewTramoCuota(rowData)}
                        disabled={
                          rowData.tramo && rowData.tramo.includes("Cuota")
                        }
                        outlined
                      ></Button>
                      <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button ml-2"
                        label=""
                        onClick={() => handleBorrarLinea(rowData, "unicos")}
                        outlined
                        disabled={loading}
                      ></Button>
                      <Dialog
                        visible={showTramoModalUnico}
                        onHide={() => {
                          setShowTramoModalUnico(false);
                        }}
                        style={{ width: "1200px" }}
                        modal
                        dismissableMask={false}
                      >
                        <div className="flex align-items-center justify-content-center">
                          <div className="grid">
                            <div className="col-12">
                              <h5>
                                Seleccione cantidad de cuotas para{" "}
                                {nombreCuadro}
                              </h5>
                              <div>
                                <InputNumber
                                  value={0}
                                  onChange={(e) => setCuotas(e.value)}
                                  locale="es-CL"
                                  maxFractionDigits={0}
                                  max={maxAmountOfCuotas}
                                />
                                <div className="field col-12 md:col-12">
                                  <Button
                                    icon="pi pi-check"
                                    label={`Confirmar`}
                                    className="p-button-rounded p-button-success p-button  mb-2 mt-2"
                                    onClick={() =>
                                      handleTramoConfirmacionCuota(itemCuotas)
                                    }
                                    disabled={1 >= cuotas || loading}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </>
                  )}
                />
              )}
            </DataTable>
            <div
              className="grid ml-2 mr-2 mb-4 mt-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></div>
          </>
        )}
        <div className="col-12 md:col-12">
          {/* <Panel header="TOTAL">
            <div className="grid text-right">
              <div className="col-10">SUBTOTAL</div>
              <div className="col-2">
                <span className="pink-color">
                  {subtotal.toLocaleString("en-US").replace(".", ",")} UF
                </span>
              </div>

              <div className="col-10">
                <span>I.V.A</span>
              </div>
              <div className="col-2">
                <span className="pink-color">
                  {iva.toLocaleString("en-US").replace(".", ",")} UF
                </span>
              </div>

              <div className="col-10">
                <span>TOTAL</span>
              </div>
              <div className="col-2">
                <span className="pink-color">
                  {total.toLocaleString("en-US").replace(".", ",")} UF
                </span>
              </div>
              <div className="col-10">
                <span></span>
              </div>

              
            </div>
          </Panel> */}
          <Dialog
            visible={verResumenMensual}
            onHide={() => setVerResumenMensual(false)}
            modal
          >
            <div className="p-fluid formgrid grid">
              <div className="col-12 md:col-12" style={{ textAlign: "center" }}>
                <h2>Resumen Mensual Neto</h2>
                <h7 style={{ marginTop: "5px" }}>
                  ¿Cuánto pagaré mensualmente?
                </h7>
              </div>

              <ul>
                <div
                  className="col-12 md:col-12"
                  style={{ textAlign: "center" }}
                >
                  <DataTable
                    value={matrixArray}
                    className="p-datatable-gridlines"
                    showGridlines
                    rows={10}
                    dataKey="id"
                    responsiveLayout="scroll"
                    stripedRows={true}
                    emptyMessage="No hay montos para este año"
                    loading={loading}
                  >
                    {columns.map((col) => (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                      />
                    ))}
                  </DataTable>
                </div>
              </ul>
              <div className="col-12 md:col-12" style={{ textAlign: "center" }}>
                <h7 style={{ marginTop: "5px" }}>
                  *Tarifas con remarcador no incluidas, esto puede variar el
                  precio final mensual a pagar
                </h7>
              </div>
            </div>
          </Dialog>
        </div>
        <div className="p-fluid formgrid grid ">
          <div className="col-12 md:col-2">
            <Button
              label="Agregar Subproducto"
              icon="pi pi-plus"
              className="p-button-success mt-4 mb-4"
              onClick={() => openDialog("subproducto")}
              style={{ width: "100%" }}
              disabled={loading || cotizacion?.estado.estado === "ASIGNACIÓN"}
            />
          </div>
          <div className="col-12 md:col-2">
            <Button
              label={`Agregar Servicio`}
              icon="pi pi-plus"
              className="p-button-success mt-4 mb-4"
              onClick={() => openDialog("servicio")}
              style={{ width: "100%" }}
              disabled={loading || cotizacion?.estado.estado === "ASIGNACIÓN"}
            ></Button>
          </div>
          <div className="col-12 md:col-2">
            <Button
              label="Enviar a Cliente"
              icon="pi pi-send"
              className=" mt-4 mb-4 "
              onClick={() => {}}
              style={{}}
              disabled
            ></Button>
          </div>
          <div className="col-12 md:col-2">
            <Button
              label="Ver Resumen Mensual"
              icon="pi pi-search"
              onClick={() => handleShowResumenMensual()}
              className=" mt-4 mb-4 "
              disabled={loading}
            />
          </div>
          <div className="col-12 md:col-2">
            <Button
              label="Guardar Cotización"
              icon="pi pi-save"
              className="p-button mt-4 mb-4"
              onClick={guardarCotizacion}
              style={{ width: "100%" }}
              disabled={loading || cotizacion?.estado.estado === "ASIGNACIÓN"}
            ></Button>
          </div>
          <div className="col-12 md:col-2">
            <Button
              label={`Cerrar Contrato`}
              icon="pi pi-dollar"
              className="p-button-success mt-4 mb-4"
              onClick={() => setShowCerrarContrato(true)}
              style={{ width: "100%" }}
              disabled={loading || cotizacion?.estado.estado === "ASIGNACIÓN"}
            ></Button>
          </div>{" "}
          <Dialog
            visible={showCerrarContrato}
            onHide={() => setShowCerrarContrato(false)}
            modal
          >
            <div className="p-fluid formgrid grid">
              <div className="col-12 md:col-12" style={{ textAlign: "center" }}>
                <h2>Cerrando Cotización</h2>
                <h7 style={{ marginTop: "5px" }}>
                  ¿Cuántos dias previos al término del contrato se deberá dar
                  aviso?
                </h7>
                <div style={{ marginTop: "10px" }}>
                  <label htmlFor="daysSelect">
                    Seleccione la cantidad de días:
                  </label>
                  <Dropdown
                    id="daysDropdown"
                    value={diasAviso}
                    options={cantidadDiasAvisoContrato}
                    onChange={(e) => setDiasAviso(e.value)}
                    optionLabel="label"
                    placeholder="Seleccionar"
                  />
                </div>
              </div>
              <div className="col-12 md:col-12" style={{ textAlign: "center" }}>
                <Button
                  label="Cerrar Contrato"
                  icon="pi pi-dollar"
                  className="mr-2 mb-2 mt-4 p-button-success"
                  onClick={cerrarContrato}
                  style={{ width: "400px" }}
                ></Button>{" "}
              </div>
            </div>
          </Dialog>
        </div>
        <Dialog
          header="Seleccionar Producto"
          visible={visible}
          style={{ width: "50vw" }}
          modal
          onHide={closeDialog}
        >
          <div>
            <Dropdown
              value={selectedProduct}
              options={products}
              onChange={handleProductChange}
              optionLabel="descripcion"
              placeholder="Seleccione un producto"
              style={{ width: "100%" }}
            />
            {typeOfAdd === "subproducto" && filteredSubproductos.length > 0 && (
              <>
                <div className="flex align-items-center justify-content-center">
                  <div className="">
                    <div className="">
                      <h5>Seleccione Sub Producto Adicional</h5>
                      <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-6">
                          <label htmlFor="tipoSubP">Tipo Sub Producto</label>
                          <Dropdown
                            id="tipoSubP"
                            value={subProductoAdicional}
                            onChange={(e) => {
                              setSubProductoAdicional(e.value);
                            }}
                            options={filteredSubproductos}
                            optionLabel="descripcion"
                            placeholder="Selecione"
                          ></Dropdown>
                        </div>
                        <div className="field col-12 md:col-6">
                          <label htmlFor="tipoSubP">Metros Cuadrados</label>
                          <InputNumber
                            id="metrajeSubp"
                            value={metrajeSubproductoAdicional}
                            onChange={(e) => {
                              setMetrajeSubproductoAdicional(e.value);
                            }}
                            placeholder="Selecione Metraje"
                          ></InputNumber>
                        </div>
                        <div
                          className="field col-12 md:col-12"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            icon="pi pi-plus"
                            label={`Agregar`}
                            className="p-button-rounded p-button-success p-button  mb-2 mt-2"
                            onClick={() => {
                              handleAñadirAdicional(typeOfAdd);
                            }}
                            style={{ width: "400px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {typeOfAdd === "servicio" && (
              <>
                <DataTable
                  value={filteredServicios}
                  selection={selectedServicios}
                  onSelectionChange={handleServicioSelectionChange}
                  className="p-datatable-gridlines"
                  showGridlines
                  rows={10}
                  dataKey="id"
                  emptyMessage="No tiene servicios asociados"
                  loading={loading}
                >
                  <Column selectionMode="multiple" style={{ width: "3em" }} />
                  <Column field="descripcion" header="Servicios" />
                  <Column field="moneda" header="Moneda" />
                  <Column field="unidad" header="Unidad Medida" />
                </DataTable>
                <div className="mt-2">
                  <Button
                    label="Añadir"
                    icon="pi pi-plus"
                    onClick={() => {
                      handleAñadirAdicional(typeOfAdd);
                    }}
                    className="mr-2"
                    disabled={selectedServicios.length === 0}
                  />
                  <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    onClick={closeDialog}
                    className="p-button-secondary"
                  />
                </div>
              </>
            )}
          </div>
        </Dialog>
      </>
    </div>
  );
};

export default RevisarCotizacion;
