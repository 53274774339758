import React from "react";
import { classNames } from "primereact/utils";
import { RTLContext } from "../App";
import { useContext } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Chart } from "primereact/chart";

const OverviewBox = ({
  icon,
  title,
  menuRef,
  chartRef,
  chartData,
  chartOptions,
  amount,
  status,
}) => {
  const isRTL = useContext(RTLContext);

  return (
    <div className="col-12 md:col-6 lg:col-3">
      <div className="card overview-box flex flex-column pt-2">
        <div className="flex align-items-center muted-text">
          <i className={`pi ${icon}`}></i>
          <h6 className={classNames("m-0", { "pl-2": !isRTL, "pr-2": isRTL })}>
            {title}
          </h6>
          <div className={classNames({ "ml-auto": !isRTL, "mr-auto": isRTL })}>
            <Button
              type="button"
              icon="pi pi-ellipsis-h"
              className="p-button-rounded p-button-text p-button-plain"
              onClick={(event) => menuRef.current.toggle(event)}
            ></Button>
            <Menu
              ref={menuRef}
              popup
              model={[
                { label: "Actualizar", icon: "pi pi-fw pi-refresh" },
                { label: "Editar", icon: "pi pi-fw pi-pencil" },
              ]}
            ></Menu>
          </div>
        </div>
        <div className="flex justify-content-between mt-3 flex-wrap">
          <div className="flex flex-column" style={{ width: "80px" }}>
            <span
              className="mb-1 fs-xlarge"
              style={{ color: "black!important" }}
            >
              {amount}
            </span>
            {status && (
              <span
                className={`overview-status p-1 ${status}-bgcolor fs-small`}
              >
                {status}
              </span>
            )}
          </div>
          <div className="flex align-items-end">
            <Chart
              ref={chartRef}
              type="line"
              data={chartData}
              options={chartOptions}
              height="60px"
              width="160px"
            ></Chart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewBox;
