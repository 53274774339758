import React, { useState, useEffect } from "react";

import { Button } from "primereact/button";

import { useGlobalContext } from "../Config";
import FormBusquedaProductos from "../components/FormBusquedaProductos";

import ProductosDisponiblesTable from "../components/ProductosDisponiblesTable";
import UseScrollOnStateChange from "../components/UseScrollOnStateChange";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom"; // Use useLocation to get the current URL
import StepByStep from "../components/StepByStep";
import Loader from "../components/Loader";

const CotizacionSelectProductos = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idCliente = parseInt(searchParams.get("idCliente"));
  const idCotizacion = parseInt(searchParams.get("idCotizacion"));
  const selected = searchParams.get("selected");
  const activeIndex = parseInt(searchParams.get("activeIndex"));
  const [carro, setCarro] = useState([]);
  const [cliente, setCliente] = useState(null);
  const { baseUrl } = useGlobalContext();
  const [empresa, setEmpresa] = useState("TODAS");
  const empresas = [
    { name: "TODAS", value: "TODAS" },
    { name: "ALTAMIRA", value: "Altamira" },
    { name: "LA SIEMBRA", value: "LA SIEMBRA" },
  ];
  const [showProductosEncontrados, setShowProductosEncontrados] =
    useState(false);
  const [tipoProducto, setTipoProducto] = useState("");
  const [tiposProducto, setTiposProducto] = useState([
    { id: 1, descripcion: "Bodega", code: "Bodega", cobro: "Factura Exenta" },
    { id: 2, descripcion: "Sitio", code: "Sitio", cobro: "Factura Exenta" },
    { id: 3, descripcion: "Casino", code: "Casino", cobro: "Factura Exenta" },
    {
      id: 4,
      descripcion: "Zona Lavado",
      code: "Zona Lavado",
      cobro: "Factura Exenta",
    },
  ]);
  const navigate = useNavigate();

  const [desde, setDesde] = useState(null);
  const [hasta, setHasta] = useState(null);
  const [rowClick, setRowClick] = useState(true);

  const [loading, setLoading] = useState(false);
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [displayConstruccion, setDisplayConstruccion] = useState(false);

  const searchProductos = async () => {
    try {
      if (!isValidFiltrosProductos()) {
        return;
      }

      resetCarro();
      setProductosDisponibles([]);
      const empresasArray = mapEmpresas(empresa);
      const tiposArray = mapTipos(tipoProducto);
      const { desdeFiltro, hastaFiltro } = mapDateFilters(desde, hasta);

      setLoading(true);
      setShowProductosEncontrados(true);

      const url = buildProductosUrl(
        empresasArray,
        desdeFiltro,
        hastaFiltro,
        tiposArray
      );
      const data = await fetchData(url);
      handleProductosResponse(data);
    } catch (error) {
      console.error(error);
    }
  };

  const isValidFiltrosProductos = () => {
    if (!checkCabeceraFiltrosProductos()) {
      return false;
    }
    return true;
  };

  const checkCabeceraFiltrosProductos = () => {
    const basicChecks =
      empresa !== null &&
      empresa !== undefined &&
      empresa !== "" &&
      tipoProducto !== null &&
      tipoProducto !== undefined &&
      tipoProducto !== "" &&
      desde !== null &&
      desde !== undefined &&
      hasta !== null &&
      hasta !== undefined &&
      hasta !== "";

    const dateChecks = new Date(hasta) >= new Date(desde);

    const currentDate = new Date();
    const currentDateChecks =
      new Date(desde) >= currentDate && new Date(hasta) >= currentDate;

    const isDateValid = dateChecks && currentDateChecks;
    const isBasicValid = basicChecks;
    const isValid = isDateValid && isBasicValid;

    if (!isBasicValid) {
      alert("Debe ingresar todos los parámetros de búsqueda");
    }
    if (!isDateValid && isBasicValid) {
      alert(
        "Las fechas deben ser mayores a la de hoy y la fecha de término debe ser mayor o igual a la de inicio"
      );
    }

    return isValid;
  };

  const mapEmpresas = (selectedEmpresa) => {
    const empresaMapping = {
      TODAS: ["2", "3"],
      ALTAMIRA: ["2"],
      "LA SIEMBRA": ["3"],
    };
    return empresaMapping[selectedEmpresa.toUpperCase()] || [];
  };

  const mapTipos = (tipoProductoArray) => {
    return tipoProductoArray.map((tipo) => tipo.descripcion);
  };
  const buildProductosUrl = (
    empresasArray,
    desdeFiltro,
    hastaFiltro,
    tiposArray
  ) => {
    return `${baseUrl}getProductosByFiltro?empresas=${empresasArray}&fechaInicio=${desdeFiltro}&fechaTermino=${hastaFiltro}&tipos=${tiposArray}`;
  };

  const handleProductosResponse = (data) => {
    if (Array.isArray(data.productos)) {
      setProductosDisponibles(data.productos);
      setLoading(false);
    } else {
      console.error("Data.clientes no es array:", data.clientes);
      setLoading(false);
    }
  };

  const mapDateFilters = (desde, hasta) => {
    const dateObjectDesde = new Date(desde);
    const dateObjectHasta = new Date(hasta);
    const desdeFiltro = dateObjectDesde.toISOString().split("T")[0];
    const hastaFiltro = dateObjectHasta.toISOString().split("T")[0];
    setDesdeFiltro(desdeFiltro);
    setHastaFiltro(hastaFiltro);

    return { desdeFiltro, hastaFiltro };
  };

  const [desdeFiltro, setDesdeFiltro] = useState("");
  const [hastaFiltro, setHastaFiltro] = useState("");

  const resetCarro = () => {
    setCarro([]);
  };

  const fetchDataCliente = async () => {
    try {
      const response = await fetch(
        `${baseUrl}getClienteById?idCliente=${idCliente}`
      );
      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      const data = await response.json();
      setCliente(data);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };
  const fetchData = async (url) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(
        `Network response was not ok. Status: ${response.status}`
      );
    }

    return response.json();
  };
  useEffect(() => {
    if (idCliente && selected === "true" && activeIndex) {
      fetchDataCliente();
    }
  }, [idCliente, selected]);

  const selectSubProductsAndServices = () => {
    if (carro.length <= 0) {
      alert("Seleccione al menos un producto");
    } else {
      // Update each item in carro to include tarifa_base attribute
      const updatedCarro = carro.map((item) => ({
        ...item,
        tarifa_base: item.tarifa_base,
      }));

      const carroEncoded = encodeURIComponent(JSON.stringify(updatedCarro));


      const newURL =
       
        `/selectSubProductosYServicios?activeIndex=2&selectedItems=${carroEncoded}&idCliente=${cliente.id}&selected=true&desde=${desdeFiltro}&hasta=${hastaFiltro}&idCotizacion=${idCotizacion}`;
      navigate(newURL);
    }
  };
  UseScrollOnStateChange(productosDisponibles, "productosDataTable");
  return (
    <div className="card">
      {activeIndex && <StepByStep activeIndex={activeIndex} />}

      {cliente && (
        <FormBusquedaProductos
          baseUrl={baseUrl}
          cliente={cliente}
          empresa={empresa}
          setEmpresa={setEmpresa}
          empresas={empresas}
          tipoProducto={tipoProducto}
          setTipoProducto={setTipoProducto}
          tiposProducto={tiposProducto}
          setTiposProducto={setTiposProducto}
          desde={desde}
          setDesde={setDesde}
          hasta={hasta}
          setHasta={setHasta}
          searchProductos={searchProductos}
          idCotizacion={idCotizacion}
        />
      )}
      {showProductosEncontrados && (
        <>
          <div
            className="field col-12 md:col-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              id="productosDataTable"
              label="Confirmar"
              icon="pi pi-check"
              className="mr-2 mb-2 p-button-success"
              onClick={selectSubProductsAndServices}
              style={{ width: "400px" }}
              visible={!loading}

            ></Button>
          </div>
          {loading ? (
            <>
              <Loader></Loader>
            </>
          ) : (
            <div className="field col-12 md:col-12">
              <ProductosDisponiblesTable
                productosDisponibles={productosDisponibles}
                loading2={loading}
                rowClick={rowClick}
                carro={carro}
                setCarro={setCarro}
                desdeFiltro={desdeFiltro}
                hastaFiltro={hastaFiltro}
                setDisplayConstruccion={setDisplayConstruccion}
                displayConstruccion={displayConstruccion}
              />
            </div>
          )}

          <div
            className="field col-12 md:col-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              label="Confirmar"
              icon="pi pi-check"
              className="mr-2 mb-2 p-button-success"
              onClick={selectSubProductsAndServices}
              style={{ width: "400px" }}
              visible={!loading}
            ></Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CotizacionSelectProductos;
