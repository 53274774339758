import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ServiciosTable = ({
  carro,
  currentIndex,
  servicios,
  selectedServicios,
  handleServicioSelectionChange,
}) => {
    const filteredServicios = servicios.filter(servicio => servicio.incluido);

  return (
    <>
      <h5>{`Servicios para: ${carro[currentIndex].nombre_producto}`}</h5>

      <DataTable
        value={filteredServicios}
        selection={selectedServicios}
        onSelectionChange={handleServicioSelectionChange}
        className="p-datatable-gridlines"
        showGridlines
        rows={10}
        dataKey="id"
        emptyMessage="No tiene servicios asociados"
      >
        <Column selectionMode="multiple" style={{ width: "3em" }} />
        <Column field="descripcion" header="Servicios" />
        <Column field="moneda" header="Moneda" />
        <Column field="unidad" header="Unidad Medida" />
      </DataTable>
    </>
  );
};

export default ServiciosTable;
