import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const RegistrosTable = ({
  renderButtons,
  remarcadoresFiltrados,
  dialogRegistro,
  selectedTipoServicio,
  handleBotonServicio,
  closeBotonServicio,
  nombreServicio,
  uploadBodyTemplate,
  newRegistro,
  setNewRegistro,
  handleGuardarRegistro,
  error,
  filters,
}) => {
  return (
    <div className="col-12 md:col-12">
      <h4 style={{ marginTop: "5px" }}>
        Remarcadores disponibles para registrar
      </h4>
      <DataTable
        value={remarcadoresFiltrados}
        header="Remarcadores encontrados"
        emptyMessage="No hay remarcadores para mostrar"
        className="p-datatable-gridlines"
        filters={filters}
      >
        <Column
          field="servicio"
          header="Servicio con Remarcador"
          filter
          filterPlaceholder="Buscar por servicio"
          style={{ minWidth: "10rem", textAlign: "left" }}
        />
        <Column
          field="fecha"
          header="Fecha Último Registro"
          filter
          filterPlaceholder="Buscar por último registro"
          style={{ minWidth: "10rem", textAlign: "left" }}
        />
        <Column
          field="medidaAgua"
          header="Medición Anterior Agua"
          filterPlaceholder="Buscar por último registro"
          style={{ minWidth: "10rem", textAlign: "left" }}
        />
        <Column
          field="medidaElectricidad"
          header="Medición Anterior Electricidad"
          filterPlaceholder="Buscar por último registro"
          style={{ minWidth: "10rem", textAlign: "left" }}
        />
        <Column
          field=""
          header="Medición Actual Agua"
          filterPlaceholder="Buscar por último registro"
          style={{ minWidth: "10rem", textAlign: "left" }}
        />
        <Column
          field="newRegistro"
          header="Medición Actual Electricidad"
          filterPlaceholder="Buscar por último registro"
          style={{ minWidth: "10rem", textAlign: "left" }}
        />
        <Column
          field="periodo"
          header="Periodo registro"
          filter
          filterPlaceholder="Buscar por último registro"
          style={{ minWidth: "10rem", textAlign: "left" }}
        />
        <Column
          header="Servicios"
          body={(rowData) => renderButtons(handleBotonServicio, rowData)}
          style={{ minWidth: "9rem", textAlign: "left" }}
        />
      </DataTable>
      <Dialog
        blockScroll
        modal
        visible={dialogRegistro}
        onHide={closeBotonServicio}
        header={`Registrar Servicio - ${nombreServicio}`}
        style={{ width: "75vw" }}
        footer={
          <div className="formgrid grid col-12 md:col-12">
            <div className="field col-6 md:col-6">
              <Button
                className="p-button-rounded p-button-success"
                label="Guardar"
                onClick={handleGuardarRegistro}
              ></Button>
            </div>
            <div className="field col-6 md:col-6">
              <Button
                className="p-button-rounded p-button-danger"
                label="Cancelar"
                onClick={closeBotonServicio}
              ></Button>
            </div>
          </div>
        }
      >
        <div className="card p-fluid formgrid grid">
          <div className="field col-12 md:col-12">
            <DataTable
              value={[selectedTipoServicio]}
              className="p-datatable-gridlines"
            >
              <Column
                field={`remarcador${nombreServicio}`}
                header="Numero Remarcador"
              />
              <Column
                field={`medida${nombreServicio}`}
                header="Ultima medición"
              />
              <Column header="Fotografía respaldo" body={uploadBodyTemplate} />
            </DataTable>
          </div>
          <div className="field col-12 md:col-12">
            <h4>Ingrese el valor actual:</h4>
            <div className="field col-12 md:col-12">
              <InputText
                placeholder="Valor actual del remarcador"
                id="newRegistro"
                type="text"
                value={newRegistro}
                onChange={(e) => setNewRegistro(e.target.value)}
              ></InputText>
            </div>
            {error && <div style={{ color: "red" }}>{error}</div>}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default RegistrosTable;
