import { useState } from 'react';

// Utility functions for RUT validation and formatting
const cleanRut = (rut) => {
  return rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
};

const validateRut = (rut) => {
  if (!rut || typeof rut !== 'string') return false;
  const cleanRutVal = cleanRut(rut);
  if (cleanRutVal.length < 2) return false;

  const body = cleanRutVal.slice(0, -1);
  const verifier = cleanRutVal.slice(-1);

  let sum = 0;
  let multiplier = 2;

  for (let i = body.length - 1; i >= 0; i--) {
    sum += multiplier * +body[i];
    multiplier = multiplier === 7 ? 2 : multiplier + 1;
  }

  const mod = 11 - (sum % 11);
  const expectedVerifier = mod === 11 ? '0' : mod === 10 ? 'K' : mod.toString();

  return expectedVerifier === verifier;
};

const formatRut = (rut) => {
  const cleanRutVal = cleanRut(rut);
  let result = cleanRutVal.slice(-4, -1) + '-' + cleanRutVal.substr(cleanRutVal.length - 1);

  for (let i = 4; i < cleanRutVal.length; i += 3) {
    result = cleanRutVal.slice(-3 - i, -i) + '.' + result;
  }

  return result;
};

const Rut = () => {
  const [rut, setRut] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleRutChange = (event) => {
    const inputRut = event.target.value;
    setRut(inputRut);

    const cleanRutVal = cleanRut(inputRut);
    setIsValid(validateRut(cleanRutVal));
  };

  const handleRutBlur = () => {
    if (isValid) {
      setRut(formatRut(rut));
    }
  };

  return { rut, isValid, handleRutChange, handleRutBlur };
};

export default Rut;
