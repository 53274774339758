import { useEffect } from "react";

const UseScrollOnStateChange = (state, targetElementId) => {
  useEffect(() => {
    const topBarHeight = 140;
    const dataTableContainer = document.getElementById(targetElementId);
    if (dataTableContainer) {
      const scrollPosition = dataTableContainer.offsetTop - topBarHeight;
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }
  }, [state, targetElementId]);
};

export default UseScrollOnStateChange;
