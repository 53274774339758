import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import RegistrosTable from "../components/RegistrosTable";
import HistorialTable from "../components/HistorialTable";
import { FileUpload } from "primereact/fileupload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDroplet,
  faBoltLightning,
  faCalculator,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const Remarcador = (props) => {
  const [servicio, setServicio] = useState("");
  const [tipoServicio, setTipoServicio] = useState("");
  const [idTipoServicio, setIdTipoServicio] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showTablaHistorica, setShowTablaHistorica] = useState(false);
  const [showRegistro, setShowRegistro] = useState(false);
  const [showLectura, setShowLectura] = useState(false);
  const [dialogRegistro, setDialogRegistro] = useState(false);
  const [selectedServicio] = useState("");
  const [selectedTipoServicio, setSelectedTipoServicio] = useState("");
  const [nombreServicio, setNombreServicio] = useState("");
  const toast = useRef(null);
  const [newRegistro, setNewRegistro] = useState("");
  const [error, setError] = useState(null);
  const [filters] = useState(null);
  const [showUltimos, setShowUltimos] = useState(false);
  const [opcionesServicio, setOpcionesServicio] = useState("");

  const opcionesTipoServicio = [
    { id: 1, name: "Bodega" },
    { id: 2, name: "Sitio" },
    { id: 3, name: "Mini PYME" },
  ];

  const dataHistórica = [
    {
      id: 1,
      idTipoServicio: 1,
      idProducto: 1,
      servicio: "Bodega 1",
      fecha: "2023-05-10",
      tipoRemarcador: "Agua",
      numeroRemarcador: "A123",
      valor: 150,
      responsable: "Usuario1",
    },
    {
      id: 2,
      idTipoServicio: 2,
      servicio: "Sitio 2",
      fecha: "2023-06-18",
      tipoRemarcador: "Electricidad",
      numeroRemarcador: "E456",
      valor: 220,
      responsable: "Usuario2",
    },
    {
      id: 3,
      idTipoServicio: 3,
      servicio: "Mini PYME 3",
      fecha: "2023-07-25",
      tipoRemarcador: "Agua",
      numeroRemarcador: "A789",
      valor: 180,
      responsable: "Usuario3",
    },
    {
      id: 4,
      idTipoServicio: 1,
      servicio: "Bodega 4",
      fecha: "2023-08-12",
      tipoRemarcador: "Electricidad",
      numeroRemarcador: "E234",
      valor: 300,
      responsable: "Usuario4",
    },
    {
      id: 5,
      idTipoServicio: 2,
      servicio: "Sitio 5",
      fecha: "2023-09-20",
      tipoRemarcador: "Agua",
      numeroRemarcador: "A567",
      valor: 250,
      responsable: "Usuario5",
    },
    {
      id: 6,
      idTipoServicio: 3,
      servicio: "Mini PYME 6",
      fecha: "2023-10-05",
      tipoRemarcador: "Electricidad",
      numeroRemarcador: "E789",
      valor: 400,
      responsable: "Usuario1",
    },
  ];

  const dataRemarcadores = [
    {
      id: 1,
      idTipoServicio: 1,
      servicio: "Bodega 1",
      remarcadorAgua: "A001",
      medidaAgua: 1923,
      remarcadorElectricidad: "E001",
      medidaElectricidad: 1207,
      fecha: "2024-03-06",
      agua: "Si",
      electricidad: "Si",
      periodo: "Febrero 2024",
    },
    {
      id: 2,
      idTipoServicio: 2,
      servicio: "Sitio 1",
      remarcadorAgua: "A002",
      medidaAgua: 1746124,
      remarcadorElectricidad: "E002",
      medidaElectricidad: 124124,
      fecha: "2024-03-06",
      agua: "Si",
      electricidad: "Si",
      periodo: "Febrero 2024",
    },
    {
      id: 3,
      idTipoServicio: 3,
      servicio: "Mini PYME 1",
      remarcadorAgua: "A003",
      medidaAgua: 87355,
      remarcadorElectricidad: "E003",
      medidaElectricidad: 10924,
      fecha: "2024-03-06",
      agua: "Si",
      electricidad: "Si",
      periodo: "Febrero 2024",
    },
    {
      id: 4,
      idTipoServicio: 1,
      servicio: "Bodega 3",
      remarcadorAgua: "A004",
      medidaAgua: 178241,
      remarcadorElectricidad: "E004",
      medidaElectricidad: 91241,
      fecha: "2024-03-06",
      agua: "Si",
      electricidad: "Si",
      periodo: "Febrero 2024",
    },
  ];

  const handleChangeTipoServicio = (e) => {
    const selectedTipoServicio = e.value;
    setTipoServicio(selectedTipoServicio);
    setIdTipoServicio(selectedTipoServicio.id);
    setShowTable(true);
    const opcionesServicioFiltradas = dataHistórica
      .filter(
        (remarcador) => remarcador.idTipoServicio === selectedTipoServicio.id
      )
      .map((remarcador) => ({ id: remarcador.id, name: remarcador.servicio }));

    setOpcionesServicio(opcionesServicioFiltradas);
  };

  const handleChangeServicio = (e) => {
    const selectedServicio = e.value;
    setServicio(selectedServicio);
    setShowTablaHistorica(true);
    setShowUltimos(false);
  };

  const handleRegistro = () => {
    setShowRegistro(true);
    setShowLectura(false);
    setShowTable(false);
    setTipoServicio("");
    setIdTipoServicio("");
  };
  const handleLectura = () => {
    setShowLectura(true);
    setShowRegistro(false);
    setShowUltimos(true);
    setShowTablaHistorica(false);
    setTipoServicio("");
    setIdTipoServicio("");
    setOpcionesServicio("");
  };
  const handleBotonServicio = (servicio, rowData) => {
    setNombreServicio(servicio);
    setSelectedTipoServicio(rowData);
    setDialogRegistro(true);
  };
  const closeBotonServicio = () => {
    setDialogRegistro(false);
    setSelectedTipoServicio("");
    setNewRegistro("");
  };

  const onUpload = () => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "Archivo Cargado Exitosamente",
      life: 3000,
    });
  };

  const handleGuardarRegistro = () => {
    if (!newRegistro || isNaN(parseFloat(newRegistro))) {
      setError("Por Favor ingrese un valor válido");
      return;
    }
    const nuevoValor = parseFloat(newRegistro);
    const ultimoRegistro = parseFloat(
      selectedTipoServicio[`medida${nombreServicio}`]
    );
    if (nuevoValor === ultimoRegistro) {
      setError("El valor ingresado es igual al último registro.");
      return;
    } else if (nuevoValor < ultimoRegistro) {
      setError("El valor ingresado es menor al último registro.");
      return;
    } else if (nuevoValor > ultimoRegistro) {
      const diferencia = nuevoValor - ultimoRegistro;
      if (diferencia > ultimoRegistro * 0.2) {
        setError(
          "El valor ingresado es mayor en más del 20% al último registro."
        );
        return;
      }
    }
    setNewRegistro("");
    setError(null);
    closeBotonServicio();
  };

  const renderButtons = (handleBotonServicio, rowData) => {
    const buttons = [];

    if (rowData.agua === "Si") {
      buttons.push(
        <Button
          key="agua"
          style={{ marginLeft: "5px", marginBottom: "5px" }}
          icon={<FontAwesomeIcon icon={faDroplet}></FontAwesomeIcon>}
          className="p-button-info p-button-rounded"
          onClick={() => handleBotonServicio("Agua", rowData)}
        />
      );
    }

    if (rowData.electricidad === "Si") {
      buttons.push(
        <Button
          key="electricidad"
          style={{ marginLeft: "5px", marginBottom: "5px" }}
          icon={<FontAwesomeIcon icon={faBoltLightning}></FontAwesomeIcon>}
          className="p-button-success p-button-rounded"
          onClick={() => handleBotonServicio("Electricidad", rowData)}
        />
      );
    }

    return buttons;
  };

  const uploadBodyTemplate = () => {
    return (
      <div>
        <FileUpload
          mode="basic"
          name="demo[]"
          url="./upload.php"
          accept="image/*"
          maxFileSize={1000000}
          onUpload={onUpload}
        />
      </div>
    );
  };

  const remarcadoresFiltrados = dataRemarcadores.filter(
    (remarcador) => remarcador.idTipoServicio === idTipoServicio
  );

  const registrosFiltrados = dataHistórica.filter(
    (registro) => registro.servicio === selectedServicio
  );

  return (
    <div className="grid table-demo">
      <div className="field col-12 md:col-12">
        <h2 style={{ marginTop: "5px" }}>Remarcadores</h2>
        <h6 style={{ marginTop: "5px" }}>
          Registro y lectura de los remarcadores
        </h6>
      </div>

      <div
        className="card col-12 md:col-12 justify-content-center text-center"
        style={{ marginLeft: "0px" }}
      >
        <Button
          style={{ marginTop: "10px", marginBottom: "10px" }}
          label="Registrar un remarcador"
          icon={<FontAwesomeIcon icon={faCalculator}></FontAwesomeIcon>}
          className="col-8 md:col-7 p-button-rounded p-button-outlined"
          onClick={handleRegistro}
        ></Button>{" "}
        <Button
          style={{ marginTop: "10px", marginBottom: "10px" }}
          label="Historial de registros"
          icon={<FontAwesomeIcon icon={faBook}></FontAwesomeIcon>}
          className="col-8 md:col-7 p-button-rounded p-button-outlined"
          onClick={handleLectura}
        ></Button>{" "}
      </div>

      {showRegistro && (
        <div
          className="grid card col-12 md:col-12"
          style={{ marginLeft: "0px" }}
        >
          <div className=" col-12 md:col-12">
            <h4 style={{ marginTop: "5px" }}>
              Seleccione el tipo de servicio que desea registrar
            </h4>
            <Dropdown
              id="tipoServicios"
              value={tipoServicio}
              onChange={handleChangeTipoServicio}
              options={opcionesTipoServicio}
              optionLabel="name"
              placeholder="Seleccione una opción"
              style={{ marginTop: "5px" }}
            ></Dropdown>
          </div>

          {showTable && (
            <RegistrosTable
              renderButtons={renderButtons}
              remarcadoresFiltrados={remarcadoresFiltrados}
              dialogRegistro={dialogRegistro}
              selectedTipoServicio={selectedTipoServicio}
              handleBotonServicio={handleBotonServicio}
              closeBotonServicio={closeBotonServicio}
              nombreServicio={nombreServicio}
              uploadBodyTemplate={uploadBodyTemplate}
              newRegistro={newRegistro}
              setNewRegistro={setNewRegistro}
              handleGuardarRegistro={handleGuardarRegistro}
              error={error}
            ></RegistrosTable>
          )}
        </div>
      )}
      {showLectura && (
        <div className="card col-12 md:col-12" style={{ marginLeft: "0px" }}>
          {showUltimos && (
            <div className="formgrid col-12 md:col-12">
              <h4 style={{ marginLeft: "5px" }}>
                Ultimos registros encontrados
              </h4>
              <DataTable
                value={dataHistórica}
                emptyMessage="No hay registros para mostrar"
                className="´-datatable-gridlines"
                filters={filters}
                style={{ marginBottom: "5px" }}
                showGridlines
              >
                <Column field="idTipoServicio" header="Id Servicio" />
                <Column field="servicio" header="Servicio" />
                <Column field="fecha" header="Fecha registro" />
                <Column field="tipoRemarcador" header="Tipo Remarcador" />
                <Column field="numeroRemarcador" header="Remarcador" />
                <Column field="valor" header="Medición" />
                <Column field="responsable" header="Responsable" />
              </DataTable>
            </div>
          )}

          <div className=" col-12 md:col-12">
            <h4 style={{ marginLeft: "5px", marginTop: "5px" }}>
              Seleccione el tipo y el servicio que desea revisar
            </h4>
            <Dropdown
              id="tipoServicios"
              value={tipoServicio}
              onChange={handleChangeTipoServicio}
              options={opcionesTipoServicio}
              optionLabel="name"
              placeholder="Tipo de servicio"
              style={{
                marginLeft: "5px",
                marginTop: "5px",
                marginRight: "5px",
              }}
            ></Dropdown>
            <Dropdown
              id="Servicios"
              value={servicio}
              onChange={handleChangeServicio}
              options={opcionesServicio}
              optionLabel="name"
              placeholder="Servicio"
              style={{ marginTop: "5px", marginRight: "5px" }}
            ></Dropdown>
          </div>
          {showTablaHistorica && (
            <HistorialTable
              registrosFiltrados={registrosFiltrados}
              tipoServicio={tipoServicio}
              servicio={servicio}
            ></HistorialTable>
          )}
        </div>
      )}
    </div>
  );
};

export default Remarcador;
