import React from "react";
import { Steps } from "primereact/steps";

export default function StepByStep({activeIndex}) {
  const items = [
    {
      label: "Seleccione Cliente",
    },
    {
      label: "Seleccione Productos",
    },
    {
      label: "Añada Subproductos Y Servcios",
    },
    {
      label: "Defina Tarifas",
    },
  ];

  return (
    <>
      <Steps model={items} activeIndex={activeIndex} />
    </>
  );
}
