import React from "react";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const FormBusquedaProductos = ({
  baseUrl,
  cliente,
  empresa,
  setEmpresa,
  empresas,
  tipoProducto,
  setTipoProducto,
  tiposProducto,
  setTiposProducto,
  desde,
  setDesde,
  hasta,
  setHasta,
  searchProductos,
  idCotizacion,
}) => {
  const today = new Date();
  const navigate = useNavigate();
  const fetchData = async (url) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(
        `Network response was not ok. Status: ${response.status}`
      );
    }

    return response.json();
  };
  const getTipoProductoByEmpresa = async (empresa) => {
    try {
      const url = `${baseUrl}getTiposProductoByEmpresa?empresa=${empresa}`;
      const data = await fetchData(url);
      handleTiposProductoResponse(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTiposProductoResponse = (data) => {
    if (Array.isArray(data.tiposProductos)) {
      setTiposProducto(data.tiposProductos);
    } else {
      console.error("Data received is not an array");
    }
  };
  useEffect(() => {
    getTipoProductoByEmpresa("TODAS");
  }, []);
  return (
    <>
      <ProgressBar value="100" showValue={false} className="mt-2 mb-4" />

      <div className="p-fluid formgrid grid" id="clienteSeleccionado">
        <div className="field col-12 md:col-2">
          <h5 htmlFor="firstname2" style={{ color: "green" }}>
            Cliente Seleccionado
          </h5>
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="firstname2">RUT</label>
          <h6>{`${cliente.rut}`}</h6>
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="firstname2">Razón Social</label>

          <h6>{`${cliente.razon_social}`}</h6>
        </div>
        <div className="field col-12 md:col-2">
          <label htmlFor="firstname2">Nombre Empresa</label>

          <h6>{`${cliente.nombre}`}</h6>
        </div>
      </div>
      <ProgressBar value="100" showValue={false} className="mt-2 mb-4" />
      <div
        className="field col-12 md:col-12"
        style={{ display: "flex", justifyContent: "start" }}
      >
        {" "}
        <Button
          label="Lista Cotizaciones"
          icon="pi pi-backward
          "
          className="mr-2 mb-2"
          onClick={() => {
            navigate("/cotizacion")
          }}
          
          style={{ width: "400px" }}
        ></Button>
      </div>
      <h4 style={{ marginBottom: "5px" }}>
        Cree la nueva asignación de servicios para la cotizacion #{" "}
        {idCotizacion}
      </h4>
      <h7 style={{ marginTop: "5px" }}>
        Seleccione empresa, tipo de producto y rango de fechas
      </h7>
      <div className="p-fluid formgrid grid" style={{ marginTop: "20px" }}>
        <div className="field col-12 md:col-6">
          <label htmlFor="firstname2">Empresa</label>
          <Dropdown
            id="state"
            value={empresa}
            onChange={async (e) => {
              setEmpresa(e.value);
              setTipoProducto("");
              try {
                await getTipoProductoByEmpresa(e.value);
              } catch (error) {
                console.error("Error occurred:", error);
              }
            }}
            options={empresas}
            optionLabel="name"
            placeholder="Seleccione Empresa"
            filter
          ></Dropdown>
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="tipoproducto">Tipo Producto</label>
          <MultiSelect
            id="tipoproducto"
            value={tipoProducto}
            onChange={(e) => {
              setTipoProducto(e.value);
            }}
            options={tiposProducto
              .filter(
                (option, index, self) =>
                  index ===
                  self.findIndex((t) => t.descripcion === option.descripcion)
              )
              .map((option) => ({
                ...option,
                label: `${option.descripcion}`,
              }))}
            optionLabel="label"
            placeholder="Seleccione Tipo Producto"
            filter
          ></MultiSelect>
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="firstname2">Desde</label>
          <Calendar
            dateFormat="dd/mm/yy"
            showIcon
            showButtonBar
            value={desde}
            onChange={(e) => setDesde(e.value)}
            minDate={today}
          ></Calendar>
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="lastname2">Hasta</label>
          <Calendar
            dateFormat="dd/mm/yy"
            showIcon
            showButtonBar
            value={hasta}
            onChange={(e) => setHasta(e.value)}
            minDate={today}
          ></Calendar>
        </div>
        <div
          className="field col-12 md:col-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {" "}
          <Button
            label="Buscar Productos"
            icon="pi pi-search"
            className="mr-2 mb-2"
            onClick={() => {
              searchProductos();
            }}
            style={{ width: "400px" }}
          ></Button>
        </div>
      </div>
    </>
  );
};

export default FormBusquedaProductos;
