import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";

import Loader from "./Loader";
const CotizacionesTable = ({
  cotizaciones,
  loading,
  desdeBodyTemplate,
  dateFilterTemplate,
  hastaBodyTemplate,
  estadoBodyTemplate,
  estadoFilterTemplate,
  actionBodyTemplate,
  handleRowClick,
}) => {
  const rowClassName = (rowData) => {
    return {
      "cursor-pointer": true, // Add your custom class here
    };
  };
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    initFilters();
  }, []);
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nombre_producto: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Buscar Razón Social"
          />
        </span>
        <span className="p-input-icon-left">
          Se encontraron {cotizaciones?.length} cotizaciones
        </span>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <div className="card">
      <div className="p-fluid formgrid grid"></div>
      {loading ? (
        <>
          <Loader></Loader>
        </>
      ) : (
        <>
          <DataTable
            value={cotizaciones}
            paginator
            className="p-datatable-gridlines"
            showGridlines
            rows={10}
            dataKey="id"
            filters={filters}
            filterDisplay="menu"
            loading={loading}
            responsiveLayout="scroll"
            emptyMessage="No se encontraron cotizaciones."
            stripedRows="true"
            onRowClick={(e) => handleRowClick(e.data)}
            rowClassName={rowClassName}
            globalFilterFields={["cliente.razonSocial"]}
            header={header}
          >
            <Column
              field="id"
              header="# Cotizacion"
              filterMenuStyle={{ width: "2rem" }}
              style={{ minWidth: "7rem" }}
              body={(rowData) => rowData.id}
              sortable
              
            />
            <Column
              field="cliente.razonSocial"
              header="Cliente"
              filterMenuStyle={{ width: "14rem" }}
              style={{ minWidth: "7rem" }}
              body={(rowData) => rowData.cliente.razonSocial}
              sortable
              
            />
            <Column
              field="desde"
              header="Desde"
              body={(rowData) => {
                return rowData.desde ? rowData.desde : "No tiene fecha";
              }}
              sortable
            />
            <Column
              field="hasta"
              header="Hasta"
              body={(rowData) => {
                return rowData.hasta ? rowData.hasta : "No tiene fecha";
              }}
              sortable
            />

            <Column
              field="estado.estado"
              header="Estado"
              filterMenuStyle={{ width: "14rem" }}
              style={{ minWidth: "7rem" }}
              body={(rowData) => rowData.estado.estado}
              sortable
            />

            <Column
              header="Acción"
              body={actionBodyTemplate}
              style={{ minWidth: "12rem", textAlign: "center" }}
            />
          </DataTable>
        </>
      )}
    </div>
  );
};

export default CotizacionesTable;
