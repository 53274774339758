import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { AsignacionService } from "../service/AsignacionService";
import { useRef } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useGlobalContext } from "../Config";
import OverviewBox from "../components/OverviewBox";
import FormBusquedaCliente from "../components/FormBusquedaCliente";
import FormBusquedaProductos from "../components/FormBusquedaProductos";
import CotizacionesTable from "../components/CotizacionesTable";
import SubProductosTable from "../components/SubProductosTable";
import ServiciosTable from "../components/ServiciosTable";
import ProductosDisponiblesTable from "../components/ProductosDisponiblesTable";
import ClientesTable from "../components/ClientesTable";
import UseScrollOnStateChange from "../components/UseScrollOnStateChange";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader.js";
import moment from "moment"
const Cotizacion = (props) => {
  // VARIABLES CON STATE //
  const [additionalSelection, setAdditionalSelection] = useState(false);
  const [alias, setAlias] = useState("");
  const [asignaciones, setAsignaciones] = useState(null);
  const [cotizaciones, setCotizaciones] = useState(null);
  const [carro, setCarro] = useState([]);
  const [cliente, setCliente] = useState("");
  const [clienteLocal, setClienteLocal] = useState(false);
  const [clientes, setClientes] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [criterioBusqueda, setCriterioBusqueda] = useState(false);
  const [desde, setDesde] = useState(null);
  const [desdeFiltro, setDesdeFiltro] = useState("");
  const [desdeSub, setDesdeSub] = useState(null);
  const [display2Confirmation, setDisplay2Confirmation] = useState(false);
  const [display3Confirmation, setDisplay3Confirmation] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [displayCreateClient, setDisplayCreateClient] = useState(false);
  const [displayConstruccion, setDisplayConstruccion] = useState(false);
  const [dni, setDni] = useState("");
  const [empresa, setEmpresa] = useState("TODAS");
  const [filters2, setFilters2] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [flattenedDataArriendo, setFlattenedDataArriendo] = useState([]);
  const [flattenedDataArriendoWithTramo, setFlattenedDataArriendoWithTramo] =
    useState([]);
  const [hasta, setHasta] = useState(null);
  const [hastaTramoGeneral, setHastaTramoGeneral] = useState(null);
  const [hastaFiltro, setHastaFiltro] = useState("");
  const [hastaSub, setHastaSub] = useState(null);
  const [isBuscarVisible, setIsBuscarVisible] = useState(false);
  const [isFirstTarifaVisible, setIsFirstTarifaVisible] = useState(false);
  const [isFormBodyVisible, setIsFormBodyVisible] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);
  const [isTarifaVisible, setIsTarifaVisible] = useState(false);
  const [isTramoGenerado, setIsTramoGenerado] = useState(false);
  const [iva, setIva] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [menu1, menu2, menu3, menu4] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [nombreCuadro, setNombreCuadro] = useState("");
  const [nombreFantasia, setNombreFantasia] = useState("");
  const [nombreRepresentante, setNombreRepresentante] = useState("");
  const [productosDisponibles, setProductosDisponibles] = useState([
    {
      id: 1,
      name: "Bodega G11 ",
      empresa: "LA SIEMBRA",
      metraje: "120",
      subproducto: null,
      servicio: null,
      cobro: "Factura Afecta",
      desde: desde,
      hasta: hasta,
      oficina: false,
      baño: true,
      modulo: true,
      remAgua: true,
      remEn: true,
      internet: false,
    },
    {
      id: 2,
      name: "Bodega G23 ",
      empresa: "ALTAMIRA",
      metraje: "400",
      subproducto: null,
      servicio: null,
      cobro: "Factura Afecta",
      desde: desde,
      hasta: hasta,
      oficina: true,
      baño: true,
      modulo: true,
      remAgua: false,
      remEn: false,
      internet: true,
    },
  ]);
  const [razonSocial, setRazonSocial] = useState("");
  const [rowClick, setRowClick] = useState(true);
  const [rut, setRut] = useState("");
  const [seleccionSubProductoAdicional, setSeleccionSubproductoAdicional] =
    useState(false);
  const [selectedServicios, setSelectedServicios] = useState([]);
  const [selectedSubproductos, setSelectedSubproductos] = useState([]);
  const [selectedSubproductosFinales, setSelectedSubproductosFinales] =
    useState([]);
  const [servicio, setServicio] = useState(null);
  const [servicioObraCivil, setServicioObraCivil] = useState([]);
  const [showTramoModalArriendo, setShowTramoModalArriendo] = useState(false);
  const [showTramoModalServicio, setShowTramoModalServicio] = useState(false);
  const [showTramoModalObra, setShowTramoModalObra] = useState(false);
  const [showProductosEncontrados, setShowProductosEncontrados] =
    useState(false);
  const [showSeleccion, setShowSeleccion] = useState(true);
  const [showSubProductosAdicionales, setShowSubProductosAdicionales] =
    useState(false);
  const [subProductoAdicional, setSubProductoAdicional] = useState("");

  const [subtotal, setSubtotal] = useState(0);
  const [subtotalArriendo, setSubtotalArriendo] = useState(0);
  const [subtotalObraCivil, setSubtotalObraCivil] = useState(0);
  const [subtotalServicios, setSubtotalServicios] = useState(0);
  const [tarifaTramo, setTarifaTramo] = useState(0);
  const [tipoProducto, setTipoProducto] = useState("");
  const [total, setTotal] = useState(0);
  const totalSinIva = (
    parseFloat(subtotalArriendo) +
    parseFloat(subtotalObraCivil) +
    parseFloat(subtotalServicios)
  ).toFixed(2);

  const totalConIva = (
    parseFloat(totalSinIva) +
    parseFloat(totalSinIva) * 0.19
  ).toFixed(2);
  const [tramos, setTramos] = useState([]);
  const [tramoCounter, setTramoCounter] = useState(0);
  const [verResumenMensual, setVerResumenMensual] = useState(false);
  // VARIABLES CON STATE //

  // VARIABLES ESTATICAS //
  const estados = ["A", "E"];
  const navigate = useNavigate();

  const empresas = [
    { name: "TODAS", value: "TODAS" },
    { name: "ALTAMIRA", value: "Altamira" },
    { name: "LA SIEMBRA", value: "LA SIEMBRA" },
  ];
  const subproductosAdicionales = [
    { id: 1, name: "TODAS", value: "TODAS" },
    { id: 2, name: "ALTAMIRA", value: "Altamira" },
    { id: 3, name: "LA SIEMBRA", value: "LA SIEMBRA" },
  ];
  const [tiposProducto, setTiposProducto] = useState([
    { id: 1, descripcion: "Bodega", code: "Bodega", cobro: "Factura Exenta" },
    { id: 2, descripcion: "Sitio", code: "Sitio", cobro: "Factura Exenta" },
    { id: 3, descripcion: "Casino", code: "Casino", cobro: "Factura Exenta" },
    {
      id: 4,
      descripcion: "Zona Lavado",
      code: "Zona Lavado",
      cobro: "Factura Exenta",
    },
  ]);
  const newItemServicio = {
    id: 4,
    nombre: "Obra Civil",
    cobro: "Nota de Cobro",
    desde: desde ? desde : null,
    hasta: hasta ? hasta : null,
    incluido: true,
    moneda: "UF",
    contrato: true,
    unidad: "Sin unidad",
    tarifa_base: 0,
  };

  function calculateTarifaMensualWithFechas(item) {
    if (
      item.nombre !== "Gasto Común" &&
      item.nombre !== "Electricidad" &&
      item.nombre !== "Agua"
    ) {
      if (item.desde && item.hasta) {
        const totalDays = Math.ceil(
          (item.hasta.getTime() - item.desde.getTime()) / (1000 * 3600 * 24)
        );
        if (item.tarifa_base > 0) {
          const totalTarifaMensual = parseFloat(
            (item.tarifa_base * totalDays).toFixed(2)
          );
          item.tarifa_diaria = parseFloat((totalTarifaMensual / 30).toFixed(2));

          item.tarifa_mensual = totalTarifaMensual;
        } else {
          item.tarifa_mensual = 0;
          item.tarifa_diaria = 0;
        }
      } else {
        item.tarifa_mensual = 0;
        item.tarifa_diaria = 0;
      }
    }
  }

  function calculateTarifaMensualForFirstTramoWithFechas(item) {
    if (
      item.nombre !== "Gasto Común" &&
      item.nombre !== "Electricidad" &&
      item.nombre !== "Agua"
    ) {
      if (item.desde && item.hasta) {
        const totalDays = Math.ceil(
          (item.hasta.getTime() - item.desde.getTime()) / (1000 * 3600 * 24)
        );
        if (item.tarifa_base > 0) {
          const totalTarifaMensual = parseFloat(
            (item.tarifa_base * (totalDays + 1)).toFixed(2)
          );

          item.tarifa_mensual = totalTarifaMensual;
          item.tarifa_diaria = parseFloat((totalTarifaMensual / 30).toFixed(2));
        } else {
          item.tarifa_mensual = 0;
          item.tarifa_diaria = 0;
        }
      } else {
        item.tarifa_mensual = 0;
        item.tarifa_diaria = 0;
      }
    }
  }
  // TODO
  function calculateTarifaMensualWithMetraje(item) {
    if (
      item.nombre !== "Gasto Común" &&
      item.nombre !== "Electricidad" &&
      item.nombre !== "Agua"
    ) {
      if (item.desde && item.hasta) {
        if (item.tarifa_base > 0) {
          const totalTarifaMensual = parseFloat(
            (item.tarifa_base * item.metraje).toFixed(2)
          );
          item.tarifa_diaria = parseFloat((totalTarifaMensual / 30).toFixed(2));

          item.tarifa_mensual = totalTarifaMensual;
        } else {
          item.tarifa_mensual = 0;
          item.tarifa_diaria = 0;
        }
      } else {
        item.tarifa_mensual = 0;
        item.tarifa_diaria = 0;
      }
    }
  }

  function calculateTarifaMensualForFirstTramoWithMetraje(item) {
    if (
      item.nombre !== "Gasto Común" &&
      item.nombre !== "Electricidad" &&
      item.nombre !== "Agua"
    ) {
      if (item.desde && item.hasta) {
        if (item.tarifa_base > 0) {
          const totalTarifaMensual = parseFloat(
            (item.tarifa_base * item.metraje).toFixed(2)
          );
          item.tarifa_diaria = parseFloat((totalTarifaMensual / 30).toFixed(2));

          item.tarifa_mensual = totalTarifaMensual;
        } else {
          item.tarifa_mensual = 0;
          item.tarifa_diaria = 0;
        }
      } else {
        item.tarifa_mensual = 0;
        item.tarifa_diaria = 0;
      }
    }
  }

  const gastoComunItem = {
    id: 4,
    nombre: "Gasto Común",
    cobro: "Nota de Cobro",
    desde: desde ? desde : null,
    hasta: hasta ? hasta : null,
    tarifa_mensual: "Tarifa Variable",
    incluido: true,
    moneda: "UF",
    contrato: true,
    unidad: "Sin unidad",
    tarifa_base: 0,
    tarifa_diaria: 0,
    color: true,
  };

  const subproductos = [
    {
      id: 1,
      name: "Oficina",
      desde: desde ? desde : null,
      hasta: hasta ? hasta : null,
      cobro: "Factura Exenta",
      moneda: "UF",
      contrato: true,
      unidad: "m²",
      metraje: "20",
      incluido: true,
    },
    {
      id: 2,
      name: "Modulo",
      desde: desde ? desde : null,
      hasta: hasta ? hasta : null,
      cobro: "Factura Exenta",
      moneda: "UF",
      contrato: true,
      unidad: "m²",
      metraje: "20",
      incluido: true,
    },
    {
      id: 3,
      name: "Baño",
      desde: desde ? desde : null,
      hasta: hasta ? hasta : null,
      cobro: "Factura Exenta",
      moneda: "UF",
      contrato: true,
      unidad: "m²",
      metraje: "20",
      incluido: true,
    },
  ];
  const servicios = [
    {
      id: 1,
      nombre: "Internet",
      cobro: "Factura Afecta",
      desde: desde,
      hasta: hasta,
      incluido: false,
      moneda: "UF",
      contrato: true,
      unidad: "Kb/s",
      tarifa_base: 0.012,
      tarifa_diaria: 0,
      color: false,
      tarifa_mensual: 0.36,
    },

    {
      id: 2,
      nombre: "Electricidad",
      cobro: "Factura Exenta",
      desde: desde,
      hasta: hasta,
      incluido: false,
      moneda: "UF",
      contrato: true,
      unidad: "V",
      tarifa_base: 0,
      tarifa_diaria: 0,
      tarifa_mensual: "Tarifa Variable",
      color: true,
    },
    {
      id: 3,
      nombre: "Agua",
      cobro: "Nota de Cobro",
      desde: desde,
      hasta: hasta,
      incluido: false,
      moneda: "UF",
      contrato: true,
      unidad: "L",
      tarifa_base: 0,
      tarifa_diaria: 0,
      tarifa_mensual: "Tarifa Variable",
      color: true,
    },
  ];

  const overviewChartData1 = {
    labels: [
      "Diciembre",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
    ],
    datasets: [
      {
        data: [50, 64, 32, 24, 18, 27, 20, 36, 30],
        borderColor: ["#4DD0E1"],
        backgroundColor: ["rgba(77, 208, 225, 0.8)"],
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };
  const overviewChartData2 = {
    labels: [
      "Diciembre",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
    ],
    datasets: [
      {
        data: [11, 30, 52, 35, 39, 20, 14, 18, 60],
        borderColor: ["#4DD0E1"],
        backgroundColor: ["rgba(77, 208, 225, 0.8)"],
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const overviewChartData3 = {
    labels: [
      "Diciembre",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
    ],
    datasets: [
      {
        data: [20, 29, 39, 36, 45, 24, 28, 20, 15],
        borderColor: ["#4DD0E1"],
        backgroundColor: ["rgba(77, 208, 225, 0.8)"],
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const overviewChartData4 = {
    labels: [
      "Diciembre",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
    ],
    datasets: [
      {
        data: [30, 39, 50, 21, 33, 18, 10, 24, 20],
        borderColor: ["#4DD0E1"],
        backgroundColor: ["rgba(77, 208, 225, 0.8)"],
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };
  const overviewChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    tooltips: {
      enabled: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  const descripcionSubProductos = [
    { name: "Oficina" },
    { name: "Baño" },
    { name: "Ducha" },
  ];
  // VARIABLES ESTATICAS //

  // VARIABLES GLOBALES //

  const { baseUrl } = useGlobalContext();
  const chart1 = useRef(null);
  const op = useRef(null);
  const toast = useRef();
  const asignacionService = new AsignacionService();

  // VARIABLES GLOBALES //

  // VARIABLES DINAMICAS//

  useEffect(() => {
    const currentCarroItem = carro[currentIndex];

    if (!currentCarroItem) {
      return;
    }

    const selectedSubproductosArray = [];
    const selectedServiciosArray = [];
    const selectedObraCivilArray = [];

    const gastoComunIncluded = selectedServiciosArray.find(
      (item) => item.name === "Gasto Común"
    );
    if (!gastoComunIncluded) {
      selectedServiciosArray.push(gastoComunItem);
    }
    if (currentCarroItem.oficina) {
      const selectedSubproducto = { ...subproductos[0], incluido: true };
      selectedSubproductosArray.push(selectedSubproducto);
    }
    if (currentCarroItem.modulo) {
      const selectedSubproducto = { ...subproductos[1], incluido: true };
      selectedSubproductosArray.push(selectedSubproducto);
    }
    if (currentCarroItem.bano) {
      const selectedSubproducto = { ...subproductos[2], incluido: true };
      selectedSubproductosArray.push(selectedSubproducto);
    }
    if (currentCarroItem.internet) {
      selectedServiciosArray.push(servicios[0]);
    }
    if (currentCarroItem.electricidad) {
      selectedServiciosArray.push(servicios[1]);
    }
    if (currentCarroItem.agua) {
      selectedServiciosArray.push(servicios[2]);
    }

    setSelectedServicios(selectedServiciosArray);
    setSelectedSubproductos(selectedSubproductosArray);
    setServicioObraCivil(selectedObraCivilArray);
  }, [carro, currentIndex]);
  const usuarioId = sessionStorage.getItem("usuario_id");

  const buildCotizacionesUrl = (usuarioId) => {
    return `${baseUrl}getCotizacionesByEstados?idUsuario=${usuarioId}&estados=${[
      "1",
      "2",
    ]}`;
  };
  const getCotizaciones = async () => {
    try {
      setLoading(true);
      setLoading2(true);

      const url = buildCotizacionesUrl(usuarioId);
      const data = await fetchData(url);
      if (Array.isArray(data.cotizaciones)) {
        setCotizaciones(data.cotizaciones);
        setLoading(false);
        setLoading2(false);
      } else {
        console.error("Data.cotizaciones no es array:", data.cotizaciones);
        setLoading2(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getCotizaciones();
    setOverviewColors();
    initFilters2();
    calculateTarifaMensualWithFechas(newItemServicio);
    servicios.forEach(calculateTarifaMensualWithFechas);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const calculateSubtotal = (data) => {
    let subtotal = 0;

    for (let i = 0; i < data.length; i++) {
      const rowData = data[i];

      if (
        rowData &&
        rowData.tarifa_mensual !== undefined &&
        rowData.tarifa_mensual !== "Tarifa Variable"
      ) {
        const currentNombre = rowData.nombre;
        const currentTarifaMensual = parseFloat(rowData.tarifa_mensual);

        const nextRow = data[i + 1];
        const nextNombre = nextRow ? nextRow.nombre : null;

        if (currentNombre === nextNombre) {
          continue;
        }

        subtotal += isNaN(currentTarifaMensual) ? 0 : currentTarifaMensual;
      }
    }

    return subtotal.toFixed(2);
  };

  useEffect(() => {
    const subtotalArriendo = calculateSubtotal(flattenedDataArriendo);
    setSubtotalArriendo(subtotalArriendo);
  }, [flattenedDataArriendo]);

  useEffect(() => {
    const subtotalServicios = calculateSubtotal(selectedServicios);
    setSubtotalServicios(subtotalServicios);
  }, [selectedServicios]);

  useEffect(() => {
    const subtotalObraCivil = calculateSubtotal(servicioObraCivil);
    setSubtotalObraCivil(subtotalObraCivil);
  }, [servicioObraCivil]);

  useEffect(() => {
    const calculatedSubtotal =
      parseFloat(subtotalArriendo) +
      parseFloat(subtotalServicios) +
      parseFloat(subtotalObraCivil);

    const calculatedIva = calculatedSubtotal * 0.19;

    const calculatedTotal = calculatedSubtotal + calculatedIva;

    setSubtotal(calculatedSubtotal.toFixed(2));
    setIva(calculatedIva.toFixed(2));
    setTotal(calculatedTotal.toFixed(2));
  }, [subtotalArriendo, subtotalServicios, subtotalObraCivil]);
  // VARIABLES DINAMICAS //

  // VARIABLES ASYNC //
  const toggleBodyFormVisibility = async () => {
    searchProductos();
  };
  const fetchData = async (url) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

   

    return response.json();
  };
  const searchProductos = async () => {
    try {
      if (!isValidFiltrosProductos()) {
        return;
      }

      resetCarro();

      const empresasArray = mapEmpresas(empresa);
      const tiposArray = mapTipos(tipoProducto);
      const { desdeFiltro, hastaFiltro } = mapDateFilters(desde, hasta);

      setLoading(true);

      const url = buildProductosUrl(
        empresasArray,
        desdeFiltro,
        hastaFiltro,
        tiposArray
      );
      const data = await fetchData(url);

      handleProductosResponse(data);
    } catch (error) {
      console.error(error);
    }
  };

  const crearCliente = async () => {
    try {
      if (!checkFormClientes()) {
        let alertMessage = "Debe completar toda la información para el nuevo cliente";
        if (!isValid) {
          alertMessage += " y agregar un RUT válido";
        }
        alert(alertMessage);
        return;
      }
  
      setLoading(true);
      const url = buildCrearClienteUrl();
  
      const data = await fetchData(url);
      handleCrearClienteResponse(data);
    } catch (error) {
      console.error(error);
    }
  };

  UseScrollOnStateChange(clientes, "clientesDataTable");
  UseScrollOnStateChange(isSearchFormVisible, "clienteSeleccionado");
  UseScrollOnStateChange(showSeleccion, "cuadroPago");
  UseScrollOnStateChange(!showSeleccion, "subproductosDataTable");

  const handleProductosResponse = (data) => {
    if (Array.isArray(data.productos)) {
      setProductosDisponibles(data.productos);

      setShowProductosEncontrados(true);
      setLoading(false);
    } else {
      console.error("Data.clientes no es array:", data.clientes);
    }
  };
  const accept = () => {
    toast.current.show({
      severity: "success",
      summary: "Confirmado",
      detail: "Cliente Creado Correctamente",
      life: 5000,
    });
  };
  const reject = () => {
    toast.current.show({
      severity: "error",
      summary: "Rejected",
      detail: "Hubo un error al crear el cliente",
      life: 3000,
    });
  };
  const rejectRutExists = () => {
    toast.current.show({
      severity: "error",
      summary: "Rechazado",
      detail: "El RUT del cliente ya existe",
      life: 3000,
    });
  };
  const handleCrearClienteResponse = (data) => {
    const response = data;
    if (response.mensaje === "Cliente creado correctamente") {
      clearVariablesNuevoCliente();
      clearVariablesNuevoContacto();
      clearVariablesNuevoRepresentante();
      clearVariablesNuevoDirecciones();
      setDisplayCreateClient(false);
      setFiltersApplied(false);
      accept();
    } else if (response.mensaje === "El rut del cliente ya existe") {
      rejectRutExists();
      throw new Error("La respuesta fue errónea: " + response.mensaje);
    } else {
      reject();
      throw new Error("La respuesta fue errónea: " + response.mensaje);
    }
  };

  const isValidFiltrosProductos = () => {
    if (!checkCabeceraFiltrosProductos()) {
      return false;
    }
    return true;
  };

  const resetCarro = () => {
    setCarro([]);
  };

  const mapEmpresas = (selectedEmpresa) => {
    const empresaMapping = {
      TODAS: ["2", "3"],
      ALTAMIRA: ["2"],
      "LA SIEMBRA": ["3"],
    };
    return empresaMapping[selectedEmpresa.toUpperCase()] || [];
  };

  const mapTipos = (tipoProductoArray) => {
    return tipoProductoArray.map((tipo) => tipo.descripcion);
  };

  const mapDateFilters = (desde, hasta) => {
    const dateObjectDesde = new Date(desde);
    const dateObjectHasta = new Date(hasta);
    const desdeFiltro = dateObjectDesde.toISOString().split("T")[0];
    const hastaFiltro = dateObjectHasta.toISOString().split("T")[0];
    setDesdeFiltro(desdeFiltro);
    setHastaFiltro(hastaFiltro);

    return { desdeFiltro, hastaFiltro };
  };

  const buildProductosUrl = (
    empresasArray,
    desdeFiltro,
    hastaFiltro,
    tiposArray
  ) => {
    return `${baseUrl}getProductosByFiltro?empresas=${empresasArray}&fechaInicio=${desdeFiltro}&fechaTermino=${hastaFiltro}&tipos=${tiposArray}`;
  };

  const buildCrearClienteUrl = () => {
    return `${baseUrl}crearCliente?idEmpresa=2&idTipoCliente=${
      encodeURIComponent(nuevoTipo.id)
    }&rut=${encodeURIComponent(rut)}&razonSocial=${encodeURIComponent(
      razonSocial
    )}&alias=${encodeURIComponent(alias)}&nombreFantasia=${encodeURIComponent(
      nombreFantasia
    )}&contactos=${encodeURIComponent(JSON.stringify(nuevoContactos))}&domicilios=${encodeURIComponent(
      JSON.stringify(nuevoDirecciones)
    )}&representantesLegales=${encodeURIComponent(JSON.stringify(nuevoRepresentantes))}`;
  };
  

  const searchCliente = async () => {
    try {
      if (!isValidFiltrosCliente()) {
        alert("Debe ingresar al menos un parámetro de búsqueda");
        return;
      }

      setLoading(true);
      updateUIVisibility();
      setLoading(true);
      setFiltersApplied(true);
      const url = buildClienteUrl();
      const data = await fetchData(url);
      handleClienteResponse(data);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const isValidFiltrosCliente = () => {
    return checkCabeceraFiltros();
  };

  const updateUIVisibility = () => {
    setIsSearchFormVisible(false);
    setIsTarifaVisible(false);
    setAdditionalSelection(false);
    setCarro("");
  };

  const buildClienteUrl = () => {
    return filtersClienteUrl();
  };

  const handleClienteResponse = (data) => {
    if (Array.isArray(data.clientes)) {
      setClientes(data.clientes);
    } else {
      console.error("Data.clientes no es array:", data.clientes);
    }
  };

  const searchClienteByRepresentante = async () => {
    try {
      if (!validateRepresentanteFiltros()) {
        alert("Debe ingresar el nombre del representante");
        return;
      }

      setLoading(true);
      hideSearchForm();

      const url = buildRepresentanteSearchUrl();

      const data = await fetchData(url);
      processClienteResponse(data);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const validateRepresentanteFiltros = () => {
    return checkCabeceraFiltrosRepresentante();
  };

  const hideSearchForm = () => {
    setIsSearchFormVisible(false);
  };

  const buildRepresentanteSearchUrl = () => {
    return representanteUrl();
  };

  const processClienteResponse = (data) => {
    if (Array.isArray(data.clientes)) {
      setClientes(data.clientes);
      setFiltersApplied(true);
    } else {
      console.error("Data.clientes no es array:", data.clientes);
    }
  };

  // VARIABLES ASYNC //

  const handleConfirmarItem = () => {
    const carroItem = {
      ...carro[currentIndex],
      nombre:
        carro[currentIndex].tipo_producto +
        " " +
        carro[currentIndex].nombre_producto,
      desde: desde,
      hasta: hasta,
      cobro: "Factura Exenta",
      incluido: true,
      moneda: "UF",
      contrato: true,
      unidad: "m²",
      metraje: carro[currentIndex].metraje_producto,
      tarifa_base: 0.4,
    };
    const selectedSubproductosArray = selectedSubproductos.map(
      (subproducto) => {
        if ("desde" in subproducto && "hasta" in subproducto) {
          return {
            ...subproducto,
            nombre: subproducto.name,
            desde: subproducto.desde || desde,
            hasta: subproducto.hasta || hasta,
            tarifa_base: 0.04,
          };
        }

        return {
          ...subproducto,
          nombre: subproducto.name,
          desde: subproducto.desde || desde,
          hasta: subproducto.hasta || hasta,
          tarifa_base: 0.04,
        };
      }
    );
    const combinedArray = [carroItem, ...selectedSubproductosArray];
    combinedArray.forEach(calculateTarifaMensualWithMetraje);
    if (flattenedDataArriendoWithTramo.length !== 0) {
      const updatedFlattenedDataArriendo = flattenedDataArriendoWithTramo.map(
        (item) => {
          const matchingItem = combinedArray.find(
            (combinedItem) => combinedItem.nombre === item.nombre
          );

          if (matchingItem) {
            return item;
          }

          return item;
        }
      );

      const uniqueItems = combinedArray.filter((combinedItem) =>
        flattenedDataArriendoWithTramo.every(
          (existingItem) => combinedItem.nombre !== existingItem.nombre
        )
      );

      setFlattenedDataArriendo([
        ...updatedFlattenedDataArriendo,
        ...uniqueItems,
      ]);
    } else {
      setFlattenedDataArriendo(combinedArray);
    }

    setShowSeleccion(!showSeleccion);
  };
  const handleTramoConfirmationArriendo = (nombre) => {
    const flattenedDataArriendoCopy = [...flattenedDataArriendo];
    const indexToRemove = flattenedDataArriendoCopy.findIndex(
      (item) => item.nombre === nombre
    );

    if (indexToRemove !== -1) {
      flattenedDataArriendoCopy.splice(indexToRemove, 1);

      flattenedDataArriendoCopy.push(...tramos);

      const matchingItems = flattenedDataArriendoCopy.filter(
        (item) => item.nombre === nombre
      );

      matchingItems.forEach((item, index) => {
        item.tramo = `Tramo ${index + 1}`;
      });
      const totalItems = matchingItems.length;

      matchingItems.forEach((item, index) => {
        if (index === totalItems - 1) {
          calculateTarifaMensualWithMetraje(item);
        } else {
          calculateTarifaMensualForFirstTramoWithMetraje(item);
        }
      });

      flattenedDataArriendoCopy.sort((a, b) => {
        const nombreComparison = a.nombre.localeCompare(b.nombre);
        if (nombreComparison !== 0) {
          return nombreComparison;
        }

        return new Date(a.desde) - new Date(b.desde);
      });

      setFlattenedDataArriendo(flattenedDataArriendoCopy);
      setShowTramoModalArriendo(false);
    }
  };

  const handleTramoConfirmationServicio = (nombre) => {
    const selectedServiciosCopy = [...selectedServicios];
    const indexToRemove = selectedServiciosCopy.findIndex(
      (item) => item.nombre === nombre
    );

    if (indexToRemove !== -1) {
      selectedServiciosCopy.splice(indexToRemove, 1);

      selectedServiciosCopy.push(...tramos);
      const matchingItems = selectedServiciosCopy.filter(
        (item) => item.nombre === nombre
      );
      matchingItems.forEach((item, index) => {
        item.tramo = `Tramo ${index + 1}`;
      });
      const totalItems = matchingItems.length;

      selectedServiciosCopy.sort((a, b) => {
        const nombreComparison = a.nombre.localeCompare(b.nombre);
        if (nombreComparison !== 0) {
          return nombreComparison;
        }

        return new Date(a.desde) - new Date(b.desde);
      });

      setSelectedServicios(selectedServiciosCopy);
      setShowTramoModalServicio(false);
    }
  };

  const handleTramoConfirmationObra = (nombre) => {
    const servicioObraCivilCopy = [...servicioObraCivil];
    const indexToRemove = servicioObraCivilCopy.findIndex(
      (item) => item.nombre === nombre
    );

    if (indexToRemove !== -1) {
      servicioObraCivilCopy.splice(indexToRemove, 1);

      servicioObraCivilCopy.push(...tramos);
      const matchingItems = servicioObraCivilCopy.filter(
        (item) => item.nombre === nombre
      );

      matchingItems.forEach((item, index) => {
        item.tramo = `Tramo ${index + 1}`;
      });
      const totalItems = matchingItems.length;

      matchingItems.forEach((item, index) => {
        if (index === totalItems - 1) {
          calculateTarifaMensualWithFechas(item);
        } else {
          calculateTarifaMensualForFirstTramoWithFechas(item);
        }
      });

      servicioObraCivilCopy.sort((a, b) => {
        const nombreComparison = a.nombre.localeCompare(b.nombre);
        if (nombreComparison !== 0) {
          return nombreComparison;
        }

        return new Date(a.desde) - new Date(b.desde);
      });

      setServicioObraCivil(servicioObraCivilCopy);
      setShowTramoModalServicio(false);
    }
  };

  const atrasSeleccion = () => {
    setFlattenedDataArriendoWithTramo(flattenedDataArriendo);
    setShowSeleccion(!showSeleccion);
  };

  const handleDoneClick = () => {
    setCarro((prevCarro) => {
      const updatedCarro = [...prevCarro];
      const updatedItem = {
        subproducto: selectedSubproductos,
        servicio: selectedServicios,
      };
      updatedCarro[currentIndex] = updatedItem;

      setShowSeleccion(!showSeleccion);
      return updatedCarro;
    });
    setCurrentIndex((prevIndex) => prevIndex + 1);
    clearTramosVariables();
    setFlattenedDataArriendoWithTramo([]);
  };
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const selectSubProductsAndServices = () => {
    if (carro.length <= 0) {
      alert("Seleccione al menos un producto");
    } else {
      const selectedSubproductosArray = [];
      const selectedServiciosArray = [];
      const selectedObraCivilArray = [];

      const gastoComunIncluded = selectedServiciosArray.find(
        (item) => item.name === "Gasto Común"
      );

      if (!gastoComunIncluded) {
        selectedServiciosArray.push(gastoComunItem);
      }
      if (carro[currentIndex].oficina) {
        selectedSubproductosArray.push(subproductos[0]);
      }
      if (carro[currentIndex].modulo) {
        selectedSubproductosArray.push(subproductos[1]);
      }
      if (carro[currentIndex].bano) {
        selectedSubproductosArray.push(subproductos[2]);
      }
      if (carro[currentIndex].internet) {
        selectedServiciosArray.push(servicios[0]);
      }
      if (carro[currentIndex].electricidad) {
        selectedServiciosArray.push(servicios[1]);
      }
      if (carro[currentIndex].agua) {
        selectedServiciosArray.push(servicios[2]);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });

      setSelectedServicios(selectedServiciosArray);
      setSelectedSubproductos(selectedSubproductosArray);
      setServicioObraCivil(selectedObraCivilArray);
      setAdditionalSelection(!additionalSelection);
    }
  };

  const generarTramo = () => {
    setIsTramoGenerado(!isTramoGenerado);
    setDisplay3Confirmation(false);
  };
  const buildCrearCotizacionUrl = () => {
    let usuarioId = sessionStorage.getItem("usuario_id");
    return `${baseUrl}crearCotizacion?id_cliente=${cliente.id}&id_creador=${usuarioId}&comentario=`;
  };
  const crearCotizacion = async () => {
    try {
      setLoading(true);
      const crearCotizacionUrl = buildCrearCotizacionUrl();

      const data = await fetchData(crearCotizacionUrl);
      if (!data.respuesta) {
        alert(data.mensaje);
      } else {
        const newUrl = `/selectProductos?idCotizacion=${data.idCotizacion}&idCliente=${cliente.id}&selected=true&activeIndex=1`;
        navigate(newUrl);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
      } else {
        alert("Hubo un error al crear la cotización");
      }
    }
  };
  const buildDetalleCotizacionUrl = (idCotizacion) => {
    return `${baseUrl}getDetalleCotizacionByIdCotizacion?idCotizacion=${idCotizacion}`;
  };
  const handleRowClick = async (rowData) => {
    const idCotizacion = rowData.id;
  
    // Function to format date, ensuring the output is always in 'yyyy-mm-dd'
    const formatDate = (date, inputFormat) => {
      const parsedDate = moment(date, inputFormat);
      return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : '';
    };
  
    // Format the 'desde' and 'hasta' properties using the formatDate function
    const formattedDesde = formatDate(rowData.desde, 'DD/MM/YYYY');
    const formattedHasta = formatDate(rowData.hasta, 'DD/MM/YYYY');
  
    
  
    if (rowData.estado.estado === "INGRESADA") {
      let newUrl = `/selectProductos?idCotizacion=${idCotizacion}&idCliente=${rowData.cliente.id}&selected=true&activeIndex=1`;
      navigate(newUrl);
    } else {
      try {
        setLoading(true);
        const detalleCotizacionUrl = buildDetalleCotizacionUrl(idCotizacion);
  
        const data = await fetchData(detalleCotizacionUrl);
  
        if (data.mensaje === "OK") {
          const encodedParams = data.detalles.map(item => {
            // Omit 'desde' and 'hasta' properties
            const { ...rest } = item;
            // Convert the object to a URL parameter string
            return encodeURIComponent(JSON.stringify(rest));
          }).join(',');
  
          let newUrl = `/revisarCotizacion?idCliente=${rowData.cliente.id}&idCotizacion=${idCotizacion}&desde=${formattedDesde}&hasta=${formattedHasta}`;
          navigate(newUrl);
        } else {
          alert(data.mensaje);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert("Hubo un error al crear la cotización");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleSearchFormVisibility = () => {
    setDisplayConfirmation(false);
    crearCotizacion();
  };
  const calcularFirstTarifa = () => {
    setIsFirstTarifaVisible(!isFirstTarifaVisible);
  };

  const toggleBuscarVisibility = () => {
    setIsBuscarVisible(!isBuscarVisible);
  };
  const cancelarButton = () => {
    setEmpresa("");
    setTipoProducto("");
    setDesde(null);
    setHasta(null);
    setCarro("");
    setServicio("");
    setIsFormVisible(false);
    setIsFormBodyVisible(false);
    setIsTarifaVisible(false);
    setIsSearchFormVisible(false);
    setIsTramoGenerado(false);
    setDisplayConfirmation(false);
    setDisplay3Confirmation(false);
    setDisplay2Confirmation(false);
    setAdditionalSelection(false);
  };
  const getOverviewColors = () => {
    const isLight = props.colorMode === "light";
    return {
      pinkBorderColor: isLight ? "#E91E63" : "#EC407A",
      pinkBgColor: isLight ? "#F48FB1" : "#F8BBD0",
      tealBorderColor: isLight ? "#009688" : "#26A69A",
      tealBgColor: isLight ? "#80CBC4" : "#B2DFDB",
    };
  };
  const setOverviewColors = () => {
    const { pinkBorderColor, pinkBgColor, tealBorderColor, tealBgColor } =
      getOverviewColors();

    overviewChartData1.datasets[0].borderColor[0] = tealBorderColor;
    overviewChartData1.datasets[0].backgroundColor[0] = tealBgColor;

    overviewChartData2.datasets[0].borderColor[0] = tealBorderColor;
    overviewChartData2.datasets[0].backgroundColor[0] = tealBgColor;

    overviewChartData3.datasets[0].borderColor[0] = pinkBorderColor;
    overviewChartData3.datasets[0].backgroundColor[0] = pinkBgColor;

    overviewChartData4.datasets[0].borderColor[0] = tealBorderColor;
    overviewChartData4.datasets[0].backgroundColor[0] = tealBgColor;
  };

  const initFilters2 = () => {
    setFilters2({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      cliente: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      empresa: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      servicio: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      ubicacion: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      producto: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      desde: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      hasta: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      monto: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      estado: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
  };

  const checkCabeceraFiltrosProductos = () => {
    const basicChecks =
      empresa !== null &&
      empresa !== undefined &&
      empresa !== "" &&
      tipoProducto !== null &&
      tipoProducto !== undefined &&
      tipoProducto !== "" &&
      desde !== null &&
      desde !== undefined &&
      hasta !== null &&
      hasta !== undefined &&
      hasta !== "";

    const dateChecks = new Date(hasta) >= new Date(desde);

    const currentDate = new Date();
    const currentDateChecks =
      new Date(desde) >= currentDate && new Date(hasta) >= currentDate;

    const isDateValid = dateChecks && currentDateChecks;
    const isBasicValid = basicChecks;
    const isValid = isDateValid && isBasicValid;

    if (!isBasicValid) {
      alert("Debe ingresar todos los parámetros de búsqueda");
    }
    if (!isDateValid && isBasicValid) {
      alert(
        "Las fechas deben ser mayores a la de hoy y la fecha de término debe ser mayor o igual a la de inicio"
      );
    }

    return isValid;
  };

  const checkCabeceraFiltros = () => {
    return (
      (rut !== null && rut !== undefined && rut !== "") ||
      (razonSocial !== null &&
        razonSocial !== undefined &&
        razonSocial !== "") ||
      (nombreFantasia !== null &&
        nombreFantasia !== undefined &&
        nombreFantasia !== "") ||
      (alias !== null && alias !== undefined && alias !== "")
    );
  };
  const checkCabeceraFiltrosRepresentante = () => {
    return (
      (nombreRepresentante !== undefined && nombreRepresentante !== "") || true
    );
  };
  const filtersClienteUrl = () => {
    return `${baseUrl}getClientesByFiltro?rut=${rut}&razonSocial=${razonSocial}&nombreFantasia=${nombreFantasia}&alias=${alias}`;
  };

  const representanteUrl = () => {
    return `${baseUrl}getClientesByRepresentante?nombre=${nombreRepresentante}`;
  };
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Confirmada",
      detail: "La Asignación ha sido guardada con éxito",
      life: 4000,
    });
    cancelarButton();
  };

  const handleShowSubProductosAdicionales = () => {
    setShowSubProductosAdicionales(true);
  };
  const handleSubProductoAdicional = () => {
    const selectedSubproductosCopy = [...selectedSubproductos];
    const selectedServiciosCopy = [...selectedServicios];
    const obraCivilServicio = [...servicioObraCivil];

    selectedSubproductosFinales.forEach((item) => {
      const lastItem = selectedSubproductos[selectedSubproductos.length - 1];

      const newItem = {
        caracteristica: "Caracteristica",
        cobro: "Factura Exenta",
        id: lastItem.id + 1,
        incluido: false,
        nombre: `${subProductoAdicional.name} ${item.id}`,
        name: `${subProductoAdicional.name} ${item.id}`,
        desde: desdeSub,
        hasta: hastaSub,
        unidad: "m²",
        metraje: 20,
        contrato: true,
        moneda: "UF",
        tarifa_base: 0.08,
        tarifa_diaria: 0,
        tarifa_mensual: 0,
      };
      calculateTarifaMensualWithMetraje(newItem);
      selectedSubproductosCopy.push(newItem);
    });

    const obraCivilIncluded = servicioObraCivil.find(
      (item) => item.nombre === "Obra Civil"
    );

    if (!obraCivilIncluded) {
      calculateTarifaMensualWithFechas(newItemServicio);
      obraCivilServicio.push(newItemServicio);
    }
    const gastoComunIncluded = selectedServiciosCopy.find(
      (item) => item.nombre === "Gasto Común"
    );

    if (!gastoComunIncluded) {
      calculateTarifaMensualWithFechas(gastoComunItem);
      selectedServiciosCopy.push(gastoComunItem);
    }

    setSelectedSubproductos(selectedSubproductosCopy);
    setSelectedServicios(selectedServiciosCopy);
    setServicioObraCivil(obraCivilServicio);
    setSeleccionSubproductoAdicional(false);
  };

  const handleSeleccionarAdicional = () => {
    setSelectedSubproductosFinales([]);
    setSeleccionSubproductoAdicional(true);
  };

  const handleSubproductoSelectionChange = (e) => {
    setSelectedSubproductosFinales(e.value);
  };

  // TODO
  const handleServicioSelectionChange = (e) => {
    setSelectedServicios(e.value);
  };

  const handleClienteLocal = (e) => {
    setClienteLocal(e.value);
    setRut("");
    setDni("");
  };
  const hanldeCriterioBusqueda = (e) => {
    setCriterioBusqueda(e.value);
    setNombreRepresentante("");
  };

  const getAsignaciones = (data) => {
    return [...(data || [])].map((d) => {
      d.desde = new Date(d.desde);
      d.hasta = new Date(d.hasta);
      return d;
    });
  };

  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const desdeBodyTemplate = (rowData) => {
    return formatDate(rowData.desde);
  };
  const hastaBodyTemplate = (rowData) => {
    return formatDate(rowData.hasta);
  };
  const clearTramosVariables = () => {
    setTramos([]);
    setTramoCounter(0);
  };
  const handleNewTramoArriendo = (rowData) => {
    clearTramosVariables();
    setNombreCuadro(rowData.nombre);
    const tramo1 = { ...rowData };
    const tramo2 = { ...rowData };
    setHastaTramoGeneral(rowData.hasta);
    setTarifaTramo(rowData.tarifa_base);
    setTramos([tramo1, tramo2]);

    setShowTramoModalArriendo(true);
  };

  const handleNewTramoServicio = (rowData) => {
    clearTramosVariables();
    setNombreCuadro(rowData.nombre);
    const tramo1 = { ...rowData };
    const tramo2 = { ...rowData };
    setHastaTramoGeneral(rowData.hasta);
    setTramos([tramo1, tramo2]);

    setShowTramoModalServicio(true);
  };
  const [resumenMensual, setResumenMensual] = useState([]);

  const handleShowResumenMensual = () => {
    // const chargesArrays = [flattenedDataArriendo, selectedServicios];
    // const combinedMonthlyCharges = {};
    // chargesArrays.forEach((charges) => {
    //   const monthlyCharges = calculateMonthlyCharges(charges);
    //   Object.keys(monthlyCharges).forEach((key) => {
    //     if (!combinedMonthlyCharges[key]) {
    //       combinedMonthlyCharges[key] = 0;
    //     }
    //     combinedMonthlyCharges[key] += monthlyCharges[key];
    //   });
    // });
    // Object.keys(combinedMonthlyCharges).forEach((key) => {
    //   combinedMonthlyCharges[key] = parseFloat(
    //     combinedMonthlyCharges[key].toFixed(2)
    //   );
    // });
    // setResumenMensual(combinedMonthlyCharges);
    // setVerResumenMensual(true);
  };

  function calculateMonthlyCharges(charges) {
    const monthlyCharges = {};

    // charges.forEach((charge) => {
    //   const startDate = new Date(charge.desde);
    //   const endDate = new Date(charge.hasta);

    //   let currentDate = new Date(startDate);

    //   while (currentDate <= endDate) {
    //     const currentMonthKey = currentDate.toISOString().slice(0, 7);
    //     const daysInMonth = new Date(
    //       currentDate.getFullYear(),
    //       currentDate.getMonth() + 1,
    //       0
    //     ).getDate();
    //     const daysInPeriod = Math.min(
    //       daysInMonth - currentDate.getDate() + 1,
    //       Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
    //     );

    //     const dailyCharge = charge.tarifa_diaria;
    //     const monthlyCharge = dailyCharge * daysInPeriod;

    //     if (!monthlyCharges[currentMonthKey]) {
    //       monthlyCharges[currentMonthKey] = 0;
    //     }

    //     monthlyCharges[currentMonthKey] += monthlyCharge;

    //     currentDate.setDate(1);
    //     currentDate.setMonth(currentDate.getMonth() + 1);
    //   }
    // });

    // Object.keys(monthlyCharges).forEach((key) => {
    //   monthlyCharges[key] = parseFloat(monthlyCharges[key].toFixed(2));
    // });

    return monthlyCharges;
  }

  const handleNewTramoObra = (rowData) => {
    clearTramosVariables();
    setNombreCuadro(rowData.nombre);
    const tramo1 = { ...rowData };
    const tramo2 = { ...rowData };
    setHastaTramoGeneral(rowData.hasta);
    setTramos([tramo1, tramo2]);

    setShowTramoModalObra(true);
  };
  const selectFechaTramo = (index, newHasta) => {
    let updatedTramos = [...tramos];
    const currentTramo = updatedTramos[index];
    const oneDayInMillis = 24 * 60 * 60 * 1000;

    const isFirstTramoValid = () =>
      newHasta.getTime() !== hastaTramoGeneral.getTime() &&
      newHasta.getTime() !== currentTramo.hasta.getTime() &&
      newHasta.getTime() > currentTramo.desde.getTime();

    const isLastTramoValid = () =>
      tramoCounter === tramos.length - 1 &&
      newHasta.getTime() < hastaTramoGeneral.getTime();

    if (index === 0) {
      if (isFirstTramoValid()) {
        const secondTramo = updatedTramos[index + 1];
        currentTramo.hasta = newHasta;
        secondTramo.desde = new Date(newHasta.getTime() + oneDayInMillis);
        updatedTramos = [currentTramo, secondTramo];
        setTramos(updatedTramos);
        setTramoCounter((prevCounter) => prevCounter + 1);
      } else {
        alert("no es valido");
      }
    } else if (isLastTramoValid()) {
      currentTramo.hasta = newHasta;
      const newTramo = {
        ...currentTramo,
        desde: new Date(newHasta.getTime() + oneDayInMillis),
        hasta: hastaTramoGeneral,
      };
      updatedTramos = updatedTramos.map((tramo, i) =>
        i === index ? currentTramo : tramo
      );
      updatedTramos.push(newTramo);
      setTramoCounter((prevCounter) => prevCounter + 1);
      setTramos(updatedTramos);
    } else {
      alert("aqui termina el ciclo");
    }
  };

  const estadoBodyTemplate = (rowData) => {
    return (
      <span className={`customer-badge status-${rowData.estado}`}>
        {rowData.estado}
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-button mr-2 mb-2 mt-2"
          onClick={() => handleRowClick(rowData)}
          outlined
        />
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button p-button mr-2 mb-2 mt-2"
          onClick={() => handleAction2(rowData.id)}
          outlined
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger p-button mr-2 mb-2 mt-2"
          onClick={() => handleAction3(rowData.id)}
          outlined
        />
      </div>
    );
  };

  const handleSelectCliente = (rowData) => {
    setCliente(rowData);
    setDisplayConfirmation(true);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const estadoFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={estados}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };


  const handleAction2 = (rowId) => {};

  const handleAction3 = (rowId) => {};

  const toggle = (event) => {
    op.current.toggle(event);
  };

  const confirmationDialogFooter = (
    <>
      <Button
        type="button"
        label="Sí"
        icon="pi pi-check"
        onClick={toggleSearchFormVisibility}
        className="p-button-text"
        autoFocus
      />
      <Button
        type="button"
        label="No"
        icon="pi pi-times"
        onClick={() => setDisplayConfirmation(false)}
        className="p-button-text"
      />
    </>
  );




  const confirmationDialogFooterCliente = (
    <>
      <Button
        type="button"
        label="Guardar"
        icon="pi pi-save"
        onClick={crearCliente}
        className="p-button"
        autoFocus
      />
      <Button
        type="button"
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => setDisplayCreateClient(false)}
        className="p-button-danger"
      />
    </>
  );

  const formatMonth = (dateString) => {
    const [year, month] = dateString.split("-");
    const date = new Date(year, month - 1);
    const options = { month: "long" };
    return date.toLocaleDateString(undefined, options);
  };

 
  const allData = Object.entries(resumenMensual).map(([date, value]) => ({
    id: date,
    formattedMonth: formatMonth(date),
    value: `${value} UF`,
  }));
  const matrixData = {};

  allData.forEach(({ id, value }) => {
    const [year, month] = id.split("-").map(Number);

    if (!matrixData[year]) {
      matrixData[year] = {};
    }

    matrixData[year][month] = value;
  });

  

  const months = Object.keys(
    allData.reduce((acc, { id }) => {
      const [year, month] = id.split("-").map(Number);
      acc[month] = true;
      return acc;
    }, {})
  );

  const sortedMonths = months.sort((a, b) => a - b);

  ////////////// Form para crear nuevos clientes //////////////
  const [nuevoCliente, setNuevoCliente] = [
    {
      nuevoTipo: "",
      nuevoRut: "",
      nuevaRazon: "",
      nuevoAlias: "",
      nuevoFanta: "",
      nuevoEstado: "",
      nuevoContactos: [{}],
      nuevoRepresentantes: [{}],
      nuevoDirecciones: [{}],
    },
  ];

  const tiposCliente = [
    { id: 1, name: "NATURAL" },
    { id: 2, name: "COMERCIAL" },
    { id: 3, name: "OTRO" },
  ];

  const tiposDireccion = [
    { name: "COMERCIAL" },
    { name: "REGULAR" },
    { name: "OTRO" },
  ];

 

  let clienteMolde = [
    {
      tipo: "",
      rut: "",
      razon: "",
      alias: "",
      fantasia: "",
      contactos: [{}],
      representantes: [{}],
      direcciones: [[]],
    },
  ];

  let contactoMolde = [
    {
      nombre: "",
      tipo: "",
      telefono: "",
      correo: "",
    },
  ];

  let representanteMolde = [
    {
      nombre: "",
      rut: "",
      telefono: "",
      correo: "",
    },
  ];

  let direccionMolde = [
    {
      tipo: "",
      Direccion: "",
    },
  ];

  ///////// CLIENTE //////////
  const [nuevoTipo, setNuevoTipo] = useState("");
  const [nuevoRut, setNuevoRut] = useState("");
  const [nuevaRazon, setNuevaRazon] = useState("");
  const [nuevoAlias, setNuevoAlias] = useState("");
  const [nuevoFanta, setNuevoFanta] = useState("");
  ///////// CLIENTE //////////

  ///////// CONTACTO //////////
  const [nuevoContactos, setNuevoContactos] = useState([]);
  const [nuevoNombreContacto, setNuevoNombreContacto] = useState("");
  const [nuevoTipoContacto, setNuevoTipoContacto] = useState("");
  const [nuevoTelefonoContacto, setNuevoTelefonoContacto] = useState("");
  const [nuevoCorreoContacto, setNuevoCorreoContacto] = useState("");
  const [displayCreateContacto, setDisplayCreateContacto] = useState("");
  ///////// CONTACTO //////////

  ///////// REPRESENTANTES //////////
  const [nuevoRepresentantes, setNuevoRepresentantes] = useState([]);
  const [nuevoNombreRepresentante, setNuevoNombreRepresentante] = useState("");
  const [nuevoRutRepresentante, setNuevoRutRepresentante] = useState("");
  const [nuevoTelefonoRepresentante, setNuevoTelefonoRepresentante] =
    useState("");
  const [nuevoCorreoRepresentante, setNuevoCorreoRepresentante] = useState("");
  const [displayCreateRepresentante, setDisplayCreateRepresentante] =
    useState("");
  ///////// REPRESENTANTES //////////

  ///////// DIRECCIONES //////////
  const [nuevoDirecciones, setNuevoDirecciones] = useState([]);
  const [nuevaDireccion, setNuevaDireccion] = useState("");
  const [nuevoTipoDireccion, setNuevoTipoDireccion] = useState("");
  const [displayCreateDireccion, setDisplayCreateDireccion] = useState("");
  ///////// DIRECCIONES //////////

  const clearVariablesNuevoCliente = () => {
    setNuevoTipo("");
    setNuevoRut("");
    setNuevaRazon("");
    setNuevoAlias("");
    setNuevoFanta("");
    setNuevoContactos([{}]);
    setNuevoRepresentantes([{}]);
    setNuevoDirecciones([{}]);
  };

  const clearVariablesNuevoContacto = () => {
    setNuevoNombreContacto("");
    setNuevoRutRepresentante("");
    setNuevoTelefonoContacto("");
    setNuevoCorreoContacto("");
  };

  const clearVariablesNuevoRepresentante = () => {
    setNuevoNombreRepresentante("");
    setNuevoRutRepresentante("");
    setNuevoTelefonoRepresentante("");
    setNuevoCorreoRepresentante("");
  };

  const clearVariablesNuevoDirecciones = () => {
    setNuevaDireccion("");
    setNuevoTipoDireccion("");
  };

  const handleAgregarNuevoCliente = () => {
    if (!isValidFormClientes()) {
      alert("debe llenar los campos obligatorios");
      return;
    }
    clienteMolde = {
      tipo: nuevoTipo,
      rut: nuevoRut,
      razon: nuevaRazon,
      alias: nuevoAlias,
      fantasia: nuevoFanta,
      contactos: nuevoContactos,
      representantes: nuevoRepresentantes,
      direcciones: nuevoDirecciones,
    };
    setNuevoCliente([...nuevoCliente, clienteMolde]);
    clearVariablesNuevoCliente();
  };

  const handleAgregarNuevoContacto = () => {
    if (!isValidFormContactos()) {
      alert("Debe rellenar los campos");
      return;
    }
    contactoMolde = {
      nombre: nuevoNombreContacto,
      tipo: nuevoTipoContacto.name,
      telefono: nuevoTelefonoContacto,
      correo: nuevoCorreoContacto,
    };
    setNuevoContactos([...nuevoContactos, contactoMolde]);
    clearVariablesNuevoContacto();
  };

  const handleAgregarNuevoRepresentante = () => {
    if (!isValidFormRepresentantes()) {
      let alertMessage = "Debe completar toda la información para el nuevo representante";
      if (!isValidRepresentante) {
        alertMessage += " y agregar un RUT válido";
      }
      alert(alertMessage);
      return;
    }
    representanteMolde = {
      nombre: nuevoNombreRepresentante,
      rut: nuevoRutRepresentante,
      telefono: nuevoTelefonoRepresentante,
      correo: nuevoCorreoRepresentante,
    };
    setNuevoRepresentantes([...nuevoRepresentantes, representanteMolde]);
    clearVariablesNuevoRepresentante();
  };

  const handleAgregarNuevoDireccion = () => {
    if (!isValidFormDirecciones()) {
      alert("Debe rellenar todos los campos");
      return;
    }
    direccionMolde = {
      tipo: nuevoTipoDireccion.name,
      direccion: nuevaDireccion,
    };
    setNuevoDirecciones([...nuevoDirecciones, direccionMolde]);
    clearVariablesNuevoDirecciones();
  };
  const [isValid, setIsValid] = useState(true)
  const [isValidRepresentante, setIsValidRepresentante] = useState(true)
  const checkFormClientes = () => {
    const checksClientes =
      rut !== null &&
      rut !== undefined &&
      rut !== "" &&
      razonSocial !== null &&
      razonSocial !== undefined &&
      razonSocial !== "" &&
      nuevoTipo !== null &&
      nuevoTipo !== undefined &&
      nuevoTipo !== "" &&
      isValid
    const isClientesValid = checksClientes;

    return isClientesValid;
  };

  const checkFormContactos = () => {
    const checksContactos =
      nuevoNombreContacto !== null &&
      nuevoNombreContacto !== undefined &&
      nuevoNombreContacto !== "" &&
      nuevoTipoContacto !== null &&
      nuevoTipoContacto !== undefined &&
      nuevoTipoContacto !== "" &&
      nuevoTelefonoContacto !== null &&
      nuevoTelefonoContacto !== undefined &&
      nuevoTelefonoContacto !== "" &&
      nuevoCorreoContacto !== null &&
      nuevoCorreoContacto !== undefined &&
      nuevoCorreoContacto !== "";

    const isContactosValid = checksContactos;

    return isContactosValid;
  };

  const checkFormRepresentantes = () => {
    const checksRepresentantes =
      nuevoNombreRepresentante !== null &&
      nuevoNombreRepresentante !== undefined &&
      nuevoNombreRepresentante !== "" &&
      nuevoRutRepresentante !== null &&
      nuevoRutRepresentante !== undefined &&
      nuevoRutRepresentante !== "" &&
      nuevoTelefonoRepresentante !== null &&
      nuevoTelefonoRepresentante !== undefined &&
      nuevoTelefonoRepresentante !== "" &&
      nuevoCorreoRepresentante !== null &&
      nuevoCorreoRepresentante !== undefined &&
      nuevoCorreoRepresentante !== "" && isValidRepresentante;

    const isRepresentantesValid = checksRepresentantes;

    return isRepresentantesValid;
  };

  const checkFormDirecciones = () => {
    const checksDirecciones =
      nuevaDireccion !== null &&
      nuevaDireccion !== undefined &&
      nuevaDireccion !== "" &&
      nuevoTipoDireccion !== null &&
      nuevoTipoDireccion !== undefined &&
      nuevoTipoDireccion !== "";

    const isDireccionesValid = checksDirecciones;

    return isDireccionesValid;
  };

  const isValidFormClientes = () => {
    if (!checkFormClientes()) {
      return false;
    }
    return true;
  };

  const isValidFormContactos = () => {
    if (!checkFormContactos()) {
      return false;
    }
    return true;
  };

  const isValidFormRepresentantes = () => {
    if (!checkFormRepresentantes()) {
      return false;
    }
    return true;
  };

  const isValidFormDirecciones = () => {
    if (!checkFormDirecciones()) {
      return false;
    }
    return true;
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <h3 style={{ marginBottom: "5px" }}>Cotizaciones</h3>
        <h7 style={{ marginTop: "5px" }}>¿Cómo vamos?</h7>
      </div>
      {isFormVisible ? (
        <></>
      ) : (
        <>
          <OverviewBox
            icon="pi-shopping-cart"
            title="Cotizaciones"
            menuRef={menu1}
            chartRef={chart1}
            chartData={overviewChartData1}
            chartOptions={overviewChartOptions}
            amount={cotizaciones?.length}
          />

          <OverviewBox
            icon="pi-users"
            title="Clientes"
            menuRef={menu3}
            chartRef={null}
            chartData={overviewChartData3}
            chartOptions={overviewChartOptions}
            amount="374"
          />

          <OverviewBox
            icon="pi-plus"
            title="Nuevas"
            menuRef={menu2}
            chartRef={null}
            chartData={overviewChartData2}
            chartOptions={overviewChartOptions}
            amount="5"

          />

          <OverviewBox
            icon="pi pi-stopwatch"
            title="Proximos Vencimientos"
            menuRef={menu4}
            chartRef={null}
            chartData={overviewChartData4}
            chartOptions={overviewChartOptions}
            amount="3"

          />
        </>
      )}

      <div className="col-12">
        {isFormVisible && (
          <div className="card">
            <div>
              {!isBuscarVisible && (
                <FormBusquedaCliente
                  criterioBusqueda={criterioBusqueda}
                  hanldeCriterioBusqueda={hanldeCriterioBusqueda}
                  clienteLocal={clienteLocal}
                  handleClienteLocal={handleClienteLocal}
                  rut={rut}
                  setRut={setRut}
                  razonSocial={razonSocial}
                  setRazonSocial={setRazonSocial}
                  alias={alias}
                  setAlias={setAlias}
                  nombreFantasia={nombreFantasia}
                  setNombreFantasia={setNombreFantasia}
                  dni={dni}
                  setDni={setDni}
                  searchCliente={searchCliente}
                  isFormVisible={isFormVisible}
                  clientes={clientes}
                  filtersApplied={filtersApplied}
                  displayCreateClient={displayCreateClient}
                  setDisplayCreateClient={setDisplayCreateClient}
                  confirmationDialogFooterCliente={
                    confirmationDialogFooterCliente
                  }
                  nombreRepresentante={nombreRepresentante}
                  setNombreRepresentante={setNombreRepresentante}
                  searchClienteByRepresentante={searchClienteByRepresentante}
                  nuevoCliente={nuevoCliente}
                  setNuevoCliente={setNuevoCliente}
                  tiposCliente={tiposCliente}
                  tiposDireccion={tiposDireccion}
                  tiposContacto={tiposDireccion}
                  contactoMolde={contactoMolde}
                  representanteMolde={representanteMolde}
                  direccionMolde={direccionMolde}
                  nuevoTipo={nuevoTipo}
                  setNuevoTipo={setNuevoTipo}
                  nuevoRut={nuevoRut}
                  setNuevoRut={setNuevoRut}
                  nuevaRazon={nuevaRazon}
                  setNuevaRazon={setNuevaRazon}
                  nuevoAlias={nuevoAlias}
                  setNuevoAlias={setNuevoAlias}
                  nuevoFanta={nuevoFanta}
                  setNuevoFanta={setNuevoFanta}
                  nuevoContactos={nuevoContactos}
                  setNuevoContactos={setNuevoContactos}
                  nuevoRepresentantes={nuevoRepresentantes}
                  setNuevoRepresentantes={setNuevoRepresentantes}
                  nuevoDirecciones={nuevoDirecciones}
                  setNuevoDirecciones={setNuevoDirecciones}
                  nuevoNombreContacto={nuevoNombreContacto}
                  setNuevoNombreContacto={setNuevoNombreContacto}
                  nuevoTipoContacto={nuevoTipoContacto}
                  setNuevoTipoContacto={setNuevoTipoContacto}
                  nuevoTelefonoContacto={nuevoTelefonoContacto}
                  setNuevoTelefonoContacto={setNuevoTelefonoContacto}
                  nuevoCorreoContacto={nuevoCorreoContacto}
                  setNuevoCorreoContacto={setNuevoCorreoContacto}
                  displayCreateContacto={displayCreateContacto}
                  setDisplayCreateContacto={setDisplayCreateContacto}
                  nuevoNombreRepresentante={nuevoNombreRepresentante}
                  setNuevoNombreRepresentante={setNuevoNombreRepresentante}
                  nuevoRutRepresentante={nuevoRutRepresentante}
                  setNuevoRutRepresentante={setNuevoRutRepresentante}
                  nuevoTelefonoRepresentante={nuevoTelefonoRepresentante}
                  setNuevoTelefonoRepresentante={setNuevoTelefonoRepresentante}
                  nuevoCorreoRepresentante={nuevoCorreoRepresentante}
                  setNuevoCorreoRepresentante={setNuevoCorreoRepresentante}
                  displayCreateRepresentante={displayCreateRepresentante}
                  setDisplayCreateRepresentante={setDisplayCreateRepresentante}
                  nuevaDireccion={nuevaDireccion}
                  setNuevaDireccion={setNuevaDireccion}
                  nuevoTipoDireccion={nuevoTipoDireccion}
                  setNuevoTipoDireccion={setNuevoTipoDireccion}
                  displayCreateDireccion={displayCreateDireccion}
                  setDisplayCreateDireccion={setDisplayCreateDireccion}
                  clearVariablesNuevoCliente={clearVariablesNuevoCliente}
                  clearVariablesNuevoContacto={clearVariablesNuevoContacto}
                  clearVariablesNuevoDirecciones={
                    clearVariablesNuevoDirecciones
                  }
                  clearVariablesNuevoRepresentante={
                    clearVariablesNuevoRepresentante
                  }
                  handleAgregarNuevoCliente={handleAgregarNuevoCliente}
                  handleAgregarNuevoContacto={handleAgregarNuevoContacto}
                  handleAgregarNuevoDireccion={handleAgregarNuevoDireccion}
                  handleAgregarNuevoRepresentante={
                    handleAgregarNuevoRepresentante
                  }
                  checkFormClientes={checkFormClientes}
                  checkFormContactos={checkFormContactos}
                  checkFormDirecciones={checkFormDirecciones}
                  checkFormRepresentantes={checkFormRepresentantes}
                  isValidFormClientes={isValidFormClientes}
                  isValidFormContactos={isValidFormContactos}
                  isValidFormDirecciones={isValidFormDirecciones}
                  isValidFormRepresentantes={isValidFormRepresentantes}
                  loading={loading}
                  isValid={isValid}
                  setIsValid={setIsValid}
                  isValidRepresentante={isValidRepresentante}
                  setIsValidRepresentante={setIsValidRepresentante}
                />
              )}

              {filtersApplied && (
                <>
                  {loading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <ClientesTable
                      filtersClienteUrl={filtersClienteUrl}
                      representanteUrl={representanteUrl}
                      clientes={clientes}
                      setClientes={setClientes}
                      checkCabeceraFiltros={checkCabeceraFiltros}
                      checkCabeceraFiltrosRepresentante={
                        checkCabeceraFiltrosRepresentante
                      }
                      loading={loading}
                      handleSelectCliente={handleSelectCliente}
                      id="clientesDataTable"
                      isMantenedor={false}
                    />
                  )}
                </>
              )}

              <div className="col-12 md:col-12">
                <div className="col-12 md:col-12"> </div>
                <Dialog
                  visible={displayConfirmation}
                  onHide={() => setDisplayConfirmation(false)}
                  style={{ width: "350px" }}
                  modal
                  footer={confirmationDialogFooter}
                >
                  <div className="flex align-items-center justify-content-center">
                    <i
                      className="pi pi-exclamation-triangle mr-3"
                      style={{ fontSize: "2rem" }}
                    />
                    <span>¿Desea seleccionar al cliente?</span>
                  </div>
                </Dialog>
              </div>
            </div>
            {isSearchFormVisible && !additionalSelection && (
              <>
                <FormBusquedaProductos
                  baseUrl={baseUrl}
                  cliente={cliente}
                  empresa={empresa}
                  setEmpresa={setEmpresa}
                  empresas={empresas}
                  tipoProducto={tipoProducto}
                  setTipoProducto={setTipoProducto}
                  tiposProducto={tiposProducto}
                  setTiposProducto={setTiposProducto}
                  desde={desde}
                  setDesde={setDesde}
                  hasta={hasta}
                  setHasta={setHasta}
                  isTarifaVisible={isTarifaVisible}
                  searchProductos={searchProductos}
                />
                <div className="p-fluid formgrid grid" id="clienteSeleccionado">
           
                  

                </div>
              </>
            )}
          </div>
        )}
        <Toast ref={toast} />
        <Button
          visible={!isFormVisible}
          label="Nueva Cotización"
          icon="pi pi-plus"
          className="mr-2 mb-2 p-button-rounded p-button"
          onClick={toggleFormVisibility}
        ></Button>
        <Button
          visible={!isFormVisible}
          label="Ver Mapa"
          icon="pi pi-map"
          className="mr-2 mb-2 p-button-rounded p-button"
          type="button"
          onClick={toggle}
        />
        <OverlayPanel ref={op} appendTo={document.body} showCloseIcon>
          <img src="\assets\demo\images\mapa.png" alt="nature1" />
        </OverlayPanel>{" "}
        {isFormVisible ? (
          <></>
        ) : (
          <>
            <CotizacionesTable
              cotizaciones={cotizaciones}
              filters={filters2}
              loading={loading2}
              desdeBodyTemplate={desdeBodyTemplate}
              dateFilterTemplate={dateFilterTemplate}
              hastaBodyTemplate={hastaBodyTemplate}
              estadoBodyTemplate={estadoBodyTemplate}
              estadoFilterTemplate={estadoFilterTemplate}
              actionBodyTemplate={actionBodyTemplate}
              handleRowClick={handleRowClick}
            ></CotizacionesTable>
          </>
        )}
      </div>
    </div>
  );
};

export default Cotizacion;
