import { createContext, useContext, useState } from "react";
const GlobalContext = createContext();
export const useGlobalContext = () => useContext(GlobalContext);

const AppContext = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //DESCOMENTAR PARA LOCAL
  // const [baseUrl, setBaseUrl] = useState("http://localhost:8080/rest/rest/");
  //DESCOMENTAR PARA LOCAL

  // DESCOMENTAR PARA PRD
  const [baseUrl, setBaseUrl] = useState(
    "https://bodegas.kesp.cl/bodegas.service/rest/"
  );
  // DESCOMENTAR PARA PRD

  return (
    <GlobalContext.Provider
      value={{ baseUrl, setBaseUrl, isLoggedIn, setIsLoggedIn }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
