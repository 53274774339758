import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";

const AsignacionesTable = ({
  asignaciones,
  filters,
  loading,
  desdeBodyTemplate,
  dateFilterTemplate,
  hastaBodyTemplate,
  estadoBodyTemplate,
  estadoFilterTemplate,
  actionBodyTemplate,
  fila,
  setFila,
  displayBasic,
  setDisplayBasic,
  handleRowClick,
}) => {
  const handleOpenDialog = (rowData) => {
    setFila(rowData);
    setDisplayBasic(true);
  };

  const basicDialogFooter = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span style={{ fontSize: "2rem" }}>Monto Total: {fila?.monto}</span>
      <Button
        type="button"
        label="Cerrar"
        onClick={() => setDisplayBasic(false)}
        icon="pi pi-times"
        className="p-button-secondary"
        autoFocus
      />
    </div>
  );

  const productos = [
    {
      nombre: "Producto 1",
      fechaInicio: "2024-03-01",
      fechaTermino: "2024-04-01",
      unidadMedida: "m^2",
      tarifaBase: "UF 200",
      tarifaPactada: "UF 200",
      subtotal: "UF 200",
    },
    {
      nombre: "Producto 2",
      fechaInicio: "2024-03-15",
      fechaTermino: "2024-04-15",
      unidadMedida: "m^2",
      tarifaBase: "UF 50",
      tarifaPactada: "UF 40",
      subtotal: "UF 50",
    },
    // Agrega más productos según sea necesario
  ];

  const subproductos = [
    {
      nombre: "Subproducto 1",
      fechaInicio: "2024-03-05",
      fechaTermino: "2024-04-05",
      unidadMedida: "m^2",
      tarifaBase: "UF 10",
      tarifaPactada: "UF 9",
      subtotal: "UF 9",
    },
    {
      nombre: "Subproducto 2",
      fechaInicio: "2024-03-10",
      fechaTermino: "2024-04-10",
      unidadMedida: "m^2",
      tarifaBase: "UF 5",
      tarifaPactada: "UF 4,75",
      subtotal: "UF 4,75",
    },
    // Agrega más subproductos según sea necesario
  ];

  const servicios = [
    {
      nombre: "Servicio 1",
      fechaInicio: "2024-03-20",
      fechaTermino: "2024-04-20",
      unidadMedida: "kw/h",
      tarifaBase: "$ 2.000 CLP",
      tarifaPactada: "$ 1.900 CLP",
      subtotal: "$ 1.900 CLP",
    },
    {
      nombre: "Servicio 2",
      fechaInicio: "2024-03-25",
      fechaTermino: "2024-04-25",
      unidadMedida: "Litros",
      tarifaBase: "$ 1.200 CLP",
      tarifaPactada: "$ 1.200 CLP",
      subtotal: "$ 1.200 CLP",
    },
    // Agrega más servicios según sea necesario
  ];

  return (
    <div className="col-12">
      <div className="card">
        <div className="field col-12 md:col-10">
          <h5>Asignaciones / Contratos</h5>
        </div>

        <div className="col-12">
          <Dialog
            header={"Detalles del Contrato " + fila?.contrato}
            visible={displayBasic}
            style={{ width: "95vw" }}
            modal
            blockScroll
            footer={basicDialogFooter}
            onHide={() => setDisplayBasic(false)}
          >
            <TabView>
              <TabPanel header="Contrato">
                <div className="card grid table-demo">
                  <div className="field col-12 md:col-3">
                    <h4 style={{ marginTop: "5px" }}>
                      Cliente: <>{fila?.nombreCliente}</>
                    </h4>
                    <h6 style={{ marginTop: "5px" }}>
                      Rut: <>{fila?.rutCliente}</>
                    </h6>
                    <h6 style={{ marginTop: "5px" }}>
                      Correo: <>{fila?.correoCliente}</>
                    </h6>
                    <h6 style={{ marginTop: "5px" }}>
                      Contacto: <>{fila?.contactoCliente}</>
                    </h6>
                  </div>
                  <div className="field col-12 md:col-3">
                    <h4 style={{ marginTop: "5px" }}>
                      Representante Legal: <>{fila?.nombreCliente}</>
                    </h4>
                    <h6 style={{ marginTop: "5px" }}>
                      Rut: <>{fila?.rutCliente}</>
                    </h6>
                    <h6 style={{ marginTop: "5px" }}>
                      Correo: <>{fila?.correoCliente}</>
                    </h6>
                    <h6 style={{ marginTop: "5px" }}>
                      Contacto: <>{fila?.contactoCliente}</>
                    </h6>
                  </div>
                  <div className="field col-12 md:col-3">
                    <h4 style={{ marginTop: "5px" }}>
                      Empresa: <>{fila?.nombreEmpresa}</>
                    </h4>
                    <h6 style={{ marginTop: "5px" }}>
                      Rut: <>{fila?.rutEmpresa}</>
                    </h6>
                    <h6 style={{ marginTop: "5px" }}>
                      Correo: <>{fila?.correoEmpresa}</>
                    </h6>
                    <h6 style={{ marginTop: "5px" }}>
                      Contacto: <>{fila?.contactoEmpresa}</>
                    </h6>
                  </div>
                  <div className="field col-12 md:col-3">
                    <h4>Vendedor: Nombre Vendedor</h4>
                    <h6>Correo: correo@vendedor.cl</h6>
                  </div>
                </div>
                <div className="card grid table-damo">
                  <h4 style={{ marginTop: "5px" }}>
                    Productos, subproductos y servicios contratados
                  </h4>
                  <div className="field col-12 md:col-12">
                    <DataTable
                      header="Productos"
                      value={productos}
                      className="p-datatable-gridlines"
                    >
                      <Column
                        field="nombre"
                        header="Nombre"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="fechaInicio"
                        header="Fecha de Inicio"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="fechaTermino"
                        header="Fecha de Término"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="unidadMedida"
                        header="Unidad de Medida"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="tarifaBase"
                        header="Tarifa Base"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="tarifaPactada"
                        header="Tarifa Pactada"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="subtotal"
                        header="Subtotal"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                    </DataTable>
                  </div>

                  <div className="field col-12 md:col-12">
                    <DataTable
                      header="Subproductos"
                      value={subproductos}
                      className="p-datatable-gridlines"
                    >
                      <Column
                        field="nombre"
                        header="Nombre"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="fechaInicio"
                        header="Fecha de Inicio"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="fechaTermino"
                        header="Fecha de Término"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="unidadMedida"
                        header="Unidad de Medida"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="tarifaBase"
                        header="Tarifa Base"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="tarifaPactada"
                        header="Tarifa Pactada"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="subtotal"
                        header="Subtotal"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                    </DataTable>
                  </div>

                  <div className="field col-12 md:col-12">
                    <DataTable
                      header="Servicios"
                      value={servicios}
                      className="p-datatable-gridlines"
                    >
                      <Column
                        field="nombre"
                        header="Nombre"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="fechaInicio"
                        header="Fecha de Inicio"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="fechaTermino"
                        header="Fecha de Término"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="unidadMedida"
                        header="Unidad de Medida"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="tarifaBase"
                        header="Tarifa Base"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="tarifaPactada"
                        header="Tarifa Pactada"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                      <Column
                        field="subtotal"
                        header="Subtotal"
                        style={{ minWidth: "10rem", textAlign: "left" }}
                      />
                    </DataTable>
                  </div>
                </div>
              </TabPanel>

              <TabPanel header="Contactos Cliente"></TabPanel>

              <TabPanel header="Cuadro de Pago"></TabPanel>
            </TabView>
          </Dialog>

          <DataTable
            value={asignaciones}
            paginator
            rows={10}
            dataKey="id"
            filters={filters}
            // onRowClick={(e) => handleOpenDialog(e.data)}
            filterDisplay="menu"
            loading={loading}
            responsiveLayout="scroll"
            emptyMessage="No se encontraron registros."
            rowClassName="row-clickable"
            className="p-datatable-gridlines"
            onRowClick={(e) => handleRowClick(e.data)}


          >
            <Column
              field="id"
              header="# Cotización"
              style={{ minWidth: "7rem" }}
              body={(rowData) => rowData.id}
              sortable
            />
            <Column
              field="cliente.razonSocial"
              header="Cliente"
              style={{ minWidth: "7rem" }}
              body={(rowData) => rowData.cliente.razonSocial}
              sortable
            />
            {/* <Column
              field="contrato"
              header="Contrato"
              filter
              filterPlaceholder="Buscar por número"
              style={{ minWidth: "7rem" }}
            /> */}
            <Column
            field="desde"
              header="Desde"
              body={(rowData) => {
                return rowData.desde ? rowData.desde : "No tiene fecha";
              }}
              sortable
            />
            <Column
            field="hasta"
              header="Hasta"
              body={(rowData) => {
                return rowData.hasta ? rowData.hasta : "No tiene fecha";
              }}
              sortable
            />
            <Column
              field="estado.estado"
              header="Estado"
              style={{ minWidth: "7rem" }}
              body={(rowData) => rowData.estado.estado}
              sortable
            />
            <Column
            sortable
              field="aviso"
              header="Días Aviso"
              style={{ minWidth: "7rem" }}
              body={(rowData) => rowData.aviso}
            />

            {/* <Column
              field="periodicidad"
              header="Periodicidad"
              filter
              filterPlaceholder="Buscar por periodicidad"
              style={{ minWidth: "9rem" }}
            /> */}

            <Column
              header="Acción"
              body={actionBodyTemplate}
              style={{ minWidth: "12rem", textAlign: "left" }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default AsignacionesTable;
