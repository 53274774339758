import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import TarifasTable from "../components/TarifasTable";
import { Button } from "primereact/button";
import { useGlobalContext } from "../Config";

const empresas = [
  { name: "ALTAMIRA", value: "ALTAMIRA" },
  { name: "LA SIEMBRA", value: "LA SIEMBRA" },
];
const Tarifa = (props) => {
  const [empresa, setEmpresa] = useState("");
  const [idEmpresa, setIdEmpresa] = useState(""); //idEmpresa será utilizado con el back posteriormente
  const [mostrarTitulo, setMostrarTitulo] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTarifa, setSelectedTarifa] = useState("");
  const [newTarifa, setNewTarifa] = useState("");
  const { baseUrl } = useGlobalContext();
  const idUsuario = sessionStorage.getItem("usuario_id");
  const handleChangeEmpresa = (e) => {
    const selectedEmpresa = e.value;
    setEmpresa(selectedEmpresa);
    setIdEmpresa(selectedEmpresa.id);
  };

  const handleCloseDialog = () => {
    setDisplayDialog(false);
    setSelectedTarifa(null);
    setNewTarifa("");
  };

  const handleBuscarClick = () => {
    setMostrarTitulo(true);
  };

  const fetchData = async (url) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(
        `Network response was not ok. Status: ${response.status}`
      );
    }

    return response.json();
  };
  const getTarifasByEmpresa = async (empresa) => {
    try {
      const url = `${baseUrl}getTarifasByEmpresa?empresa=${empresa}`;
      const data = await fetchData(url);
      handleTarifasResponse(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTarifasResponse = (data) => {
    setTarifas(data);
    setMostrarTitulo(true);
  };

  // const createCotizacion = async () => {
  //   try {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  //     var urlencoded = new URLSearchParams();

  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: urlencoded,
  //       credentials: "include",
  //       redirect: "follow",
  //     };
  //     const url = deactivateTarifa();
  //     fetch(url, requestOptions)
  //       .then((response) => {
  //         console.log(response);
  //         if (response.ok) {
  //           alert("La tarifa se actualizó correctamente");
  //         } else {
  //           alert("No fue posible actualizar la tarifa");
  //           return;
  //         }
  //       })
  //       .then((result) => console.log(result))
  //       .catch((error) => console.log("error", error));
  //   } catch (error) {
  //     console.error(error);
  //     alert("Hubo un error");
  //   }
  // };
  const handleUpdateTarifa = async () => {
    try {
      let indicador;
      switch (selectedTarifa.tipo) {
        case "producto":
          indicador = "Producto";
          break;
        case "subproducto":
          indicador = "Subproducto";
          break;
        case "servicio":
          indicador = "Servicio";
          break;
        default:
          indicador = "";
      }
      const deactivateTarifa = () => {
        return `${baseUrl}deactivateTarifa${indicador}?id=${selectedTarifa.id}&idUsuario=${idUsuario}`;
      };

      const updateTarifa = () => {
        return `${baseUrl}insertTarifa${indicador}?idTipo${indicador}=${selectedTarifa.id_tipo}&idEmpresa=${selectedTarifa.id_empresa}&tarifaBase=${newTarifa}&idUsuario=${idUsuario}&moneda=${selectedTarifa.moneda}`;
      };

      const requestOptions = {
        method: "POST",
        redirect: "follow",
      };
      if (newTarifa === 0) {
        const urlDeactivate = deactivateTarifa();
        const response = await fetch(urlDeactivate, requestOptions);
        if (response.ok) {
          alert("La tarifa se actualizó correctamente");
        } else {
          throw new Error("No fue posible actualizar la tarifa");
        }
      } else {
        const urlDeactivate = deactivateTarifa();
        const urlInsert = updateTarifa();

        const deactivateResponse = await fetch(urlDeactivate, requestOptions);
        if (!deactivateResponse.ok) {
          throw new Error("No fue posible desactivar la tarifa existente");
        }

        const insertResponse = await fetch(urlInsert, requestOptions);
        if (insertResponse.ok) {
          alert("La tarifa se actualizó correctamente");
        } else {
          throw new Error("No fue posible actualizar la tarifa");
        }
      }
      await getTarifasByEmpresa(empresa);
      setDisplayDialog(false);
    } catch (error) {
      await getTarifasByEmpresa(empresa);
      console.error(error);
      alert("Hubo un error al actualizar la tarifa");
      setDisplayDialog(false);
    }
  };

  const templateButton = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text mr-2 mb-2"
          onClick={() => {
            setSelectedTarifa(rowData);
            setNewTarifa(null)
            setDisplayDialog(true);
          }}
        />
        {""}
      </div>
    );
  };
  ////////MOCK DATA////////

  const [tarifas, setTarifas] = useState([]);

  ///////MOCK DATA////////

  return (
    <div className="grid table-demo">
      <div className="field col-12 md:col-12">
        <h2 style={{ marginTop: "20px" }}>Tarifas</h2>
      </div>
      <div className="card col-12 md:col-12">
        <h4 style={{ marginTop: "10px", marginLeft: "10px" }}>
          Seleccione la empresa
        </h4>
        <Dropdown
          id="state"
          value={empresa}
          onChange={async (e) => {
            setEmpresa(e.value);
            setTarifas("");
            try {
              await getTarifasByEmpresa(e.value);
            } catch (error) {
              console.error("Error occurred:", error);
            }
          }}
          options={empresas}
          optionLabel="name"
          placeholder="Seleccione Empresa"
          filter
          className="ml-2"
        ></Dropdown>
        {mostrarTitulo && empresa && (
          <>
            <div className="field col-12 md:col-12">
              <h4 style={{ marginTop: "20px", marginLeft: "10px" }}>
                Estás viendo las tarifas de la empresa {empresa}
              </h4>
            </div>
            <TarifasTable
              tarifas={tarifas}
              displayDialog={displayDialog}
              setDisplayDialog={setDisplayDialog}
              selectedTarifa={selectedTarifa}
              setSelectedTarifa={setSelectedTarifa}
              newTarifa={newTarifa}
              setNewTarifa={setNewTarifa}
              handleCloseDialog={handleCloseDialog}
              templateButton={templateButton}
              handleUpdateTarifa={handleUpdateTarifa}
            ></TarifasTable>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default Tarifa;
