import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
const ClientesTable = ({
  id,
  clientes,
  loading,
  handleSelectCliente,
  isMantenedor,
  handleEditCliente,
  header,
  globalFilterFields,
  filters,
}) => {
  return (
    <>
      <DataTable
        header={header ? header: `Se encontraron ${clientes.length} clientes`}
        value={clientes}
        className="p-datatable-gridlines"
        showGridlines
        rows={50}
        dataKey="id"
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="No se encontraron clientes."
        stripedRows="true"
        id={id}
        globalFilterFields={globalFilterFields}
        filters={filters}

      >
        <Column field="rut" header="RUT" style={{ minWidth: "100px" }} />
        <Column
          field="razon_social"
          header="Razón Social"
          style={{ minWidth: "90px" }}
        />
        <Column
          field="nombre"
          header="Nombre Empresa"
          style={{ minWidth: "90px" }}
        />
        <Column
          header="Grupo Empresa"
          body={(rowData) => (
            <>
              {rowData.grupos.map((grupo, index) => (
                <div key={grupo.id}>
                  <p>{`${index + 1}. ${grupo.nombre}`}</p>
                </div>
              ))}
              {rowData.grupos.length === 0 && (
                <p>No tiene grupo empresa asociado</p>
              )}
            </>
          )}
          style={{ minWidth: "120px" }}
        />

        <Column
          header="Nombre R. Legal "
          body={(rowData) => (
            <>
              {rowData.representantes.map((representante, index) => (
                <div key={representante.id}>
                  <p>{`${index + 1}. ${representante.nombre}`}</p>
                </div>
              ))}
              {rowData.representantes.length === 0 && (
                <p>No tiene representante</p>
              )}
            </>
          )}
          style={{ minWidth: "130px" }}
        />

        <Column
          header="Acción"
          body={(rowData) =>
            isMantenedor === true ? (
              <>
              <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-success p-button mr-2 mb-2 mt-2"
                  onClick={() => handleEditCliente(rowData)}
                  disabled={rowData.activo === false}
                  outlined
                ></Button>
                <Button
                  icon="pi pi-info"
                  className="p-button-rounded p-button p-button mr-2 mb-2 mt-2"
                  onClick={() => {}}
                  disabled
                  outlined
                ></Button>
              </>
            ) : (
              <>
                <Button
                  icon="pi pi-check"
                  className="p-button-rounded p-button-success p-button mr-2 mb-2 mt-2"
                  onClick={() => handleSelectCliente(rowData)}
                  disabled={rowData.activo === false}
                  outlined
                ></Button>
                <Button
                  icon="pi pi-info"
                  className="p-button-rounded p-button p-button mr-2 mb-2 mt-2"
                  onClick={() => {}}
                  disabled
                  outlined
                ></Button>
              </>
            )
          }
        />

        <Column
          field="activo"
          header="Estado"
          body={(rowData) =>
            rowData.activo ? (
              <Tag
                className=" p-button-rounded p-button-success p-button mr-2"
                severity="success"
                value="Disponible"
              ></Tag>
            ) : (
              <Tag
                className="p-button-rounded p-button-danger p-button mr-2"
                severity="danger"
                value="Bloqueado"
              ></Tag>
            )
          }
        />
      </DataTable>
    </>
  );
};

export default ClientesTable;
