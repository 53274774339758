import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlugCircleCheck,
  faRestroom,
  faTint,
  faWarehouse,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useEffect } from "react";

const ProductosDisponiblesTable = ({
  productosDisponibles,
  loading2,
  rowClick,
  carro,
  setCarro,
  desdeFiltro,
  hastaFiltro,
  setDisplayConstruccion,
  displayConstruccion,
}) => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    initFilters();
  }, []);
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nombre_producto: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Buscar Nombre Producto"
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <>
      {productosDisponibles.length > 0 && (
        <>
         
          <h5 style={{ marginTop: "5px" }}>¿Cuales productos desea?</h5>
          <h6 style={{ marginTop: "5px" }}>
           
            Se encontraron {productosDisponibles.length} productos
          </h6>
        </>
      )}
      <DataTable
        value={productosDisponibles}
        className="p-datatable-gridlines"
        showGridlines
        rows={10}
        dataKey="id"
        loading={loading2}
        responsiveLayout="scroll"
        emptyMessage="No se encontraron productos disponibles."
        stripedRows="true"
        selectionMode={rowClick ? null : "checkbox"}
        selection={carro}
        onSelectionChange={(e) => setCarro(e.value)}
        id="productosDataTable"
        paginator
        globalFilterFields={["nombre_producto"]}
        header={header}
        filters={filters}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "4rem" }}
        ></Column>
        <Column
          field="nombre_producto"
          style={{ width: "120px" }}
          header="Nombre"
          filter
          body={(rowData) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ textTransform: "uppercase" }}>
                {rowData.nombre_producto}
              </span>
            </div>
          )}
        />
        <Column field="empresa" header="Empresa" style={{ width: "120px" }} />
        <Column
          style={{ width: "80px" }}
          header="Metraje"
          body={(rowData) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>{rowData.metraje_producto}</span>
              <span style={{ marginLeft: "4px" }}>
                {rowData.unidad === "m**2" ? "m²" : rowData.unidad}
              </span>
            </div>
          )}
        />

        <Column
          style={{ width: "120px" }}
          body={(rowData) =>
            desdeFiltro
              ? new Date(desdeFiltro).toLocaleDateString("en-GB", {
                  timeZone: "UTC",
                })
              : ""
          }
          header="Desde"
        />
        <Column
          style={{ width: "120px" }}
          body={(rowData) =>
            hastaFiltro
              ? new Date(hastaFiltro).toLocaleDateString("en-GB", {
                  timeZone: "UTC",
                })
              : ""
          }
          header="Hasta"
        />
        <Column
          style={{ width: "250px" }}
          header="Servicios"
          body={(rowData) => (
            <span>
              {rowData.oficina ? (
                <FontAwesomeIcon
                  icon={faBuilding}
                  style={{ color: "#0150a0" }}
                  size="2xl"
                  className="ml-2 mr-2 mb-1 mt-1 "
                  title="Oficina"
                />
              ) : (
                <></>
              )}
              {rowData.bano ? (
                <FontAwesomeIcon
                  icon={faRestroom}
                  style={{ color: "#0150a0" }}
                  size="2xl"
                  className="ml-2 mr-2 mb-1 mt-1 "
                  title="Baño"
                />
              ) : (
                <></>
              )}
              {rowData.modulo ? (
                <FontAwesomeIcon
                  icon={faWarehouse}
                  style={{ color: "#0150a0" }}
                  size="2xl"
                  className="ml-2 mr-2 mb-1 mt-1 "
                  title="Modulo"
                />
              ) : (
                <></>
              )}

              {rowData.agua ? (
                <FontAwesomeIcon
                  icon={faTint}
                  style={{ color: "#0150a0" }}
                  size="2xl"
                  className="ml-2 mr-2 mb-1 mt-1 "
                  title="Agua"
                />
              ) : (
                <></>
              )}
              {rowData.electricidad ? (
                <FontAwesomeIcon
                  icon={faPlugCircleCheck}
                  style={{ color: "#0150a0" }}
                  size="2xl"
                  className="ml-2 mr-2 mb-1 mt-1 "
                  title="Electricidad"
                />
              ) : (
                <></>
              )}
              {rowData.internet ? (
                <FontAwesomeIcon
                  icon={faWifi}
                  style={{ color: "#0150a0" }}
                  size="2xl"
                  className="ml-2 mr-2 mb-1 mt-1 "
                  title="Internet"
                />
              ) : (
                <></>
              )}
            </span>
          )}
        />

        <Column
          style={{ width: "160px" }}
          body={(rowData) => {
            const currentDate = desdeFiltro ? new Date(desdeFiltro) : null;

            if (currentDate) {
              // FUNCION SOLO PARA FINES DEMOSTRATIVOS, POSTERIORMENTE DEBE TRAERME LA DATA DE LOS CONTRATOS POR VENCER
              const oneMonthBefore = new Date(currentDate);
              oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

              const formattedDate = oneMonthBefore.toLocaleDateString("en-GB", {
                timeZone: "UTC",
              });

              return rowData.id % 2 === 1 ? (
                <span style={{ color: "red" }}>{formattedDate}</span>
              ) : null;
            } else {
              return "";
            }
          }}
          header="Contrato Vigente Hasta"
        />

        <Column
          style={{ width: "200px" }}
          header="Acción"
          body={(rowData) => (
            <>
              <Button
                icon="pi pi-info"
                className="p-button-rounded p-button p-button mr-2 mb-2 mt-2"
                onClick={() => setDisplayConstruccion(true)}
                outlined
              ></Button>

              <Button
                icon="pi pi-camera"
                className="p-button-rounded p-button-warning p-button mb-2 mt-2 mr-2"
                onClick={() => setDisplayConstruccion(true)}
                outlined
              ></Button>

              <Button
                icon="pi pi-map"
                className="p-button-rounded p-button-success p-button mr-2 mb-2"
                onClick={() => setDisplayConstruccion(true)}
                outlined
              ></Button>
              <Dialog
                visible={displayConstruccion}
                onHide={() => {
                  setDisplayConstruccion(false);
                }}
                style={{ width: "1200px" }}
                modal
                dismissableMask={false}
              >
                <div className="flex align-items-center justify-content-center">
                  <h4>En Construcción</h4>
                </div>
              </Dialog>
            </>
          )}
        />
      </DataTable>
    </>
  );
};

export default ProductosDisponiblesTable;
